import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Tooltip as MuiToolTip } from "@mui/material";
import {
  CartesianGrid,
  AreaChart,
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import InfoIcon from "@mui/icons-material/Info";
import ComponentLoader from "../../../../components/ComponentLoader";
import no_Data from "../../../../assets/images/png/Vector.png";
import { useVehiclesDiscovery } from "../../VehiclesDiscoveryContext";
import "../../vehicleDiscovery.scss";
import {
  averageSuccessPercentageInfo,
  averageNumberOfDiscoveryAttemptsInfo,
} from "../../constants";

export default function VehicleDiscoveryChartInsights({ chartName }) {
  const { isLoading, vehicleDiscoveryMetrics } = useVehiclesDiscovery();
  const [chartData, setChartData] = useState([{}]);
  const [averageMetrics, setAverageMetrics] = useState({});

  async function getData() {
    setChartData(vehicleDiscoveryMetrics?.dailyMetrics || [{}]);
    let total = 0;
    let length = vehicleDiscoveryMetrics?.dailyMetrics?.length;
    vehicleDiscoveryMetrics?.dailyMetrics?.forEach(
      (row) => (total += row[chartName])
    );
    setAverageMetrics(total / length);
  }

  useEffect(() => {
    getData();
  }, [vehicleDiscoveryMetrics]);

  const headerComponent = (chartName) => {
    return (
      <div>
        <Grid container mb={1}>
          <Grid item xs={12} className="monthlyChargerUtilization">
            <p className="title_for_charts">
              {chartName === "averageSuccessPercentage"
                ? "Average Success %"
                : "Average discovery attempts"}
              <MuiToolTip
                title={
                  chartName === "averageSuccessPercentage"
                    ? averageSuccessPercentageInfo
                    : averageNumberOfDiscoveryAttemptsInfo
                }
              >
                <span className="info">
                  <InfoIcon fontSize="small" className="infoIconColor" />
                </span>
              </MuiToolTip>
            </p>
            <p className="value">
              <span className="KPI_num">
                {Math.round(averageMetrics, 0)}
                {chartName === "averageSuccessPercentage" ? "%" : ""}
              </span>
            </p>
          </Grid>
        </Grid>
      </div>
    );
  };

  const circularLoader = (
    <div className="connectivity_section">
      <div className="connectivity_section_loader">
        <ComponentLoader isLoading={isLoading} />
      </div>
    </div>
  );
  const chart = (chartName) => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>{headerComponent(chartName)}</div>
            <div className="chargerUptimeBarGraph">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={chartData}
                  margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="4" vertical={true} />
                  <XAxis dataKey="date" id="graphAxes" />
                  <YAxis dataKey={chartName} id="graphAxes" />
                  <Tooltip
                    formatter={(value, name) => {
                      switch (name) {
                        case "averageSuccessPercentage":
                          return [value, "Success %"];
                        case "averageNumberOfDiscoveryAttempts":
                          return [value, "Discovery attempts"];
                        default:
                          return [value, name];
                      }
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey={chartName}
                    stroke="#2770D8"
                    fill="#e4e9f3"
                    strokeWidth={2}
                    dot={false}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="axis_details">
              <p>
                X axis - Date range selected | Y axis -{" "}
                {chartName === "averageSuccessPercentage"
                  ? "Success %"
                  : "Discovery attempts"}
              </p>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const noDataMessage = (
    <div className="no_data">
      <div className="noDataMargin">
        <img src={no_Data} alt="No data available" />
      </div>
      <p>No data available</p>
    </div>
  );

  return (
    <div>
      {chartData.length && !isLoading
        ? chart(chartName)
        : isLoading
        ? circularLoader
        : noDataMessage}
    </div>
  );
}
