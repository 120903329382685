import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "../../components/Loader";
import Controls from "../../components/controls/Controls";
import apiUsers from "../../services/api/Users";
import { addUser } from "../../redux-state/actions/UserAction";
import { UseForm, Form } from "../../components/UseForm";
import { setData } from "../../utils/Storage";
import { messages } from "../../constants/Messages";
import { validateEmail } from "../../utils/FieldValidations";
import { variables } from "../../constants/Variables";
import ElectriphiLoginLogo from "../../assets/images/png/ford-logo-dark.png";

const useStyles = makeStyles(() => ({
  paper: {
    padding: 20,
    width: 350,
  },
  logo: {
    margin: "1em 20px",
  },
  loginButton: {
    margin: "2em 0px",
  },
  divHeight: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const NewLogin = ({ handleRedirect }) => {
  const classes = useStyles();
  return (
    <div className={classes.divHeight}>
      <Grid>
        <Paper elevation={10} className={classes.paper}>
          <Grid align="center" className={classes.logo}>
            <img
              style={{ width: "100%" }}
              alt="Electriphi Login Logo"
              src={ElectriphiLoginLogo}
            />
          </Grid>
          <p style={{ textAlign: "center" }}>Operations Console </p>
          <Controls.Button
            color="primary"
            text="Login"
            fullWidth
            onClick={handleRedirect}
          />
        </Paper>
      </Grid>
    </div>
  );
};

export default NewLogin;
