export const resetFilterFields = (field) => {
  field[0].forEach((parent) => {
    parent.checked = false;
    parent.children.forEach((child) => {
      if (child.children) {
        child.children.forEach((grandChild) => {
          grandChild.checked = false;
        });
      }
      child.checked = false;
    });
  });
  field[1]([...field[0]]);
};
