import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, InputAdornment, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import PageMainContent from "../../../components/PageMainContent";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import Popup from "../../../components/Popup";
import ToastMessage from "../../../components/ToastMessage";
import AddEditManufacturer from "./AddEditManufacturers";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
];

const useStyles = makeStyles(() => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const AggChargers = () => {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [refreshChargers, setRefreshChargers] = useState(true);
  const [data, setData] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "Add Manufacturer",
    child: "",
    item: {},
  });
  const [toast, setToast] = useState("");
  const history = useHistory();

  const classes = useStyles();

  const headCells = useMemo(
    () => [
      { id: "manufacturer", label: "Original Equipment Manufacturer (OEM)" },
      { id: "count", label: "Chargers count" },
    ],
    []
  );

  const { GET_CHARGERS } = indexedEndPoints;
  useEffect(() => {
    const fetchChargers = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(GET_CHARGERS);
      setLoading(false);
      setData(response?.data);
      setRefreshChargers(false);
    };
    refreshChargers && fetchChargers();
  }, [refreshChargers]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const onTableRowClick = (row) => {
    history.push({
      pathname: routePaths.CHARGERS,
      search: `?manufacturer=${encodeURIComponent(row.manufacturer)}`,
      state: { errorCodeGroups: row.errorCodeGroups },
    });
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn, false, false, null, onTableRowClick);

  const ToolbarMemoised = useCallback(({ handleSearch, classes }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className="searchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Grid sm item />
        {/* Just commenting this code so that we reuse it in future when required again */}
        {/* <PrivilegedComponent
          permission="Add Manufacturer"
          module="dataCatalogCharger"
        >
          <Controls.Button
            text="Add OEM"
            variant="outlined"
            testid="addManufacturerBtn"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Add OEM",
                child: "addManufacturer",
                item: {},
              })
            }
          />
        </PrivilegedComponent> */}
      </Toolbar>
    );
  }, []);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Chargers Dashboard"
      />
      <PageHeader title="Chargers" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {openPopup.child === "addManufacturer" && (
          <AddEditManufacturer
            setOpenPopup={setOpenPopup}
            setRefreshChargers={setRefreshChargers}
            setToast={setToast}
          />
        )}
      </Popup>
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

export default AggChargers;
