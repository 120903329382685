import React from "react";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import Fab from "@mui/material/Fab";
import { useHistory } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NewPageMainContent from "../../components/NewPageMainContent";
import CircuitMonitorModel from "./circuitMonitor";

const CircuitMonitor = () => {
  const history = useHistory();
  return (
    <>
      <NewPageMainContent>
        <div className="flex_div_space_between">
          <OpsBreadcrumb title="Circuit Monitor" />
          <Fab
            variant="extended"
            size="small"
            className="fab_btn"
            onClick={() =>
              history.push({
                pathname: `/circuit-monitor/alert-log`,
              })
            }
          >
            <NotificationsNoneIcon sx={{ mr: 1 }} />
            Alert Log
          </Fab>
        </div>
        <CircuitMonitorModel />
      </NewPageMainContent>
    </>
  );
};

export default CircuitMonitor;
