export const mapRouteToTabName = {
  "/login": "",
  "/": "Home",
  "/userAlertSettings": "Home",
  "/charger-health": "Charger Health",
  "/charger-health/open-Faults": "Charger Health",
  "/charger-health/offline-Ports": "Charger Health",
  "/charger-details": "Chargers",
  "/charger-details/charger-history": "Chargers",
  "/charger-details/replacement-history": "Chargers",
  "/usage-analytics": "Usage Analytics",
  "/circuit-monitor": "Circuit Monitor",
  "/circuit-monitor/alert-log": "Circuit Monitor",
  "/users": "Users",
  "/requests": "Requests",
  "/requests/reviewconfirm": "Requests",
  "/companies": "Companies",
  "/companies/:id/depots/:depotId?": "Companies",
  "/billing": "Billing",
  "/billing/:id/billing-depots": "Billing",
  "/billing/:id/history": "Billing",
  "/services": "Services",
  "/services/scheduled": "Services",
  "/services/history": "Services",
  "/pre-provisioning": "Pre Provisioning",
  "/reports": "Reports",
  "/reports/history": "Reports",
  "/dataCatalog": "Data Catalog",
  "/dataCatalog/history": "Data Catalog",
  "/dataCatalog/errorCodesDashboard": "Data Catalog",
  "/dataCatalog/errorCodesDashboard/errorCodes": "Data Catalog",
  "/dataCatalog/errorCodesDashboard/errorCodes/bulkUpload": "Data Catalog",
  "/dataCatalog/chargersDashboard": "Data Catalog",
  "/dataCatalog/chargersDashboard/chargers": "Data Catalog",
  "/dataCatalog/chargersDashboard/chargers/bulkUpload": "Data Catalog",
  "/dataCatalog/vinVidMapping": "Data Catalog",
  "/dataCatalog/vinVidMapping/bulkUpload": "Data Catalog",
  "/dataCatalog/utilityRates": "Data Catalog",
  "/dataCatalog/utilityRates/provider": "Data Catalog",
  "/dataCatalog/utilityRates/searchPlans": "Data Catalog",
  "/dataCatalog/vehiclesDashboard": "Data Catalog",
  "/dataCatalog/vehiclesDashboard/vehicles": "Data Catalog",
  "/dataCatalog/vehiclesDashboard/vehicles/bulkUpload": "Data Catalog",
  "/vehicles": "Vehicle Discovery",
  "/vehicles/depot-details": "Vehicle Discovery",
  "/datasync/events": "Datasync Events",
  "/configurations": "Configurations",
};
