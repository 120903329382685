import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { InputAdornment, Checkbox, Tooltip } from "@mui/material";
import Controls from "../controls/Controls";
import InfoIcon from "@mui/icons-material/Info";

const SingleTypeFilter = (props) => {
  let { _stateVariable, setFuntion, isSearchable = false } = props;
  const { disableCheckBox = false } = _stateVariable[0];
  const [stateVarCopy, setStateVarCopy] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    if (_stateVariable.length) {
      for (var i = 0; i < _stateVariable.length; i++) {
        _stateVariable[i].index = i;
        for (var j = 0; j < _stateVariable[i].children.length; j++)
          _stateVariable[i].children[j].index = j;
      }
      setFuntion([..._stateVariable]);
    }
  }, []);

  React.useEffect(() => {
    if (_stateVariable.length) {
      const tempData = JSON.parse(JSON.stringify(_stateVariable));
      const matched = tempData[0]?.children?.filter((row) =>
        row?.name?.toLowerCase()?.includes(searchText?.trim().toLowerCase())
      );
      setStateVarCopy([
        {
          ...tempData[0],
          children: matched,
        },
      ]);
    }
  }, [searchText, _stateVariable]);

  const expandCollapse = (
    parentInd,
    firstChildIndex = null,
    secondChildIndex = null
  ) => {
    if (secondChildIndex !== null) {
      _stateVariable[parentInd].children[firstChildIndex].children[
        secondChildIndex
      ].isExpanded =
        !_stateVariable[parentInd].children[firstChildIndex].children[
          secondChildIndex
        ].isExpanded;
      setFuntion([..._stateVariable]);
    } else if (firstChildIndex !== null) {
      _stateVariable[parentInd].children[firstChildIndex].isExpanded =
        !_stateVariable[parentInd].children[firstChildIndex].isExpanded;
      setFuntion([..._stateVariable]);
    } else {
      _stateVariable[parentInd].isExpanded =
        !_stateVariable[parentInd].isExpanded;
      setFuntion([..._stateVariable]);
    }
  };

  const parentClicked = (parentIndex) => {
    _stateVariable[parentIndex].checked = !_stateVariable[parentIndex].checked;
    setFuntion([..._stateVariable]);
    if (_stateVariable[parentIndex].checked === true) {
      if (_stateVariable[parentIndex].children) {
        _stateVariable[parentIndex].children.map((single) => {
          single.checked = true;
          setFuntion([..._stateVariable]);
          if (single.children) {
            single.children.map((child) => {
              child.checked = true;
              setFuntion([..._stateVariable]);
            });
          }
        });
      }
    } else {
      if (_stateVariable[parentIndex].children) {
        _stateVariable[parentIndex].children.map((single) => {
          single.checked = false;
          setFuntion([..._stateVariable]);
          if (single.children) {
            single.children.map((child) => {
              child.checked = false;
              setFuntion([..._stateVariable]);
            });
          }
        });
      }
    }
  };

  const childClicked = (parent, child) => {
    _stateVariable[parent].children[child].checked =
      !_stateVariable[parent].children[child].checked;
    setFuntion([..._stateVariable]);

    if (_stateVariable[parent].children[child].checked === true) {
      if (_stateVariable[parent].children[child].children) {
        _stateVariable[parent].children[child].children.map((single) => {
          single.checked = true;
          setFuntion([..._stateVariable]);
        });
      }
    } else {
      if (_stateVariable[parent].children[child].children) {
        _stateVariable[parent].children[child].children.map((single) => {
          single.checked = false;
          setFuntion([..._stateVariable]);
        });
      }
    }

    if (_stateVariable[parent].children) {
      if (
        _stateVariable[parent].children.filter(
          (single) => single.checked === true
        ).length === _stateVariable[parent].children.length
      ) {
        _stateVariable[parent].checked = true;
      } else {
        _stateVariable[parent].checked = false;
      }
      setFuntion([..._stateVariable]);
    }
  };

  return (
    <>
      <div>
        {isSearchable && (
          <div className="filter_Search">
            <Controls.Input
              label={"Search"}
              id={"single_filter_search"}
              style={{ width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        )}
        {stateVarCopy[0]?.children.length > 0 ? (
          stateVarCopy.length > 0 &&
          stateVarCopy.map((acc, index) => (
            <div
              className="side_filter_new"
              key={`allMakes-${index}`}
              style={{
                border: "1px solid #DBE3EA",
                marginBottom: "15px",
                borderRadius: "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                className="filter_parent"
              >
                <Checkbox
                  checked={acc.checked}
                  disabled={disableCheckBox}
                  id={"single_filter_checkbox" + acc?.name}
                  indeterminate={
                    acc.children?.length >
                      acc.children?.filter((value) => value.checked == true)
                        ?.length &&
                    acc.children?.some((value) => value.checked == true)
                  }
                  onClick={() => parentClicked(index)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => expandCollapse(index)}
                >
                  <p className="parent_p">{acc.name}</p>
                  {acc.isExpanded === false ? (
                    <ExpandMoreIcon className="accordion_arrows" />
                  ) : (
                    <ExpandLessIcon className="accordion_arrows" />
                  )}
                </div>
              </div>

              {acc.isExpanded && (
                <>
                  {acc.children &&
                    acc.children.length > 0 &&
                    acc.children.map((acch, ind) => (
                      <div key={ind} style={{ border: "1px solid #DBE3EA" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          className="filter_child"
                        >
                          <Checkbox
                            checked={acch.checked}
                            id={"single_filter_checkbox" + acch?.name}
                            indeterminate={
                              acch.models?.length >
                                acch.models?.filter(
                                  (value) => value.checked == true
                                )?.length &&
                              acch.models?.some(
                                (value) => value.checked == true
                              )
                            }
                            onClick={() => childClicked(index, acch.index)}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className={acch.children && "manual_pointer"}
                            onClick={() => expandCollapse(index, ind)}
                          >
                            <p className="child_p">{acch.name}</p>
                            {acch.hasInfoIcon && (
                              <div style={{ margin: "0 10px" }}>
                                <Tooltip title={acch.tooltip}>
                                  <InfoIcon style={{ color: "#7790A5" }} />
                                </Tooltip>
                              </div>
                            )}
                            {acch.models && (
                              <>
                                {acch.isExpanded === false ? (
                                  <AddIcon className="accordion_arrows" />
                                ) : (
                                  <RemoveIcon className="accordion_arrows" />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          ))
        ) : (
          <p className="no_data_in_filter">No Data</p>
        )}
      </div>
    </>
  );
};
export default SingleTypeFilter;
