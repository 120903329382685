import { LogLevel } from "@azure/msal-browser";
import environment from "./environment";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: environment[process.env.REACT_APP_NODE_ENV].VITE_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${
      environment[process.env.REACT_APP_NODE_ENV].VITE_AZURE_TENANT_ID
    }`,
    redirectUri:
      environment[process.env.REACT_APP_NODE_ENV].VITE_AZURE_REDIRECT_URI,
    postLogoutRedirectUri:
      environment[process.env.REACT_APP_NODE_ENV].VITE_AZURE_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      piiLoggingEnabled: false,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["user.read"],
};
