import apiConfigurations from "../../services/api/Configurations";
import apiUsers from "../../services/api/Users";
import { indexedEndPoints } from "../../services/api/utilities/provider";

export const saveAllUsersGlobal = () => {
  return async (dispatch) => {
    const res = await apiUsers.listUsers();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "GLOBAL_USERS",
        payload: { allUsers: res.data, isUsersLoaded: true },
      });
    } else {
      dispatch({
        type: "GLOBAL_USERS",
        payload: { allUsers: [], isUsersLoaded: true },
      });
    }
  };
};

export const saveAllRolesGlobal = () => {
  const { ROLES } = indexedEndPoints;
  return async (dispatch) => {
    const res = await apiConfigurations.getRoles(ROLES);
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "GLOBAL_ROLES",
        payload: { allRoles: res.data, isRolesLoaded: true },
      });
    } else {
      dispatch({
        type: "GLOBAL_ROLES",
        payload: { allRoles: [], isRolesLoaded: true },
      });
    }
  };
};
