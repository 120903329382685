import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";

const {
  CHARGER_PROFILE,
  GET_CHARGERS_FIRMWARE_DETAILS,
  GET_FIRMWARE_DETAILS_COUNT,
  GET_CHARGERS_PORTS_COUNTS,
  DEPOT_CHARGERS,
  GET_ALL_DEMAND_RESPONSE_PROGRAMS,
  CHARGER_CREDENTIAL,
  CHARGERS_EXPORT_CSV,
  CHARGERS_SERIAL_NO,
} = indexedEndPoints;

export const searchChargerSerialNo = async (srNo) =>
  await apiProvider.getAll(`${CHARGERS_SERIAL_NO}/${srNo}`);

export const getChargerProfile = async (id) =>
  await apiProvider.getAll(`${CHARGER_PROFILE}/${id}/profiles`);

export const getOCPPSettingsByCharger = async (depotID, chargeID) =>
  await apiProvider.getAll(
    `${GET_CHARGERS_FIRMWARE_DETAILS}/ocpp-lastKnown/${depotID}/${chargeID}`
  );

export const getChargerPortCells = async (id) =>
  await apiProvider.getAll(`${CHARGER_PROFILE}/${id}/cells`);

export const getChargersAndFirmware = async (body = {}, queryParam = "") =>
  await apiProvider.post(`${GET_CHARGERS_FIRMWARE_DETAILS}${queryParam}`, body);

export const getFirmwareCountOnCHD = async (body) =>
  body
    ? await apiProvider.post(GET_FIRMWARE_DETAILS_COUNT, body)
    : await apiProvider.post(GET_FIRMWARE_DETAILS_COUNT, {});

export const getCommissionedChargerAndPortCount = async (body) => {
  return await apiProvider.post(GET_CHARGERS_PORTS_COUNTS, body);
};

export const getChargersListForDepot = async (depotId, bool = false) => {
  return await apiProvider.getAll(
    `${DEPOT_CHARGERS}/${depotId}?allChargers=${bool}`
  );
};

export const getAllDRPrograms = async () =>
  await apiProvider.getAll(GET_ALL_DEMAND_RESPONSE_PROGRAMS);

export const getCredentialByChargerOrFesn = async (
  chargerId,
  depotId,
  objectId
) => {
  const baseUrl = `${CHARGER_CREDENTIAL}/${chargerId}`;
  const queryParams = [];

  if (depotId) {
    queryParams.push(`depotId=${encodeURIComponent(depotId)}`);
  }
  if (objectId) {
    queryParams.push(`chargerObjectId=${encodeURIComponent(objectId)}`);
  }
  const url =
    queryParams.length > 0 ? `${baseUrl}?${queryParams.join("&")}` : baseUrl;

  return await apiProvider.getAll(url);
};

export const chargersExportCsv = async (payload) => {
  let dataSize = 0;
  return fetch(CHARGERS_EXPORT_CSV, {
    method: "POST",
    headers: await apiProvider.getHeaders(),
    body: JSON.stringify(payload),
  })
    .then(function (response) {
      if (response.status !== 200) {
        return "";
      } else {
        return response.blob();
      }
    })

    .then((data) => {
      dataSize = data.size;
      if (dataSize > 0) {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `Chargers.csv`;
        a.click();
        return dataSize;
      } else return 0;
    });
};

export default {
  getChargersAndFirmware,
  getCommissionedChargerAndPortCount,
};
