import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Controls from "../../../components/controls/Controls";
import SingleTypeFilter from "../../../components/all-filter-types/SingleFilter";
import TabPanel from "../../../components/all-filter-types/FilterTabPanel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { checkSingleFilterSelected } from "../../../components/all-filter-types/isFilteredCheckFunctions";

const BillingDepotsFilter = (props) => {
  const { applyFilter, depotStatus, allHomeChargingFlag } = props;
  const [value, setValue] = React.useState("Depot Status");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tempAllDepotStatus, setTempAllDepotStatus] = React.useState(
    JSON.parse(JSON.stringify(depotStatus))
  );

  const [tempAllHomeChargingFlag, setTempAllHomeChargingFlag] = React.useState(
    JSON.parse(JSON.stringify(allHomeChargingFlag))
  );

  const clearAll = () => {
    if (tempAllDepotStatus[0].children) {
      tempAllDepotStatus[0].checked = false;
      tempAllDepotStatus[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempAllDepotStatus([...tempAllDepotStatus]);
    if (tempAllHomeChargingFlag[0].children) {
      tempAllHomeChargingFlag[0].checked = false;
      tempAllHomeChargingFlag[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempAllHomeChargingFlag([...tempAllHomeChargingFlag]);
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Depot Status"
              value={"Depot Status"}
              icon={
                checkSingleFilterSelected(tempAllDepotStatus) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="CONFIG FLAGS"
              value={"CONFIG FLAGS"}
              icon={
                checkSingleFilterSelected(tempAllHomeChargingFlag) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"Depot Status"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotStatus}
                setFuntion={setTempAllDepotStatus}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"CONFIG FLAGS"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllHomeChargingFlag}
                setFuntion={setTempAllHomeChargingFlag}
              />
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={
              !(
                checkSingleFilterSelected(tempAllDepotStatus) ||
                checkSingleFilterSelected(tempAllHomeChargingFlag)
              )
            }
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() =>
              applyFilter(tempAllDepotStatus, tempAllHomeChargingFlag)
            }
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default BillingDepotsFilter;
