import React from "react";
import { Chip, Grid, Tooltip, Zoom } from "@mui/material";
import { titleLabels } from "../../../constants/TitleLabels";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import ScienceIcon from "@mui/icons-material/Science";
import PublicIcon from "@mui/icons-material/Public";
import { format } from "date-fns";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import { routePaths } from "../../../constants/RoutePaths";
import { Link } from "react-router-dom";

const ChargerDrawerDetails = ({
  drawerObj,
  firmwareChargers,
  handleDeleteFirmware,
  handleConvertToTestVersion,
  handleConvertToReleaseVersion,
}) => {
  return (
    <div className="chargerModelsDrawer">
      <div className="chargeDetailsSideDrawer">
        <div className="charger_details_section">
          <div className="charger_heading">
            <p>MODEL DETAILS</p>
          </div>
          <div className="all_details">
            <div className="single_detail">
              <p className="c_title">Manufacturer (OEM):</p>
              <p className="description">
                {drawerObj.vendorManufacturer || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Model (OEM):</p>
              <p className="description">
                {drawerObj.vendorModel || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Charger Meta ID:</p>
              <p className="description">
                {drawerObj.chargerMetaId || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Min Charging Rate:</p>
              <p className="description">
                {drawerObj.minChargingRate || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Max Charging Rate:</p>
              <p className="description">
                {drawerObj.maxChargingRate || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Charging Rate Unit:</p>
              <p className="description">
                {drawerObj.chargingRateUnit || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Max Amps:</p>
              <p className="description">
                {drawerObj.maxAmps || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Max Voltage:</p>
              <p className="description">
                {drawerObj.maxVoltage || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Network Type:</p>
              <p className="description">
                {drawerObj.networkType || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Power Type:</p>
              <p className="description">
                {drawerObj.powerType || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Type of Connector:</p>
              <p className="description">
                {drawerObj.typeOfConnectorList?.map((item, i) => (
                  <div key={i}>
                    <p className="description">{item}</p>
                  </div>
                ))}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Number of Ports:</p>
              <p className="description">
                {drawerObj.numberOfPorts || titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Min Temperature:</p>
              <p className="description">
                {drawerObj.temperatureRange?.min
                  ? drawerObj.temperatureRange?.min + " celsius"
                  : titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Max Temperature:</p>
              <p className="description">
                {drawerObj.temperatureRange?.max
                  ? drawerObj.temperatureRange?.max + " celsius"
                  : titleLabels.NOT_AVAILABLE}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Error code group:</p>
              <p className="description">
                {drawerObj.errorCodeGroupName ? (
                  <Link
                    to={`${routePaths.ERROR_CODES}?groupId=${drawerObj.errorCodeGroupId}`}
                  >
                    {drawerObj.errorCodeGroupName}
                  </Link>
                ) : (
                  titleLabels.NOT_AVAILABLE
                )}
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">RFID capable:</p>
              <p className="description">
                <Chip
                  label={drawerObj.isRfidCapable === true ? "True" : "False"}
                  style={{
                    color:
                      drawerObj.isRfidCapable === true ? "#00765E" : "#D01300",
                    background:
                      drawerObj.isRfidCapable === true ? "#E5F1EF" : "#FAE7E5",
                  }}
                />
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">Customer visibility:</p>
              <p className="description">
                <Chip
                  label={
                    drawerObj.isCustomerVisible === true ? "True" : "False"
                  }
                  style={{
                    color:
                      drawerObj.isCustomerVisible === true
                        ? "#00765E"
                        : "#D01300",
                    background:
                      drawerObj.isCustomerVisible === true
                        ? "#E5F1EF"
                        : "#FAE7E5",
                  }}
                />
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">EVCC ID capable:</p>
              <p className="description">
                <Chip
                  label={drawerObj.isEVCCIdCapable === true ? "True" : "False"}
                  style={{
                    color:
                      drawerObj.isEVCCIdCapable === true
                        ? "#00765E"
                        : "#D01300",
                    background:
                      drawerObj.isEVCCIdCapable === true
                        ? "#E5F1EF"
                        : "#FAE7E5",
                  }}
                />
              </p>
            </div>
            <div className="single_detail">
              <p className="c_title">White labelled model:</p>
              <p className="description">
                <Chip
                  label={drawerObj.isWhiteLabelled === true ? "Yes" : "No"}
                  style={{
                    color:
                      drawerObj.isWhiteLabelled === true
                        ? "#00765E"
                        : "#D01300",
                    background:
                      drawerObj.isWhiteLabelled === true
                        ? "#E5F1EF"
                        : "#FAE7E5",
                  }}
                />
              </p>
            </div>
          </div>
        </div>
        {drawerObj?.isRfidCapable &&
          drawerObj?.rfidConfig?.length > 0 &&
          drawerObj?.rfidConfig[0]?.key?.length > 0 && (
            <div className="charger_details_section" id="rfidAuthModeContainer">
              <div className="charger_heading">
                <p>RFID AUTHENTICATION CONFIGURATION</p>
              </div>
              <div className="rfidTable">
                <Grid container className="rfidAuthModeHeading">
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    Freevend mode
                  </Grid>
                  <Grid item xs={4}>
                    Authentication mode
                  </Grid>
                </Grid>
                {drawerObj?.rfidConfig?.map((row, ind) => (
                  <Grid
                    container
                    key={ind}
                    spacing={0}
                    className="rfidAuthModeRows"
                  >
                    <Grid item xs={4} className="rfidAuthModeKey rowHeight">
                      <p>{row.key?.toString()}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p>{row.freevendValue?.toString()}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p>{row.authValue?.toString()}</p>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </div>
          )}
        <div className="charger_details_section" id="rfidAuthModeContainer">
          <div className="charger_heading">
            <p>WHITE LABEL CONFIGURATION</p>
            <p className="viewWhitelabelConfig">
              Customers will see white label names as manufacturer and model in
              CMS
            </p>
          </div>
          <div className="rfidTable">
            <Grid container className="rfidAuthModeHeading">
              <Grid item xs={6}>
                Sold as manufacturer
              </Grid>
              <Grid item xs={6}>
                Sold as model
              </Grid>
            </Grid>
            {drawerObj?.soldAs?.map((row, ind) => (
              <Grid
                container
                key={ind}
                spacing={0}
                className="rfidAuthModeRows"
              >
                <Grid item xs={6}>
                  <p>{row.whiteLabelManufacturer?.toString()}</p>
                </Grid>
                <Grid item xs={6}>
                  <p>{row.whiteLabelModel?.toString()}</p>
                </Grid>
              </Grid>
            ))}
          </div>
        </div>
        {drawerObj.firmwareDetails?.length > 0 && (
          <div className="charger_details_section">
            <div className="charger_heading">
              <p className="availableFirmwares">
                AVAILABLE FIRMWARE VERSIONS
                <Tooltip
                  arrow
                  TransitionComponent={Zoom}
                  title="Each model may store up to 2 general release versions and 1 test version."
                >
                  <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
                </Tooltip>
              </p>
            </div>
            {drawerObj.firmwareDetails?.map((row, ind) => (
              <div className="all_details" key={ind} id="versionBorder">
                <div className="availableFirmwares">
                  <div className="firmwareInfo">
                    {row.isTestVersion && (
                      <ScienceIcon
                        fontSize="small"
                        className="testVersionIcon"
                      />
                    )}
                    <p className="version">{row.version}&nbsp;</p>
                    <p className="uploadTime">{`Uploaded on ${format(
                      new Date(row.createdAt),
                      "dd/MM/yyyy hh:mm a"
                    )}`}</p>
                  </div>
                  <PrivilegedComponent
                    permission="Manage Firmware"
                    module="dataCatalogCharger"
                  >
                    <div className="firmwareActions">
                      {row.isTestVersion ? (
                        <Tooltip
                          arrow
                          TransitionComponent={Zoom}
                          title="Ready for general release"
                        >
                          <PublicIcon
                            fontSize="small"
                            className="convertVersionBtn"
                            data-testid={`convertToReleaseBtn${ind}`}
                            onClick={() =>
                              handleConvertToReleaseVersion(
                                row.version,
                                drawerObj.chargerMetaId,
                                drawerObj.firmwareDetails
                                  ?.filter((item) => !item.isTestVersion)
                                  ?.map((item) => item.version)
                              )
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          arrow
                          TransitionComponent={Zoom}
                          title="Revert to test version"
                        >
                          <ScienceIcon
                            fontSize="small"
                            className="convertVersionBtn"
                            data-testid={`convertToTestBtn${ind}`}
                            onClick={() =>
                              handleConvertToTestVersion(
                                row.version,
                                drawerObj.chargerMetaId,
                                drawerObj.firmwareDetails?.find(
                                  (item) => item.isTestVersion
                                )?.version
                              )
                            }
                          />
                        </Tooltip>
                      )}
                      <Tooltip arrow TransitionComponent={Zoom} title="Delete">
                        <DeleteIcon
                          fontSize="small"
                          className="deleteFirmwareBtn"
                          data-testid={`deleteFirmwareBtn${ind}`}
                          onClick={() =>
                            handleDeleteFirmware(
                              firmwareChargers[row.version]?.chargerIds,
                              row.version,
                              drawerObj.chargerMetaId
                            )
                          }
                        />
                      </Tooltip>
                    </div>
                  </PrivilegedComponent>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChargerDrawerDetails;
