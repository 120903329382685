import { ListItemText, Typography, Chip } from "@mui/material";
import { titleLabels } from "../../constants/TitleLabels";

const DEFAULT_DEPOTS = [
  "819ad3e6-4fba-4032-884c-ea4c8d9fb800",
  "b530125a-56e2-4d93-a43e-35164a0e53e5",
  "91ae5f46-4270-4a30-8372-5261254a9089",
  "68792cc2-ea93-4241-91dc-b1c49038995a",
  "41edb69f-2e3c-49fc-8033-7bef5f71e19d",
  "9de832b6-6b03-4c9d-99d3-4ef33737b569",
  "63db9252-afdb-4884-b768-9bc819f5ba31",
  "05a9162a-3420-4de7-8b58-c72703859d0b",
  "58aa8839-11a8-4d99-b424-3574209075c8",
  "95a65cec-1f70-43b9-a0e2-7f7191208746",
  "e9187885-bfd6-4f40-be82-b14901cc4854",
  "b3a2d955-c350-4870-b459-4f02e4e0cfad",
  "26411220-629b-4e8e-8191-859dea860508",
  "cab7a049-745c-456b-ab65-be6c9e74872b",
  "d2d7e27e-6593-48a7-97d2-8156681976b7",
  "e948ffcb-c85e-4f32-8f75-4c09471d31e8",
];

const TEST_DEPOT_ID_LIST = [
  "819ad3e6-4fba-4032-884c-ea4c8d9fb800",
  "b530125a-56e2-4d93-a43e-35164a0e53e5",
  "91ae5f46-4270-4a30-8372-5261254a9089",
  "68792cc2-ea93-4241-91dc-b1c49038995a",
];

const PROD_DEPOT_ID_LIST = [
  "41edb69f-2e3c-49fc-8033-7bef5f71e19d",
  "9de832b6-6b03-4c9d-99d3-4ef33737b569",
  "63db9252-afdb-4884-b768-9bc819f5ba31",
  "05a9162a-3420-4de7-8b58-c72703859d0b",
  "58aa8839-11a8-4d99-b424-3574209075c8",
  "95a65cec-1f70-43b9-a0e2-7f7191208746",
  "e9187885-bfd6-4f40-be82-b14901cc4854",
  "b3a2d955-c350-4870-b459-4f02e4e0cfad",
  "26411220-629b-4e8e-8191-859dea860508",
  "cab7a049-745c-456b-ab65-be6c9e74872b",
  "d2d7e27e-6593-48a7-97d2-8156681976b7",
  "e948ffcb-c85e-4f32-8f75-4c09471d31e8",
];

const averageSuccessPercentageInfo =
  "This graph shows how successful our software is at identifying discoverable vehicles on any given day. For example, a value of “70%” on October 17th, 2023 means that on that date, we were able to successfully identify 70% of the discoverable vehicles that connected to one of our chargers on that day. For a vehicle to be discoverable, it must be a Ford vehicle, with Telematics enabled, subscribed to the “Depot Charging” SKU in FFM, and be registered to the proper depot in the FPC SaaS. If all of those conditions are not met, that vehicle is not “discoverable” and our software could never have identified it";

const averageNumberOfDiscoveryAttemptsInfo =
  "This graph shows the average number of Telematics discovery attempts per session initiated on a given day. For example, a value of “4” on October 17th, 2023 means that on that date, the average number of Telematics discovery attempts, across all sessions initiated on that date, was 4.";

const depotSuccessRateInfo =
  "This graph shows the overall vehicle discovery success rate for discoverable sessions at a given depot, inclusive of both AC and DC chargers.";

const modelSuccessRateInfo =
  "This graph shows the overall vehicle discovery success rate discoverable sessions performed on a given charger model across all depots at which instances of that charger model are installed.";

const vehiclesDistributionInfo =
  "This pie chart shows the overall distribution of vehicles with and without VIN-VID mapping across the filtered depot(s). A higher number of mapped vehicles means higher chances of vehicle discovery. This information comes from the FPC data catalog.";

const chargersDistributionInfo =
  "This pie chart shows the overall distribution of chargers with and without location across the filtered depot(s). A higher number of chargers with set location means higher chances of vehicle discovery. A valid location schema should have GeoJSON {type: GeoJsonPositionType; coordinates: number[];}.";

const ALL_SESSIONS_TABLE_HEADERS = [
  { key: "depotName", name: "Depot Name", checked: false },
  { key: "depotId", name: "Depot Id", checked: false },
  { key: "sessionId", name: "Session Id", checked: false },
  { key: "chargerId", name: "Charger Id", checked: false },
  { key: "chargerModel", name: "Charger Model", checked: false },
  { key: "chargerMake", name: "Charger Make", checked: false },
  { key: "powerType", name: "PowerType", checked: false },
  { key: "vehiclePluginTime", name: "Session Start Time", checked: false },
  { key: "vin", name: "VIN", checked: false },
  { key: "sessionDuration", name: "Session Duration(Secs)", checked: false },
  { key: "discoveryAttempts", name: "Discovery", checked: false },
  { key: "successFullAttempts", name: "Successful", checked: false },
];

const ALL_SESSIONS_TABLE_HEAD_CELLS = [
  {
    id: "depotId",
    label: "Depot",
    render: (row, col) => (
      <ListItemText primary={row.depotName} secondary={row.depotId} />
    ),
  },
  {
    id: "chargerId",
    label: "Charger Id",
    render: (row, col) => row.chargerId || titleLabels.NOT_AVAILABLE,
  },
  {
    id: "chargerModel",
    label: "Charger Model",
    render: (row, col) => (
      <ListItemText
        disableTypography
        primary={row.chargerModel}
        secondary={
          <span className="makeModelPower">
            <Typography className="make">{row.chargerMake}</Typography>
            <Chip label={row.powerType} className="powerType"></Chip>
          </span>
        }
      />
    ),
  },
  {
    id: "vehiclePluginTime",
    label: "Session Start Time",
    render: (row, col) =>
      row.vehiclePluginTime[0]?.replace("GMT", "UTC") ||
      titleLabels.NOT_AVAILABLE,
  },
  {
    id: "vin",
    label: "VIN",
    render: (row, col) => row.vin || titleLabels.NOT_AVAILABLE,
  },
  {
    id: "sessionDuration",
    label: "Session Duration(Secs)",
    render: (row, col) => row.sessionDuration || titleLabels.NOT_AVAILABLE,
  },
  {
    id: "discoveryAttempts",
    label: "Discovery",
    render: (row, col) => row.discoveryAttempts || titleLabels.NOT_AVAILABLE,
  },
  {
    id: "successFullAttempts",
    label: "Successful",
    render: (row, col) => (row.successFullAttempts === 0 ? "No" : "Yes"),
  },
];

const ALL_SESSIONS_COLUMN_HEADERS = [
  { key: "depotName", label: "Depot Name" },
  { key: "depotId", label: "Depot Id" },
  { key: "sessionId", label: "Session Id" },
  { key: "chargerId", label: "Charger Id" },
  { key: "chargerModel", label: "Charger Model" },
  { key: "chargerMake", label: "Charger Make" },
  { key: "powerType", label: "PowerType" },
  { key: "vehiclePluginTime", label: "Session Start Time" },
  { key: "vin", label: "VIN" },
  { key: "sessionDuration", label: "Session Duration(Secs)" },
  { key: "discoveryAttempts", label: "Discovery" },
  { key: "successFullAttempts", label: "Successful" },
];

const INSIGHTS_TABLE_HEADERS_1 = [
  { key: "depotName", name: "Depot name", checked: false },
  { key: "organizationName", name: "Organization name", checked: false },
  {
    key: "totalNumberOfSuccesfullDiscoverySessions",
    name: "Successfull discovery attempts",
    checked: false,
  },
  { key: "totalSessions", name: "Total sessions", checked: false },
  {
    key: "vehiclesRegisteredToDepot",
    name: "Registered Vehicles",
    checked: false,
  },
];

const INSIGHTS_TABLE_HEAD_CELLS_1 = [
  {
    id: "depotName",
    label: "Depot",
    styles: { width: "20vw" },
    render: (row, col) => (
      <ListItemText primary={row.depotName} secondary={row.organizationName} />
    ),
  },
  {
    id: "identifiedSessions",
    label: "Identified sessions",
    styles: { width: "15vw" },
    render: (row, col) => (
      <ListItemText
        primary={row.totalNumberOfSuccesfullDiscoverySessions}
        secondary={"of " + row.totalSessions}
      />
    ),
  },
  {
    id: "success",
    label: "Success %",
    styles: { width: "12vw" },
    render: (row, col) =>
      Math.round(
        Number(
          (row.totalNumberOfSuccesfullDiscoverySessions / row.totalSessions) *
            100
        ),
        2
      ) + "%",
  },
  {
    id: "vehiclesRegisteredToDepot",
    label: "Registered Vehicles",
    styles: { width: "20vw" },
    render: (row, col) => (
      <ListItemText primary={row.vehiclesRegisteredToDepot} />
    ),
  },
];

const INSIGHTS_COLUMN_HEADERS_1 = [
  { key: "depotName", label: "Depot" },
  { key: "organizationName", label: "Organization name" },
  {
    key: "totalNumberOfSuccesfullDiscoverySessions",
    label: "Successfull discovery attempts",
  },
  { key: "totalSessions", label: "Total sessions" },
  { key: "vehiclesRegisteredToDepot", label: "Registered Vehicles" },
];

const INSIGHTS_TABLE_HEADERS_2 = [
  { key: "chargerMake", name: "Charger make", checked: false },
  { key: "chargerModel", name: "Charger model", checked: false },
  {
    key: "totalNumberOfSuccesfullDiscoverySessions",
    name: "Successfull discovery attempts",
    checked: false,
  },
  { key: "totalSessions", name: "Total sessions", checked: false },
  { key: "powerType", name: "Charger Type", checked: false },
];

const INSIGHTS_TABLE_HEAD_CELLS_2 = [
  {
    id: "chargerModel",
    label: "Charger model",
    styles: { width: "20vw" },
    render: (row, col) => (
      <ListItemText
        disableTypography
        primary={row.chargerModel}
        secondary={
          <span className="makeModelPower">
            <Typography className="make">{row.chargerMake}</Typography>
            <Chip label={row.powerType} className="powerType"></Chip>
          </span>
        }
      />
    ),
  },
  {
    id: "identifiedSessions",
    label: "Identified sessions",
    styles: { width: "15vw" },
    render: (row, col) => (
      <ListItemText
        primary={row.totalNumberOfSuccesfullDiscoverySessions}
        secondary={"of " + row.totalSessions}
      />
    ),
  },
  {
    id: "success",
    label: "Success %",
    styles: { width: "12vw" },
    render: (row, col) =>
      Math.round(
        Number(
          (row.totalNumberOfSuccesfullDiscoverySessions / row.totalSessions) *
            100
        ),
        2
      ) + "%",
  },
];

const INSIGHTS_COLUMN_HEADERS_2 = [
  { key: "chargerMake", label: "Charger make" },
  { key: "chargerModel", label: "Charger model" },
  {
    key: "totalNumberOfSuccesfullDiscoverySessions",
    label: "Successfull discovery attempts",
  },
  { key: "totalSessions", label: "Total sessions" },
  { key: "powerType", label: "Charger Type" },
];

export {
  DEFAULT_DEPOTS,
  TEST_DEPOT_ID_LIST,
  PROD_DEPOT_ID_LIST,
  ALL_SESSIONS_TABLE_HEADERS,
  ALL_SESSIONS_TABLE_HEAD_CELLS,
  ALL_SESSIONS_COLUMN_HEADERS,
  INSIGHTS_TABLE_HEADERS_1,
  INSIGHTS_TABLE_HEAD_CELLS_1,
  INSIGHTS_COLUMN_HEADERS_1,
  INSIGHTS_TABLE_HEADERS_2,
  INSIGHTS_TABLE_HEAD_CELLS_2,
  INSIGHTS_COLUMN_HEADERS_2,
  averageSuccessPercentageInfo,
  averageNumberOfDiscoveryAttemptsInfo,
  depotSuccessRateInfo,
  modelSuccessRateInfo,
  vehiclesDistributionInfo,
  chargersDistributionInfo,
};
