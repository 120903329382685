import React, { useCallback, useState } from "react";
import { Button, Toolbar } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { startCase } from "lodash";
import useTable from "./UseTable";

const UploadAndPreviewCSV = () => {
  const [fileName, setFileName] = useState("");
  const [headers, setHeaders] = useState(null);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [errors, setErrors] = useState("");

  const handleFileUpload = (e, dataHeaders) => {
    const file = e?.target?.files?.[0];
    setFileName(file?.name);
    if (!file?.type?.includes("csv")) {
      setErrors("Uploaded file is not a CSV file. Please check the file.");
      return;
    }
    const reader = new FileReader();
    reader.onload = (ev) => {
      const content = ev.target.result
        ?.split("\n")
        ?.filter((row) => row?.trim()?.length > 0);
      const headers = content[0]
        .replaceAll('"', "")
        .replaceAll("\r", "")
        .split(",");
      if (content?.length < 2) {
        setErrors("Empty CSV file uploaded. Please check the file.");
        return;
      }
      if (
        !(
          headers.every((item) => dataHeaders.includes(item)) &&
          dataHeaders.every((item) => headers.includes(item))
        )
      ) {
        setErrors("Invalid CSV file uploaded. Please check the file.");
        return;
      }
      const data = [];
      content?.slice(1)?.forEach((row) => {
        const dataRow = row.replaceAll('"', "").replaceAll("\r", "").split(",");
        const isEmptyRow = dataRow?.every((item) => !item || item.length === 0);
        if (!isEmptyRow) data.push(dataRow);
      });
      setHeaders(headers);
      setData(data);

      const tableHeaders = headers?.map((item) => ({
        id: item,
        label: startCase(item),
      }));
      setTableHeaders(tableHeaders);
      const tableData = data?.map((row) => {
        let rowObj = {};
        row?.forEach((item, ind) => (rowObj[headers[ind]] = item));
        return rowObj;
      });
      setTableData(tableData);
    };
    reader.readAsText(file);
  };

  const ToolbarMemoised = useCallback(() => {
    return (
      <Toolbar className="table_toolbar">
        <p style={{ fontWeight: "bold", fontSize: "18px" }}>Preview</p>
      </Toolbar>
    );
  }, []);

  const UploadPreviewCSV = ({
    headers,
    showErrorsWithPreview = false,
    showPreviewLabel = true,
  }) => {
    const {
      tableContainer: TableContainer,
      tableHead: TableHead,
      tableBody: TableBody,
      tablePagination: TablePagination,
    } = useTable(tableData, tableHeaders, filterFn);

    return (
      <div style={{ margin: "10px 0" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            className="csvButton"
          >
            <ArrowUpwardIcon />
            &nbsp;Select CSV File
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={(e) => {
                setErrors("");
                setTableHeaders([]);
                setTableData([]);
                handleFileUpload(e, headers);
              }}
            />
          </Button>
          <div style={{ paddingLeft: "10px" }}>{fileName}</div>
        </div>
        <div style={{ padding: "10px", color: "red" }}>{errors}</div>
        {tableData?.length > 0 && (showErrorsWithPreview || errors === "") && (
          <div style={{ margin: "20px 0" }}>
            {showPreviewLabel && <ToolbarMemoised />}
            <TableContainer>
              <TableHead />
              {TableBody}
            </TableContainer>
            {TablePagination}
          </div>
        )}
      </div>
    );
  };

  return {
    headers,
    data,
    tableHeaders,
    tableData,
    setTableData,
    fileName,
    setFileName,
    errors,
    setErrors,
    UploadPreviewCSV,
  };
};

export default UploadAndPreviewCSV;
