import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Popup from "../../../components/Popup";
import ComponentLoader from "../../../components/ComponentLoader";
import { fetchDatadogLogsForSession } from "../vehicleDiscoveryServices";

function transformArrayOfObjects(array, chargerPluginTime) {
  return array.map((obj) => {
    // Subtract the charger pluginTime from the telematics plug status signalStartAt for time diff
    return {
      ...obj,
      timeDiff: obj.signalStartAt - chargerPluginTime,
      signalStartAt: new Date(obj.signalStartAt * 1000).toISOString() + " UTC",
    };
  });
}

const CustomTooltip = ({ active, payload, label }) => {
  const vehicle = payload[0]?.payload?.vin;
  if (active && payload && payload.length && vehicle) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`VIN : ${vehicle}`}</p>
        <p className="intro">{`Coordinates : (${payload[0]?.payload?.latitude},${payload[0]?.payload?.longitude})`}</p>
        <p className="desc">{`Distance : ${payload[0]?.payload?.distanceFromDepotInMeters}`}</p>
        <p className="desc">{`Time Diff : ${payload[0]?.payload?.timeDiff}`}</p>
        <p className="desc">{`signalStartAt (Telematics) : ${payload[0]?.payload?.signalStartAt}`}</p>
      </div>
    );
  } else if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="desc">{`Time Diff : ${payload[0]?.payload?.timeDiff}`}</p>
        <p className="desc">{`Distance : ${payload[0]?.payload?.distanceFromDepotInMeters}`}</p>
        <p className="desc">{`pluginTime (Charger) : ${payload[0]?.payload?.signalStartAt}`}</p>
      </div>
    );
  }

  return null;
};

export default function VDScatterChart({
  openChartPopup,
  setOpenChartPopup,
  sessionData,
}) {
  const dateString = sessionData?.vehiclePluginTime[0]; // time when the charger was connected
  const date = new Date(dateString);
  const epochMilliseconds = date.getTime();
  const chargerData = [
    {
      signalStartAt: epochMilliseconds
        ? new Date(epochMilliseconds).toISOString() + " UTC"
        : 0,
      timeDiff: 0,
      distanceFromDepotInMeters: 0,
    },
  ]; // charger is at origin
  const [isLoading, setLoading] = React.useState(false);
  const [vdData, setVDData] = React.useState([]);

  const apiCallToFetchDatadogLogs = async () => {
    if (
      !(
        sessionData?.depotId &&
        sessionData?.sessionId &&
        sessionData?.vehiclePluginTime[0]
      )
    )
      return;
    setLoading(true);
    const payload = {
      depotId: [sessionData?.depotId],
      sessionId: sessionData?.sessionId,
      vehiclePluginTime: epochMilliseconds,
    };
    const res = await fetchDatadogLogsForSession(payload);
    setLoading(false);

    if (res.statusCode === 200) {
      const data = transformArrayOfObjects(
        res.data,
        Math.round(epochMilliseconds / 1000) // converting it to seconds since signalStartAt received will be in seconds
      );
      setVDData(data);
    }
  };

  React.useEffect(() => {
    apiCallToFetchDatadogLogs();
  }, [sessionData]);

  const circularLoader = (
    <div className="checkVinVid_section">
      <div className="checkVinVid_section_loader">
        <ComponentLoader isLoading={isLoading} />
      </div>
    </div>
  );

  return (
    <Popup
      openPopup={openChartPopup}
      setOpenPopup={setOpenChartPopup}
      bottom={10}
      minWidth={"80%"}
    >
      {isLoading ? (
        circularLoader
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 30,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="timeDiff"
              name="time"
              unit="s"
              label={{ value: "Time", position: "insideBottom", offset: -20 }}
            />
            <YAxis
              type="number"
              dataKey="distanceFromDepotInMeters"
              name="distance"
              unit="m"
              label={{
                value: "Distance/Radius",
                angle: -90,
                position: "insideLeft",
                offset: -15,
              }}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ strokeDasharray: "3 3" }}
            />
            <Scatter
              name="signalStartAt (Telematics)"
              data={vdData}
              fill="blue"
            />
            <Scatter
              name="pluginTime (Charger)"
              data={chargerData}
              fill="red"
            />
          </ScatterChart>
        </ResponsiveContainer>
      )}
    </Popup>
  );
}
