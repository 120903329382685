import "./index.css";

import App from "./app/App";
import AxiosInterceptor from "./services/api/utilities/AxiosInterceptor";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./redux-state/store";
import reportWebVitals from "./reportWebVitals";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth-config";

const store = configureStore();
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGOUT_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

AxiosInterceptor();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App instance={msalInstance} />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
