import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import useTable from "../../../../components/UseTable";
import "../../../billing/MoreInfo.scss";
import { firmwareStatusEnum } from "../../CHDEnum";
import ScienceIcon from "@mui/icons-material/Science";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../../services/api/utilities/provider";
import Loader from "../../../../components/Loader";

const headCells = [
  {
    id: "firmwareVersion",
    label: "Firmware Version",
    render: (row, col) => (
      <p className="centerAligned zeroMargin">
        {row?.isTestVersion && (
          <ScienceIcon fontSize="small" className="testVersionIcon" />
        )}{" "}
        {row[col] || "NA"}
      </p>
    ),
  },
  {
    id: "updatedAt",
    label: "Updated At",
    render: (row, col) => row[col]?.replace("GMT", "UTC"),
    type: "date",
  },
];

const FirmwareHistorySideBar = (props) => {
  const { CHARGER_EXTRA_DETIALS } = indexedEndPoints;
  const data = props.DrawerOC.data;
  const filterFn = useMemo(() => ({ fn: (items) => items }), []);
  const [firmwareHistory, setFirmwareHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFirmwareHistory = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(
        `${CHARGER_EXTRA_DETIALS}/${data.depotId}/${data.chargerId}/details`
      );
      setLoading(false);
      if (response?.statusCode === 200)
        setFirmwareHistory(response?.data?.firmwareVersionHistory);
    };
    getFirmwareHistory();
  }, []);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(firmwareHistory, headCells, filterFn);

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">ACCOUNT</p>
            <p className="plan_value">{data.accountName || "NA"}</p>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">DEPOT</p>
            <p className="plan_value">{data.depotName || "NA"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">MANUFACTURER</p>
            <p className="plan_value">{data.chargerManufacturer || "NA"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">MODEL</p>
            <p className="plan_value">{data.chargerModel || "NA"}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">CURRENT FIRMWARE VERSION</p>
            <p className="plan_value centerAligned">
              {data.isTestVersion && (
                <ScienceIcon fontSize="small" className="testVersionIcon" />
              )}{" "}
              {data.firmwareVersion || "NA"}
            </p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="single_plan_details_section">
            <p className="plan_title">FIRMWARE STATUS</p>
            <p className="plan_value">
              {firmwareStatusEnum[data.firmwareStatus] || "NA"}
            </p>
          </div>
        </Grid>
      </Grid>
      <Loader isLoading={loading} />
      {firmwareHistory?.length > 0 ? (
        <>
          <TableContainer>
            <TableHead />
            {TableBody}
          </TableContainer>
          {TablePagination}
        </>
      ) : (
        !loading && <p>No Firmware history available</p>
      )}
    </>
  );
};
export default FirmwareHistorySideBar;
