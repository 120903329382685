import React, { useEffect, useMemo } from "react";
import { Grid, Tooltip } from "@mui/material";
import Controls from "../../../../components/controls/Controls";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { UseForm, Form } from "../../../../components/UseForm";
import { messages } from "../../../../constants/Messages";
import InfoIcon from "@mui/icons-material/Info";
import { getChargerPortCells } from "../chargerFirmwareService";

import {
  apiProvider,
  indexedEndPoints,
} from "../../../../services/api/utilities/provider";
import {
  chargerPortMinMaxAmpCheck,
  chargerPortMinRateCheck,
  chargerPortMaxRateCheck,
} from "../../../../utils/chargerValidations";

const AddEditChargerPort = (props) => {
  let initialFieldValues = {};
  const { CHARGER_PROFILE } = indexedEndPoints;
  const data = props.DrawerOC.data;
  const allMinChargingConnected = props.details?.portsDetails
    ?.map((obj) => obj["minimumChargingRate"])
    ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const [cellIdOptions, setCellIdOptions] = React.useState([]);
  const [checkPortAmp, setCheckPortAmp] = React.useState(false);

  const [checkMinRate, setCheckMinRate] = React.useState(false);
  const [checkMaxRate, setCheckMaxRate] = React.useState(false);
  const defaultPortName =
    props.portCount === 0
      ? props?.parentCharger?.serialNumber
      : `${props?.parentCharger?.serialNumber}_${props.portCount + 1}`;
  initialFieldValues = {
    portName:
      props.DrawerOC.child === "editChargerPort"
        ? data.portName
        : defaultPortName,
    minimumChargingRate:
      props.DrawerOC.child === "editChargerPort"
        ? data.minimumChargingRate
        : props?.parentCharger?.minChargingRate,
    maxCapacity:
      props.DrawerOC.child === "editChargerPort"
        ? data.maxCapacity
        : props?.parentCharger?.maxChargingRate,
    typeConnector:
      props.DrawerOC.child === "editChargerPort"
        ? data.typeConnector
        : props?.parentCharger?.typeConnector?.[0],
    description:
      props.DrawerOC.child === "editChargerPort" ? data.typeConnector : "",
    maxAmps:
      props.DrawerOC.child === "editChargerPort"
        ? data.maxAmps
        : props?.parentCharger?.maxAmps,
    cellId: props.DrawerOC.child === "editChargerPort" ? data.cellId : "",
    portDescription:
      props.DrawerOC.child === "editChargerPort" ? data.portDescription : "",
  };

  const connectorTypeOptions = props?.parentCharger?.typeConnector?.map(
    (item) => ({ label: item, value: item })
  ) || [
    {
      label: "J1772",
      value: "J1772",
    },
    {
      label: "J1772 type 2",
      value: "J1772 type 2",
    },
    {
      label: "CCS1",
      value: "CCS1",
    },
    {
      label: "CCS2",
      value: "CCS2",
    },
    {
      label: "CHAdeMO",
      value: "CHAdeMO",
    },
  ];

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("portName" in fieldValues)
      temp.portName = fieldValues.portName ? "" : messages.FIELD_REQUIRED;
    if ("minimumChargingRate" in fieldValues)
      temp.minimumChargingRate =
        fieldValues.minimumChargingRate || fieldValues.minimumChargingRate === 0
          ? parseInt(fieldValues.minimumChargingRate) < 0
            ? "Min Charging Rate should not be Negative"
            : ""
          : messages.FIELD_REQUIRED;
    if ("maxAmps" in fieldValues)
      temp.maxAmps = fieldValues.maxAmps
        ? parseInt(fieldValues.maxAmps) <= 0
          ? "Max amps should not be Negative or 0"
          : ""
        : messages.FIELD_REQUIRED;
    if ("maxCapacity" in fieldValues)
      temp.maxCapacity = fieldValues.maxCapacity
        ? parseInt(fieldValues.maxCapacity) <= 0
          ? "Max Charging Rate should not be Negative or 0"
          : ""
        : messages.FIELD_REQUIRED;
    if ("typeConnector" in fieldValues)
      temp.typeConnector = fieldValues.typeConnector
        ? ""
        : messages.FIELD_REQUIRED;
    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(initialFieldValues, true, validate);

  initialFieldValues = {
    portName: props.DrawerOC.child === "editChargerPort" ? data.portName : "",
    minimumChargingRate:
      props.DrawerOC.child === "editChargerPort"
        ? data.minimumChargingRate
        : "",
    maxCapacity:
      props.DrawerOC.child === "editChargerPort" ? data.maxCapacity : "",
    typeConnector:
      props.DrawerOC.child === "editChargerPort"
        ? data.typeConnector
        : props?.parentCharger?.typeConnector?.[0],
    description:
      props.DrawerOC.child === "editChargerPort" ? data.typeConnector : "",
    maxAmps: props.DrawerOC.child === "editChargerPort" ? data.maxAmps : "",
    cellId: props.DrawerOC.child === "editChargerPort" ? data.cellId : "",
  };

  const handleSubmit = async (e) => {
    if (validate()) {
      props.setLoading(true);
      const bodyRequest = {
        portName: values.portName,
        typeConnector: values.typeConnector,
        maxAmps: parseFloat(values.maxAmps),
        maxChargingRate: parseFloat(values.maxCapacity),
        minChargingRate: parseFloat(values.minimumChargingRate),
        cellId: values.cellId,
        description: values.description,
      };

      if (props.DrawerOC.child === "addChargerPort") {
        const response = await apiProvider.post(
          `${CHARGER_PROFILE}/${props?.parentCharger?.depotId}/${props?.parentCharger?.objectId}/chargeports`,
          bodyRequest
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          props.parentCharger.chargePortsCount = props.portCount + 1;
          props.setToast({
            isOpen: true,
            message: "Port added successfully",
            type: "success",
          });
          props.toggleDetailDrawer(false, "", "", {});
          props.getOtherChargerDetails(
            props?.parentCharger?.depotId,
            props?.parentCharger?.chargerId
          );
        } else {
          props.toggleDetailDrawer(false, "", "", {});
          props.setToast({
            isOpen: true,
            message: "Port adding failed",
            type: "error",
          });
        }
      } else {
        const response = await apiProvider.put(
          `${CHARGER_PROFILE}/${props?.parentCharger?.depotId}/${props?.parentCharger?.objectId}/chargeports/${data.connectorId}`,
          bodyRequest
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          props.setToast({
            isOpen: true,
            message: "Port updated successfully",
            type: "success",
          });
          props.toggleDetailDrawer(false, "", "", {});
          props.getOtherChargerDetails(
            props?.parentCharger?.depotId,
            props?.parentCharger?.chargerId
          );
        } else {
          props.setToast({
            isOpen: true,
            message: "Port updating failed",
            type: "error",
          });
        }
      }
      props.setLoading(false);
    }
  };

  useEffect(() => {
    getPortCells();
  }, []);

  useEffect(() => {
    setCheckMinRate(
      chargerPortMinRateCheck(
        values.minimumChargingRate,
        props?.parentCharger?.minChargingRate,
        values.maxCapacity,
        allMinChargingConnected,
        data,
        props?.parentCharger?.maxChargingRate,
        props.DrawerOC.child === "editChargerPort" ? "Edit" : "Add"
      )
    );

    setCheckMaxRate(
      chargerPortMaxRateCheck(
        values.maxCapacity,
        values.minimumChargingRate,
        props?.parentCharger?.maxChargingRate
      )
    );
  }, [values?.minimumChargingRate, values?.maxCapacity]);

  const getPortCells = async () => {
    const res = await getChargerPortCells(props.parentCharger.depotId);
    if (res?.statusCode === 200) {
      if (values.cellId) {
        setCellIdOptions([
          ...res.data.map((item) => ({ name: item, label: item })),
          ...[
            {
              label: values.cellId,
              value: values.cellId,
            },
          ],
        ]);
      } else {
        setCellIdOptions(res.data.map((item) => ({ name: item, label: item })));
      }
    }
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <div className="edit_charger_height">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controls.Input
                className="width100"
                name="portName"
                label="Charge port name *"
                value={values.portName}
                onChange={handleFormInputChange()}
                error={errors.portName}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Select
                className="width100"
                label="Connector type *"
                name="typeConnector"
                disabled={props?.parentCharger?.typeConnector?.length === 1}
                options={connectorTypeOptions.map((item) => item.label)}
                value={values.typeConnector}
                error={errors.typeConnector}
                onChange={handleFormInputChange()}
              />
            </Grid>
            <Grid item xs={12} className="maxAmpsBanner">
              <p className="ampsTitle">
                Charger model max amps:{" "}
                <span className="ampsValue">
                  {props?.parentCharger?.modelMaxAmps} A
                </span>
              </p>
              {props?.parentCharger?.derateConfig?.isChargerDerated && (
                <p className="ampsTitle">
                  Charger derated to:{" "}
                  <span className="ampsValue">
                    {props?.parentCharger?.derateConfig?.deratedMaxAmps} A
                  </span>
                </p>
              )}
              {props?.parentCharger?.maxAmps <
                props?.parentCharger?.modelMaxAmps && (
                <p className="ampsTitle">
                  Charger limited to:{" "}
                  <span className="ampsValue">
                    {props?.parentCharger?.maxAmps} A
                  </span>
                </p>
              )}
              <p className="info">
                <InfoIcon className="infoIcon" />
                To limit the port via software, update the max amps value to be
                less than the charger model max amps. A port limit is enacted
                only via software. That means if connectivity is lost, the port
                may return to the charger&apos;s max amperage.
              </p>
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                type="number"
                className="width100"
                name="maxAmps"
                label="Max amps (A) *"
                value={values.maxAmps}
                onChange={(e) => {
                  handleFormInputChange()(e);
                  let chargerMaxAmps = props?.parentCharger?.maxAmps;
                  if (
                    props?.parentCharger?.derateConfig?.isChargerDerated &&
                    (props?.parentCharger?.connectivityStatus ===
                      "Not Connected" ||
                      props?.parentCharger?.derateConfig?.deratedMaxAmps <
                        props?.parentCharger?.maxAmps)
                  )
                    chargerMaxAmps =
                      props?.parentCharger?.derateConfig?.deratedMaxAmps;

                  setCheckPortAmp(
                    chargerPortMinMaxAmpCheck(e.target.value, chargerMaxAmps)
                  );
                }}
                error={errors.maxAmps}
              />
            </Grid>

            <Grid item xs={12}>
              <Controls.Input
                className="width100"
                type="number"
                min={0}
                name="minimumChargingRate"
                label="Min charging rate (kW) *"
                value={values.minimumChargingRate}
                onChange={(e) => {
                  handleFormInputChange()(e);
                }}
                error={errors.minimumChargingRate}
              />
            </Grid>

            <Grid item xs={12}>
              <Controls.Input
                className="width100"
                name="maxCapacity"
                type="number"
                label="Max charging rate (kW) *"
                value={values.maxCapacity}
                onChange={(e) => {
                  handleFormInputChange()(e);
                }}
                error={errors.maxCapacity}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Select
                className="width100"
                label="Cell ID"
                name="cellId"
                options={cellIdOptions.map((item) => item.label)}
                value={values.cellId}
                error={errors.cellId}
                onChange={handleFormInputChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.TextArea
                name="description"
                label="Description"
                value={values.Description}
                error={errors.Description}
                className="width100"
                onChange={handleFormInputChange()}
              />
            </Grid>

            {checkPortAmp != true && checkPortAmp != false && (
              <Grid item xs={12}>
                <div className="custom_error_msg">
                  <InfoIcon fontSize="small" />
                  {checkPortAmp.map((single) => {
                    return <p>{single}</p>;
                  })}
                </div>
              </Grid>
            )}
            {checkMinRate != true && checkMinRate != false && (
              <Grid item xs={12}>
                <div className="custom_error_msg">
                  <InfoIcon fontSize="small" />
                  {checkMinRate.map((single) => {
                    return <p>{single}</p>;
                  })}
                </div>
              </Grid>
            )}
            {checkMaxRate != true && checkMaxRate != false && (
              <Grid item xs={12}>
                <div className="custom_error_msg">
                  <InfoIcon fontSize="small" />
                  {checkMaxRate.map((single) => {
                    return <p>{single}</p>;
                  })}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className="custom_alert_msg">
                <ReportProblemIcon fontSize="small" />
                <p>
                  Each new charge port added will increase the customer’s
                  monthly bill by the per-port rate defined in their selected
                  package.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="filter_action_btn">
          <Controls.Button
            text="Cancel"
            variant="outlined"
            onClick={() => {
              props.toggleDetailDrawer(false, "", "", {});
            }}
            className="btn_font_size"
          />
          <Controls.Button
            text={
              props.DrawerOC.child === "editChargerPort"
                ? "Save Changes"
                : "Add Port"
            }
            disabled={
              props.loading ||
              typeof checkMaxRate != "boolean" ||
              typeof checkMinRate != "boolean" ||
              typeof checkPortAmp != "boolean"
                ? true
                : false
            }
            color="primary"
            onClick={() => handleSubmit()}
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};
export default AddEditChargerPort;
