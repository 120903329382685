import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ComponentLoader from "../../../components/ComponentLoader";
import { Tooltip as MuiToolTip } from "@mui/material";
import {
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
} from "recharts";
import InfoIcon from "@mui/icons-material/Info";

import no_Data from "../../../assets/images/png/Vector.png";
import "../vehicleDiscovery.scss";
import {
  vehiclesDistributionInfo,
  chargersDistributionInfo,
} from "../constants";

export default function DepotVehicleDiscoveryPieChartInsights({
  chartName,
  loadingImprovementMetrics,
  vehicleDiscoveryImprovementMetrics,
}) {
  const [chartData, setChartData] = useState([]);
  const [successPercentageData, setSuccessPercentageData] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const colors = ["#23A469", "#2770D8", "#FAE100"];

  async function getData() {
    if (chartName === "vehicleInsights") {
      const total = vehicleDiscoveryImprovementMetrics.numberOfRegisteredVIN;
      const withMapping =
        vehicleDiscoveryImprovementMetrics.numberOfRegisteredVINWithVIDMapping;
      const withoutMapping = total - withMapping;
      const data = [
        { name: "With Mapping", value: withMapping || 0 },
        { name: "Without Mapping", value: withoutMapping || 0 },
      ];
      const success = (withMapping / total) * 100;
      setChartData(data);
      setSuccessPercentageData(Math.round(success, 0));
      setTotalCount(total);
    } else if (chartName === "chargerInsights") {
      const total = vehicleDiscoveryImprovementMetrics.numberOfChargers;
      const withLocation =
        vehicleDiscoveryImprovementMetrics.numberOfChargersWithValidLocation;
      const withoutLocation = total - withLocation;
      const data = [
        { name: "With Location", value: withLocation || 0 },
        { name: "No/Invalid Location", value: withoutLocation || 0 },
      ];
      const success = (withLocation / total) * 100;
      setChartData(data);
      setSuccessPercentageData(Math.round(success, 0));
      setTotalCount(total);
    }
  }

  useEffect(() => {
    getData();
  }, [vehicleDiscoveryImprovementMetrics]);

  const headerComponent = (chartName) => {
    return (
      <div>
        <Grid container mb={1}>
          <Grid item xs={12} className="monthlyChargerUtilization">
            <p className="title_for_pie_charts">
              {chartName === "vehicleInsights"
                ? "Registered Vehicles VIN-VID Mapping"
                : "Chargers Having A Valid Location"}
              <MuiToolTip
                title={
                  chartName === "vehicleInsights"
                    ? vehiclesDistributionInfo
                    : chargersDistributionInfo
                }
              >
                <span className="info">
                  <InfoIcon fontSize="small" className="infoIconColor" />
                </span>
              </MuiToolTip>
            </p>
            <p className="value">
              <span className="KPI_num">
                {chartName === "vehicleInsights"
                  ? `Total Vehicles: ${totalCount}`
                  : `Total Chargers: ${totalCount}`}
              </span>
            </p>
          </Grid>
        </Grid>
      </div>
    );
  };

  const circularLoader = (
    <div className="connectivity_section">
      <div className="connectivity_section_loader">
        <ComponentLoader isLoading={loadingImprovementMetrics} />
      </div>
    </div>
  );
  const chart = (chartName) => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>{headerComponent(chartName)}</div>
            <div className="chargerUptimeBarGraph">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    cx="50%"
                    cy="50%"
                    data={chartData}
                    dataKey="value"
                    nameKey="name"
                    innerRadius={"50%"}
                    paddingAngle={2}
                    fill="#8884d8"
                    isAnimationActive={false}
                  >
                    {chartData.map((_, index) => (
                      <Cell
                        key={`${chartName}-cell-${index}`}
                        fill={colors[index % colors.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    align="right"
                    verticalAlign="middle"
                    layout="vertical"
                    iconSize="15"
                    iconType="circle"
                    wrapperStyle={{ margin: "0 0 0 10px" }}
                    formatter={(value, entry) => (
                      <>
                        <span className="graphLegendKey">{`${value} - `}</span>
                        <span className="graphLegendValue">
                          {entry.payload.value}
                        </span>
                      </>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="axis_details">
              <p>
                {chartName === "vehicleInsights"
                  ? `${successPercentageData}% - Vehicles have VIN-VID mapping`
                  : `${successPercentageData}% - Chargers have a valid location assigned`}
              </p>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const noDataMessage = (
    <div className="no_data">
      <div className="noDataMargin">
        <img src={no_Data} alt="No data available" />
      </div>
      <p>No data available</p>
    </div>
  );

  return (
    <div>
      {chartData.length && !loadingImprovementMetrics
        ? chart(chartName)
        : loadingImprovementMetrics
        ? circularLoader
        : noDataMessage}
    </div>
  );
}
