import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
const { DATA_CATALOG_HISTORY, DATA_CATALOG_MONTH_HISTORY } = indexedEndPoints;

export const getDataCatalogHistoryApiCall = async (payload = {}) =>
  await apiProvider.post(DATA_CATALOG_HISTORY, payload);

export const downloadDataCatalogMonthlyHistoryApi = async () => {
  let dataSize = 0;
  return fetch(`${DATA_CATALOG_MONTH_HISTORY}`, {
    method: "GET",
    headers: await apiProvider.getHeaders(),
  })
    .then(function (response) {
      if (response.status !== 200) {
        return "";
      } else {
        return response.blob();
      }
    })

    .then((data) => {
      dataSize = data.size;
      if (dataSize > 0) {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `Data Catalog history.csv`;
        a.click();
        return dataSize;
      } else return 0;
    });
};
