import React, { useEffect, useMemo } from "react";
import {
  Autocomplete,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import Controls from "../../../../components/controls/Controls";
import { UseForm, Form } from "../../../../components/UseForm";
import { messages } from "../../../../constants/Messages";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import {
  getChargerProfile,
  searchChargerSerialNo,
} from "../chargerFirmwareService";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../../services/api/utilities/provider";
import {
  chargerMinMaxAmpCheck,
  chargerMinRateCheck,
  chargerMaxRateCheck,
} from "../../../../utils/chargerValidations";
import Loader from "../../../../components/Loader";
import { chargersMetadata } from "./ChargerMetadata";

const predictManufacturer = (serialNo, allManufacturer) =>
  serialNo?.startsWith("EX-") && allManufacturer?.includes("Lite-On")
    ? "Lite-On"
    : serialNo?.startsWith("PE_") &&
      allManufacturer?.includes("Power Electronics")
    ? "Power Electronics"
    : "";

const predictModel = (serialNo, manufacturer, allManufacturer) => {
  if (serialNo?.length < 12) return "";
  const srNo = serialNo?.slice(0, 12);
  const matchedModel = srNo in chargersMetadata ? chargersMetadata[srNo] : "";
  if (matchedModel) {
    const foundModelFromMeta = allManufacturer[0]?.children
      ?.find((obj) => obj.name === manufacturer)
      ?.chargers?.find((item) => item.vendorModel === matchedModel.vendorModel);
    if (foundModelFromMeta) {
      const matchedSoldAs = foundModelFromMeta?.soldAs?.find(
        (item) =>
          item.whiteLabelManufacturer === matchedModel.whiteLabelManufacturer &&
          item.whiteLabelModel === matchedModel.whiteLabelModel
      );
      if (matchedSoldAs)
        return { ...matchedModel, soldAsId: matchedSoldAs?.modelId };
    }
  }
  return "";
};

const EditChargerSideBar = (props) => {
  const { CHARGER_PROFILE, CHARGER_EXTRA_DETIALS, ADD_CHARGER, GET_DRIVERS } =
    indexedEndPoints;
  let initialFieldValues = {};
  const data = props?.DrawerOC?.data;
  const forWhat = props?.DrawerOC?.reason;
  const allMakes = [
    {
      ...props.allMakes,
      children: props.allMakes[0]?.children
        ?.filter((make) => !make.isDeleted) // remove deleted manufacturers for CRUD operations
        ?.map((make) => ({
          ...make,
          chargers: make.chargers?.filter((model) => !model.isDeleted), // remove deleted models for CRUD operations
        })),
    },
  ];
  const allAccounts = props.allAccounts;
  const [details, setDetails] = React.useState([]);
  const [profileData, setProfileData] = React.useState([]);
  const [chargingModeOptions, setchargingModeOptions] = React.useState([]);
  const [checkAmp, setCheckAmp] = React.useState(false);
  const [driverList, setDriverList] = React.useState([]);
  const [checkMinRate, setCheckMinRate] = React.useState(false);
  const [checkMaxRate, setCheckMaxRate] = React.useState(false);
  const [modelMaxAmps, setModelMaxAmps] = React.useState("");
  const [tempDriver, setTempDriver] = React.useState("");
  const [allSoldAsOptions, setAllSoldAsOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isSrNoValidated, setSrNoValidated] = React.useState(
    forWhat === "EDIT"
  );

  const allManufacturer = allMakes[0]?.children?.map((el) => ({
    label: el.name,
    value: el.name,
  }));
  const allAccountNames = allAccounts[0]?.children?.map((el) => ({
    label: el.companyName,
    value: el.companyName,
  }));

  const powerTypeOptions = [
    {
      label: "AC",
      value: "AC",
    },
    {
      label: "DC",
      value: "DC",
    },
  ];
  const chargingRateUnitOptions = [
    {
      label: "W",
      value: "Watt (W)",
    },
    {
      label: "A",
      value: "Ampere (A)",
    },
  ];

  const networkTypeOptions = [
    {
      label: "Ethernet",
      value: "Ethernet",
    },
    {
      label: "Wifi",
      value: "Wifi",
    },
    {
      label: "4G/LTE",
      value: "4G/LTE",
    },
    {
      label: "3G",
      value: "3G",
    },
  ];

  const siteTypeOptions = [
    {
      label: "Home",
      value: "Home",
    },
    {
      label: "Depot",
      value: "Depot",
    },
  ];

  initialFieldValues = {
    chargerName: forWhat == "EDIT" ? data?.chargerName : "",
    powerType: forWhat == "EDIT" ? data?.powerType : "",
    chargerId: forWhat == "EDIT" ? data?.chargerId : "",
    serialNumber: forWhat == "EDIT" ? data?.serialNumber : "",
    accountName: forWhat == "EDIT" ? data?.accountName : "",
    depotName: forWhat == "EDIT" ? data?.depotName : "",
    driver: "",
    chargerManufacturer: forWhat == "EDIT" ? data?.vendorManufacturer : "",
    soldAsId: forWhat == "EDIT" ? data?.soldAsId : "",
    chargerModel: forWhat == "EDIT" ? data?.vendorModel : "",
    siteType: forWhat == "EDIT" ? data?.siteType : "",
    chargingMode: forWhat == "EDIT" ? data?.chargingMode : "", //chargerProfile send chargerProfile ID in Payload
    maxVoltage:
      forWhat == "EDIT" ? data?.maxVoltage || data?.modelMaxVoltage : "",
    maxAmps: forWhat == "EDIT" ? data?.maxAmps : "",
    minChargingRate: forWhat == "EDIT" ? data?.minChargingRate : "",
    maxChargingRate: forWhat == "EDIT" ? data?.maxChargingRate : "",
    chargingRateUnit: forWhat == "EDIT" ? data?.chargingRateUnit : "",
    networkType: forWhat == "EDIT" ? data?.networkType : "",
    latitude: forWhat == "EDIT" ? (data?.latitude ? data?.latitude : "") : "",
    longitude:
      forWhat == "EDIT" ? (data?.longitude ? data?.longitude : "") : "",
    nayaxTerminalId: forWhat == "EDIT" ? data?.nayaxTerminalId : "",
    isRfidCapable: forWhat == "EDIT" ? data?.isRfidCapable : false,
    isMinaEnrolled: forWhat == "EDIT" ? data?.isMinaEnrolled : false,
    isAdvenirEnrolled: forWhat == "EDIT" ? data?.isAdvenirEnrolled : false,
    energyServicePrograms: forWhat == "EDIT" ? data?.energyServicePrograms : [],
    cpsProgramMeterId: forWhat == "EDIT" ? data?.cpsProgramMeterId : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    const regex = /^[a-zA-Z0-9\s_-]+$/;
    const regec_SN = /^[a-zA-Z0-9-_.]+$/;
    const regex_CN = /^[a-zA-Z0-9\s_()-]+$/;
    if ("chargerName" in fieldValues)
      temp.chargerName = fieldValues.chargerName
        ? !(
            !fieldValues.chargerName.match(/^\s/) &&
            regex_CN.test(fieldValues.chargerName)
          )
          ? "Please provide a valid Charger Name that is alphanumeric and might consist of - and _"
          : ""
        : messages.FIELD_REQUIRED;

    if ("serialNumber" in fieldValues)
      temp.serialNumber = fieldValues.serialNumber
        ? !(
            !fieldValues.serialNumber.match(/^\s/) &&
            regec_SN.test(fieldValues.serialNumber)
          )
          ? "Please provide a valid Serial Number that is alphanumeric and might consist of - and _"
          : ""
        : messages.FIELD_REQUIRED;

    if ("powerType" in fieldValues)
      temp.powerType = fieldValues.powerType ? "" : messages.FIELD_REQUIRED;

    if ("nayaxTerminalId" in fieldValues)
      temp.nayaxTerminalId = fieldValues.nayaxTerminalId
        ? !(
            !fieldValues.nayaxTerminalId.match(/^\s/) &&
            regex.test(fieldValues.nayaxTerminalId)
          )
          ? "Please provide a valid Nayax Terminal ID that is alphanumeric and might consist of - and _"
          : ""
        : "";

    if ("chargerId" in fieldValues)
      temp.chargerId = fieldValues.chargerId
        ? !(
            !fieldValues.chargerId.match(/^\s/) &&
            regec_SN.test(fieldValues.chargerId)
          )
          ? "Please provide a valid Charger ID that is alphanumeric and might consist of - and _"
          : ""
        : messages.FIELD_REQUIRED;

    if ("accountName" in fieldValues)
      temp.accountName = fieldValues.accountName ? "" : messages.FIELD_REQUIRED;
    if ("depotName" in fieldValues)
      temp.depotName = fieldValues.depotName ? "" : messages.FIELD_REQUIRED;

    if ("chargerManufacturer" in fieldValues)
      temp.chargerManufacturer = fieldValues.chargerManufacturer
        ? ""
        : messages.FIELD_REQUIRED;
    if ("chargerModel" in fieldValues)
      temp.chargerModel = fieldValues.chargerModel
        ? ""
        : messages.FIELD_REQUIRED;

    if ("soldAsId" in fieldValues)
      temp.soldAsId = fieldValues.soldAsId ? "" : messages.FIELD_REQUIRED;

    if ("chargingMode" in fieldValues)
      temp.chargingMode = fieldValues.chargingMode
        ? ""
        : messages.FIELD_REQUIRED;

    if ("maxAmps" in fieldValues)
      temp.maxAmps = fieldValues.maxAmps
        ? parseInt(fieldValues.maxAmps) <= 0
          ? "Max amps should not be Negative or 0"
          : ""
        : messages.FIELD_REQUIRED;
    if ("minChargingRate" in fieldValues)
      temp.minChargingRate =
        fieldValues.minChargingRate || fieldValues.minChargingRate === 0
          ? parseInt(fieldValues.minChargingRate) < 0
            ? "Min Charging Rate should not be Negative"
            : ""
          : messages.FIELD_REQUIRED;
    if ("maxChargingRate" in fieldValues)
      temp.maxChargingRate = fieldValues.maxChargingRate
        ? parseInt(fieldValues.maxChargingRate) <= 0
          ? "Max Charging Rate should not be Negative or 0"
          : ""
        : messages.FIELD_REQUIRED;
    if ("chargingRateUnit" in fieldValues)
      temp.chargingRateUnit = fieldValues.chargingRateUnit
        ? ""
        : messages.FIELD_REQUIRED;
    if ("networkType" in fieldValues)
      temp.networkType = fieldValues.networkType ? "" : messages.FIELD_REQUIRED;
    if (fieldValues.latitude)
      temp.latitude =
        parseFloat(fieldValues.latitude) < -90 ||
        parseFloat(fieldValues.latitude) > 90
          ? "Latitude should be between -90 to +90"
          : "";
    if (fieldValues.longitude)
      temp.longitude =
        parseFloat(fieldValues.longitude) < -120 ||
        parseFloat(fieldValues.longitude) > 120
          ? "Longitude should be between -120 to +120"
          : "";

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(initialFieldValues, true, validate);

  const allModels = allMakes[0]?.children
    ?.find((obj) => obj.name === values.chargerManufacturer)
    ?.chargers?.map((el) => ({
      label: el.vendorModel,
      value: el.vendorModel,
    }));

  const alldepots = allAccounts[0]?.children
    ?.find((obj) => obj.companyName === values.accountName)
    ?.depotsList?.map((el) => ({
      label: el.depotName,
      value: el.depotName,
    }));

  const allDRPrograms = allAccounts[0]?.children
    ?.find((obj) => obj.companyName === values.accountName)
    ?.depotsList?.find((obj) => obj.depotName === values.depotName)
    ?.energyServicePrograms?.filter(
      (obj) => obj.enrollmentStatus === "Enrolled"
    )
    ?.map((el) => el.programName);

  const handleSubmit = async (e) => {
    if (validate()) {
      props.setLoading(true);
      let temp = profileData?.find(
        (obj) => obj.name == values.chargingMode
      )?.profileId;
      const depotId = allAccounts[0]?.children?.reduce((result, item) => {
        const depot = item?.depotsList?.find(
          (dep) => dep?.depotName === values?.depotName
        );
        return depot ? depot?.depotId : result;
      }, null);
      const getChargerIdsByModel = (data, modelName) => {
        for (const item of data) {
          const charger = item?.chargers?.find(
            (charger) => charger?.vendorModel === modelName
          );
          if (charger) {
            return {
              // soldAsId: charger?.soldAsId,
              chargerMetaId: charger?.chargerMetaId,
            };
          }
        }
        return null; // Return null if no match is found
      };

      let bodyRequest = {
        chargerId: values.chargerId,
        chargerName: values.chargerName,
        serialNumber: values.serialNumber,
        chargerManufacturer: allSoldAsOptions
          ?.find((row) => row.value === values.soldAsId)
          ?.label?.split("|")[0]
          ?.trim(),
        chargerModel: allSoldAsOptions
          ?.find((row) => row.value === values.soldAsId)
          ?.label?.split("|")[1]
          ?.trim(),
        chargerProfile: temp,
        driver:
          values.driver == "None" || values.driver == ""
            ? null
            : driverList?.find((obj) => obj?.label == values?.driver)?.value,
        siteType: values.siteType,
        maxVoltage: parseFloat(values.maxVoltage),
        maxAmps: parseFloat(values.maxAmps),
        powerType: values.powerType,
        minChargingRate: parseFloat(values.minChargingRate),
        maxChargingRate: parseFloat(values.maxChargingRate),
        chargingRateUnit: values.chargingRateUnit,
        networkType: values.networkType,
        latitude: values.latitude ? parseFloat(values.latitude) : null,
        longitude: values.longitude ? parseFloat(values.longitude) : null,
        nayaxTerminalId: values.nayaxTerminalId ? values.nayaxTerminalId : "",
        isRfidCapable: values.isRfidCapable,
        isMinaEnrolled: values.isMinaEnrolled,
        isAdvenirEnrolled: values.isAdvenirEnrolled,
        siteId: depotId,
        chargerMetaId: getChargerIdsByModel(
          allMakes[0]?.children,
          values.chargerModel
        )?.chargerMetaId,
        soldAsId: values.soldAsId,
        energyServicePrograms: values.energyServicePrograms,
        cpsProgramMeterId: values.cpsProgramMeterId,
      };

      if (forWhat == "EDIT") {
        for (const key in bodyRequest) {
          if (
            key in bodyRequest &&
            key !== "driver" &&
            key !== "latitude" &&
            key !== "longitude"
          ) {
            if (
              bodyRequest[key] === undefined ||
              bodyRequest[key] === null ||
              bodyRequest[key] === ""
            ) {
              delete bodyRequest[key];
            }
          }
        }

        const response = await apiProvider.put(
          `${CHARGER_PROFILE}/${data?.depotId}/${data?.objectId}`,
          bodyRequest
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          props.setToast({
            isOpen: true,
            message: "Charger updated successfully",
            type: "success",
          });
          props.toggleDetailDrawer(false, "EDIT CHARGER", {}, "");

          if (Object.keys(props.filterObject)?.length) {
            props.getAllChargersAndFirmware(props.filterObject);
          } else {
            props.getAllChargersAndFirmware();
          }

          props.setLoading(false);
        } else {
          props.setToast({
            isOpen: true,
            message: "Charger updating failed",
            type: "error",
          });
          props.setLoading(false);
        }
      }
      if (forWhat == "ADD") {
        if (values.depotName) {
          bodyRequest["siteId"] = allAccounts
            .flatMap((item) => item?.children)
            .flatMap((child) => child?.depotsList)
            .find((depot) => depot?.depotName === values.depotName)?.depotId;
        }
        const response = await apiProvider.post(ADD_CHARGER, bodyRequest);
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          props.setToast({
            isOpen: true,
            message: "Charger Added successfully",
            type: "success",
          });
          props.toggleDetailDrawer(false, "ADD CHARGER", {}, "");

          if (Object.keys(props.filterObject)?.length) {
            props.getAllChargersAndFirmware(
              props.filterObject,
              `?search=${response?.data?.chargerId}`
            );
          } else {
            props.getAllChargersAndFirmware(
              {},
              `?search=${response?.data?.chargerId}`
            );
          }
          props.setSearchedString(response?.data?.chargerId);
          props.setLoading(false);
        } else {
          let errorMsg = response?.data?.data?.message
            ? response?.data?.data?.message
            : "Failed";
          props.setToast({
            isOpen: true,
            message: errorMsg,
            type: "error",
          });
          props.setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (profileData) {
      let temp = profileData?.find(
        (obj) => obj.profileId == data?.chargerProfileId
      );
      setValues({
        ...values,
        chargingMode: temp ? temp.name : "",
      });
    }
  }, [profileData]);

  useEffect(() => {
    let id = "";

    if (forWhat == "ADD" && values.depotName) {
      id = allAccounts
        .flatMap((item) => item?.children)
        .flatMap((child) => child?.depotsList)
        .find((depot) => depot?.depotName === values.depotName)?.depotId;

      setValues({
        ...values,
        siteType:
          allAccounts
            .flatMap((item) => item?.children)
            .flatMap((child) => child?.depotsList)
            .find((depot) => depot?.depotName === values.depotName)
            ?.isHomeChargingDepot == true
            ? "Home"
            : "Depot",
      });
    }
    getChargerProfileByDepotID(forWhat == "EDIT" ? data?.depotId : id);

    if (values?.depotName) {
      let depotIID = allAccounts
        .flatMap((item) => item?.children)
        .flatMap((child) => child?.depotsList)
        .find((depot) => depot?.depotName === values.depotName)?.depotId;
      let dataa;
      const fetchDrivers = async () => {
        const response = await apiProvider.getAll(`${GET_DRIVERS}${depotIID}`);
        dataa = response?.data;
        const outputArray = dataa
          ?.filter(
            (driver) => !driver?.chargerId || driver.chargerId === data.objectId
          )
          .map((item) => ({
            label: item?.firstName?.trim() + " " + item?.lastName?.trim(),
            value: item?._id,
          }));
        outputArray?.splice(0, 0, { label: "None", value: "None" });
        setDriverList(outputArray);
        if (forWhat == "EDIT") {
          if (data?.driver) {
            const targetObjectValue = outputArray.find(
              (obj) => obj.value == data?.driver
            )?.label;
            setTempDriver(targetObjectValue);
          }
        }
      };
      fetchDrivers();
    }
  }, [values.depotName]);

  useEffect(() => {
    if (forWhat == "EDIT") {
      setValues({
        ...values,
        driver: tempDriver,
      });
    }
  }, [tempDriver]);

  useEffect(() => {
    let temp = "";
    if (values.chargerModel) {
      temp = allMakes
        .flatMap((item) => item?.children)
        .flatMap((child) => child?.chargers)
        .find((charger) => charger?.vendorModel == values.chargerModel);
    }

    if (temp) {
      setModelMaxAmps(temp.maxAmps);
      if (temp.soldAs) {
        setAllSoldAsOptions(
          temp.soldAs
            .filter(
              (item) => item.whiteLabelManufacturer && item.whiteLabelModel
            )
            .map((item) => ({
              label: `${item.whiteLabelManufacturer} | ${item.whiteLabelModel}`,
              value: item.modelId,
            }))
        );
      }
      if (forWhat == "ADD") {
        setValues({
          ...values,
          isRfidCapable: temp.isRfidCapable,
          maxVoltage: temp.maxVoltage,
          maxAmps: temp.maxAmps,
          minChargingRate: temp.minChargingRate,
          maxChargingRate: temp.maxChargingRate,
          chargingRateUnit: temp.chargingRateUnit,
          powerType: temp.powerType,
          networkType: temp.networkType,
        });
        setErrors({
          ...errors,
          maxAmps: temp.maxAmps ? "" : messages.FIELD_REQUIRED,
          minChargingRate: temp.minChargingRate ? "" : messages.FIELD_REQUIRED,
          maxChargingRate: temp.maxChargingRate ? "" : messages.FIELD_REQUIRED,
          chargingRateUnit: temp.chargingRateUnit
            ? ""
            : messages.FIELD_REQUIRED,
          powerType: temp.powerType ? "" : messages.FIELD_REQUIRED,
          networkType: temp.networkType ? "" : messages.FIELD_REQUIRED,
        });
      } else setCheckAmp(chargerMinMaxAmpCheck(values.maxAmps, temp.maxAmps));
    }
  }, [values.chargerModel]);

  const getChargerProfileByDepotID = async (id) => {
    if (id) {
      const res = await getChargerProfile(id);
      if (res?.statusCode === 200) {
        setProfileData(res.data);
        setchargingModeOptions(
          res.data
            .map((obj) => obj["name"])
            .map((item) => ({ name: item, label: item }))
        );
      }
    }
  };

  React.useEffect(() => {
    getOtherChargerDetails(data?.depotId, data?.chargerId);
  }, []);

  const getOtherChargerDetails = async (depotId, chargerId) => {
    if (depotId && chargerId) {
      const response = await apiProvider.getAll(
        `${CHARGER_EXTRA_DETIALS}/${depotId}/${chargerId}/details`
      );

      setDetails(response.data);
    }
  };

  const getMaxValue = (array, key) => {
    return array.reduce((maxValue, obj) => {
      if (key in obj) {
        const value = obj[key];
        return value > maxValue ? value : maxValue;
      }
      return maxValue;
    }, null);
  };

  const getMinValue = (array, key) => {
    return array.reduce((minValue, obj) => {
      if (key in obj) {
        const value = parseInt(obj[key]);
        return value < minValue ? value : minValue;
      }
      return minValue;
    }, Infinity);
  };
  useEffect(() => {
    if (values.minChargingRate === "" && values.maxChargingRate === "") {
      return;
    }
    let MaxValueOfMinChargingRateOfPort;
    let MinValueOfMaxChargingRateOfPort;

    if (details?.portsDetails?.length) {
      MaxValueOfMinChargingRateOfPort = getMaxValue(
        details?.portsDetails,
        "minimumChargingRate"
      );
      MinValueOfMaxChargingRateOfPort = getMinValue(
        details?.portsDetails,
        "maxCapacity"
      );
    }

    setCheckMaxRate(
      chargerMaxRateCheck(
        values.minChargingRate,
        values.maxChargingRate,
        MinValueOfMaxChargingRateOfPort
      )
    );
    setCheckMinRate(
      chargerMinRateCheck(
        values.minChargingRate,
        values.maxChargingRate,
        MaxValueOfMinChargingRateOfPort
      )
    );
  }, [values.minChargingRate, values.maxChargingRate, details?.portsDetails]);

  const soldAsMemoised = useMemo(() => {
    if (allSoldAsOptions.length == 0) return null;
    return (
      <div>
        <Autocomplete
          size="small"
          name="soldAsId"
          value={
            allSoldAsOptions?.find((item) => item.value === values.soldAsId)
              ?.label
          }
          options={allSoldAsOptions?.map((item) => item.label)}
          onChange={(_, value) => {
            let x = allSoldAsOptions.find(
              (item) => item.label === value
            )?.value;
            setValues({
              ...values,
              soldAsId: x,
            });
            setErrors({ ...errors, soldAsId: "" });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Sold as manufacturer/model *"
              {...(errors.soldAsId && {
                error: true,
                helperText: errors.soldAsId,
              })}
            />
          )}
        />
      </div>
    );
  }, [allSoldAsOptions, errors.soldAsId, values.soldAsId]);

  const searchSerialNo = async () => {
    if (!values?.serialNumber?.length) return;
    setLoading(true);
    const res = await searchChargerSerialNo(values.serialNumber);
    setLoading(false);
    if (res?.statusCode === 200) {
      // check if there exists a charger with this serial number and whose siteId is not null
      const matched = res?.data?.some((item) => item.siteId);
      if (matched) {
        setSrNoValidated(false);
        const depotName = allAccounts[0]?.children
          ?.find((obj) => obj.companyId === res?.data?.[0]?.companyId)
          ?.depotsList?.find(
            (el) => el.depotId === res?.data?.[0]?.siteId
          )?.depotName;
        props.setToast({
          isOpen: true,
          message: `Charger with serial number ${
            values.serialNumber
          } already exists in ${
            depotName ? `depot ${depotName}` : "inactive depot"
          }. Cannot add new charger.`,
          type: "error",
        });
      } else {
        setSrNoValidated(true);
        props.setToast({
          isOpen: true,
          message: "Charger serial number validated successfully.",
          type: "success",
        });

        const make = predictManufacturer(
          values?.serialNumber,
          allManufacturer.map((item) => item.label)
        );
        if (make) {
          const model = predictModel(
            values?.serialNumber,
            make,
            props.allMakes
          );
          setValues({
            ...values,
            chargerManufacturer: make,
            chargerModel: model?.vendorModel,
            soldAsId: model?.soldAsId,
          });
        } else {
          setValues({
            ...values,
            chargerManufacturer: "",
            chargerModel: "",
            soldAsId: "",
          });
          setAllSoldAsOptions([]);
        }
      }
    } else {
      setSrNoValidated(false);
      props.setToast({
        isOpen: true,
        message:
          "Charger serial number search failed. Please try after some time.",
        type: "error",
      });
      props.toggleDetailDrawer(false, "EDIT CHARGER", {}, "");
    }
  };

  return (
    <>
      <Loader isLoading={loading} />
      <div className="filters_with_vertical_tab">
        <div className="edit_charger_height">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controls.Input
                className="width100"
                name="serialNumber"
                label="Serial number *"
                value={values.serialNumber}
                onChange={handleFormInputChange()}
                error={errors.serialNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        onClick={searchSerialNo}
                        className="iconCursor"
                      />
                    </InputAdornment>
                  ),
                  onBlur: searchSerialNo,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                className="width100"
                name="chargerName"
                label="Charger Name *"
                value={values.chargerName}
                onChange={handleFormInputChange()}
                error={errors.chargerName}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                className="width100"
                name="chargerId"
                label="Charger ID *"
                value={values.chargerId}
                onChange={handleFormInputChange()}
                error={errors.chargerId}
              />
            </Grid>
            <Grid item xs={12}>
              <div>
                <Autocomplete
                  size="small"
                  disabled={forWhat === "EDIT"}
                  name="accountName"
                  value={values.accountName}
                  options={allAccountNames?.map((item) => item.label)}
                  onChange={(_, value) => {
                    setValues({
                      ...values,
                      accountName: value,
                      depotName: "",
                      chargingMode: "",
                    });
                    setErrors({ ...errors, accountName: "" });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Account *"
                      {...(errors.accountName && {
                        error: true,
                        helperText: errors.accountName,
                      })}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Autocomplete
                  size="small"
                  disabled={forWhat === "EDIT"}
                  name="depotName"
                  value={values.depotName}
                  options={alldepots?.map((item) => item.label) || []}
                  onChange={(_, value) => {
                    setValues({
                      ...values,
                      depotName: value,
                      driver: "",
                      chargingMode: "",
                    });
                    setErrors({ ...errors, depotName: "" });
                  }}
                  renderOption={(props, option) => {
                    return (
                      <Tooltip title={""} key={option}>
                        <li {...props}>
                          <div className="selectContainer">{option} </div>
                        </li>
                      </Tooltip>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Depot *"
                      {...(errors.depotName && {
                        error: true,
                        helperText: errors.depotName,
                      })}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Controls.Select
                  className="width100"
                  label="Driver"
                  name="driver"
                  options={driverList?.map((item) => item.label)}
                  value={values.driver}
                  error={errors.driver}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      driver: e.target.value,
                    });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Autocomplete
                  size="small"
                  name="chargerManufacturer"
                  value={values.chargerManufacturer}
                  options={allManufacturer?.map((item) => item.label)}
                  onChange={(_, value) => {
                    setValues({
                      ...values,
                      chargerManufacturer: value,
                      chargerModel: "",
                      soldAsId: "",
                    });
                    setErrors({ ...errors, chargerManufacturer: "" });
                    setAllSoldAsOptions([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Manufacturer *"
                      {...(errors.chargerManufacturer && {
                        error: true,
                        helperText: errors.chargerManufacturer,
                      })}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Autocomplete
                  size="small"
                  name="chargerModel"
                  value={values.chargerModel}
                  options={allModels?.map((item) => item.label) || []}
                  onChange={(_, value) => {
                    setValues({
                      ...values,
                      chargerModel: value,
                      soldAsId: "",
                    });
                    setErrors({ ...errors, chargerModel: "" });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Model *"
                      {...(errors.chargerModel && {
                        error: true,
                        helperText: errors.chargerModel,
                      })}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              {soldAsMemoised}
            </Grid>
            <Grid item xs={12}>
              <div>
                <Controls.Select
                  className="width100"
                  label="Charge management profile *"
                  name="chargingMode"
                  // disabled={true}
                  options={chargingModeOptions?.map((item) => item.label)}
                  value={values.chargingMode}
                  error={errors.chargingMode}
                  onChange={handleFormInputChange()}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                className="width100"
                name="nayaxTerminalId"
                label="Nayax Terminal ID"
                value={values.nayaxTerminalId}
                onChange={handleFormInputChange()}
                error={errors.nayaxTerminalId}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="flex_div">
                <Controls.Select
                  className="width100"
                  label="Site Type *"
                  name="siteType"
                  disabled={true}
                  options={siteTypeOptions?.map((item) => item.label)}
                  value={values.siteType}
                  error={errors.siteType}
                  onChange={handleFormInputChange()}
                />
                <Tooltip title="Site type cannot be edited after the charger has been created. If the charger has moved sites, it must be removed and re-added under the new site.">
                  <InfoIcon fontSize="small" className="info_icon" />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6}>
              {values?.depotName &&
                allAccounts
                  .flatMap((item) => item?.children)
                  .flatMap((child) => child?.depotsList)
                  .find((depot) => depot?.depotName === values.depotName)
                  ?.isRFIDDepot == true &&
                values.chargerModel && (
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        className="rfigToggle"
                        control={
                          <Switch
                            checked={values.isRfidCapable}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                isRfidCapable: e.target.checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Charger is RFID Capable"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </div>
                )}
            </Grid>

            {modelMaxAmps && (
              <Grid item xs={12} className="maxAmpsBanner">
                <p className="ampsTitle">
                  Charger model max amps:{" "}
                  <span className="ampsValue">{modelMaxAmps} A</span>
                </p>
                {data?.derateConfig?.isChargerDerated && (
                  <p className="ampsTitle">
                    Charger derated to:{" "}
                    <span className="ampsValue">
                      {data?.derateConfig?.deratedMaxAmps} A
                    </span>
                  </p>
                )}
                <p className="info">
                  <InfoIcon className="infoIcon" />
                  To limit the charger via software, update the max amps value
                  to be less than the charger model max amps. Lowering this
                  charger&apos;s max amps will lower the max amps of all of its
                  ports. A charger limit is enacted only via software. That
                  means if connectivity is lost, the charger may return to its
                  max amperage.
                </p>
              </Grid>
            )}
            <Grid item xs={6}>
              <Controls.Input
                className="width100"
                name="maxAmps"
                type="number"
                label="Max amps (A) *"
                value={values.maxAmps}
                onChange={(e) => {
                  handleFormInputChange()(e),
                    setCheckAmp(
                      chargerMinMaxAmpCheck(e.target.value, modelMaxAmps)
                    );
                }}
                error={errors.maxAmps}
              />
            </Grid>
            <Grid item xs={6}>
              <div>
                <Controls.Select
                  className="width100"
                  label="Power Type *"
                  name="powerType"
                  options={powerTypeOptions?.map((item) => item.label)}
                  value={values.powerType}
                  error={errors.powerType}
                  onChange={handleFormInputChange()}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                className="width100"
                name="minChargingRate"
                type="number"
                min={0}
                label="Min charging rate (kW) *"
                value={values.minChargingRate}
                onChange={(e) => {
                  handleFormInputChange()(e);
                }}
                error={errors.minChargingRate}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                className="width100"
                name="maxChargingRate"
                type="number"
                label="Max charging rate (kW) *"
                value={values.maxChargingRate}
                onChange={(e) => {
                  handleFormInputChange()(e);
                }}
                error={errors.maxChargingRate}
              />
            </Grid>
            <Grid item xs={6}>
              <div>
                <Controls.Select
                  className="width100"
                  label="Charging rate unit *"
                  name="chargingRateUnit"
                  options={chargingRateUnitOptions?.map((item) => item.label)}
                  value={values.chargingRateUnit}
                  error={errors.chargingRateUnit}
                  onChange={handleFormInputChange()}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Controls.Select
                  className="width100"
                  label="Network *"
                  name="networkType"
                  options={networkTypeOptions?.map((item) => item.label)}
                  value={values.networkType}
                  error={errors.networkType}
                  onChange={handleFormInputChange()}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                className="width100"
                name="latitude"
                label="Latitude"
                type="number"
                min={-90}
                max={90}
                value={values.latitude}
                onChange={handleFormInputChange()}
                error={errors.latitude}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                className="width100"
                name="longitude"
                label="Longitude"
                type="number"
                min={-120}
                max={120}
                value={values.longitude}
                onChange={handleFormInputChange()}
                error={errors.longitude}
              />
            </Grid>
            {values?.depotName &&
              allAccounts
                .flatMap((item) => item?.children)
                .flatMap((child) => child?.depotsList)
                .find((depot) => depot?.depotName === values.depotName)
                ?.isMinaEnrolledDepot == true && (
                <Grid item xs={6}>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        className="rfigToggle"
                        control={
                          <Switch
                            checked={values.isMinaEnrolled}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                isMinaEnrolled: e.target.checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Charger is Mina Enrolled"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </div>
                </Grid>
              )}
            {values?.depotName &&
              allAccounts
                .flatMap((item) => item?.children)
                .flatMap((child) => child?.depotsList)
                .find((depot) => depot?.depotName === values.depotName)
                ?.isAdvenirEnrolledDepot === true && (
                <Grid item xs={6}>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        className="rfigToggle"
                        control={
                          <Switch
                            checked={values.isAdvenirEnrolled}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                isAdvenirEnrolled: e.target.checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Charger is Advenir Enrolled"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </div>
                </Grid>
              )}
            {values?.depotName &&
              allAccounts
                .flatMap((item) => item?.children)
                .flatMap((child) => child?.depotsList)
                .find((depot) => depot?.depotName === values.depotName)
                ?.isDemandResponseEnabled == true && (
                <Grid item xs={6}>
                  <Autocomplete
                    size="small"
                    multiple
                    value={values.energyServicePrograms}
                    options={[...new Set(allDRPrograms)]}
                    onChange={(_, value) =>
                      setValues({ ...values, energyServicePrograms: value })
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Demand response programs"
                      />
                    )}
                  />
                </Grid>
              )}
            {values?.depotName &&
              allAccounts
                .flatMap((item) => item?.children)
                .flatMap((child) => child?.depotsList)
                .find((depot) => depot?.depotName === values.depotName)
                ?.isDemandResponseEnabled == true &&
              values?.energyServicePrograms?.includes("CPS") && (
                <Grid item xs={6}>
                  <Controls.Input
                    className="width100"
                    name="cpsProgramMeterId"
                    label="Meter ID"
                    value={values.cpsProgramMeterId}
                    onChange={handleFormInputChange()}
                    error={errors.cpsProgramMeterId}
                  />
                </Grid>
              )}
            {checkAmp != true && checkAmp != false && (
              <Grid item xs={12}>
                <div className="custom_error_msg">
                  <InfoIcon fontSize="small" />
                  {checkAmp.map((single) => {
                    return <p>{single}</p>;
                  })}
                </div>
              </Grid>
            )}
            {checkMinRate != true && checkMinRate != false && (
              <Grid item xs={12}>
                <div className="custom_error_msg">
                  <InfoIcon fontSize="small" />

                  {checkMinRate.map((single) => {
                    return <p>{single}</p>;
                  })}
                </div>
              </Grid>
            )}
            {checkMaxRate != true && checkMaxRate != false && (
              <Grid item xs={12}>
                <div className="custom_error_msg">
                  <InfoIcon fontSize="small" />
                  {checkMaxRate.map((single) => {
                    return <p>{single}</p>;
                  })}
                </div>
              </Grid>
            )}
          </Grid>
        </div>

        <div className="filter_action_btn">
          <Controls.Button
            text="Cancel"
            variant="outlined"
            onClick={() => {
              props.toggleDetailDrawer(false, "EDIT CHARGER", {}, "");
            }}
            className="btn_font_size"
          />
          <Controls.Button
            text={forWhat == "EDIT" ? "Save Changes" : "Add Charger"}
            color="primary"
            disabled={
              !isSrNoValidated ||
              props.loading ||
              Object.values(errors)?.some((item) => item.length) ||
              typeof checkAmp != "boolean" ||
              typeof checkMaxRate != "boolean" ||
              typeof checkMinRate != "boolean"
                ? true
                : false
            }
            onClick={() => handleSubmit()}
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};
export default EditChargerSideBar;
