import React, { useState, useEffect, useMemo, useCallback } from "react";
import NewPageMainContent from "../../../components/NewPageMainContent";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import useTable from "../../../components/UseTable";
import { titleLabels } from "../../../constants/TitleLabels";
import {
  Badge,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Switch,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Controls from "../../../components/controls/Controls";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import sub from "date-fns/sub";
import {
  commonApiActionCreator,
  usersActionCreator,
} from "../../../redux-state/actions";
import { bindActionCreators } from "redux";
import Loader from "../../../components/Loader";
import MonitorIcon from "@mui/icons-material/Monitor";
import {
  getLoadMonitoringAlert,
  updateLoadMonitoringAlert,
} from "../circuitService";
import ToastMessage from "../../../components/ToastMessage";
import TuneIcon from "@mui/icons-material/Tune";
import NewCommonFilterDrawer from "../../../components/NewSideFIlterDrawer";
import CLLMAlertFilter from "./CLLMFilter";
import {
  allAlertForFilter,
  allIsMonitoredFilter,
  allMonitorForFilter,
} from "../../../constants/FilterConstants";
import add from "date-fns/add";

const AllBreadcrumbsLinks = [
  {
    link: "/circuit-monitor",
    title: "Circuit Monitor",
  },
];

const CLLMAlertTable = () => {
  const cllmPrivilege = useSelector(
    (state) => state.user.componentPrivilege.cllm
  );

  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );

  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );

  const { saveAllUsersGlobal } = bindActionCreators(
    usersActionCreator,
    dispatch
  );
  const { allUsers, isUsersLoaded } = useSelector(
    (state) => state.allUsersGlobal
  );

  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [loading, setloading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterPayload, setFilterPayload] = useState({});
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const [allAccounts, setAllAccounts] = useState([
    {
      name: "Account and Depot",
      isExpanded: true,
      checked: false,
      children: [],
    },
  ]);

  const [generatedOnType, setGeneratedOnType] = React.useState("");
  const [customFromDate, setCustomFromDate] = React.useState("");
  const [customToDate, setCustomToDate] = React.useState("");

  const [allAlertFor, setAllAlertFor] = React.useState(
    JSON.parse(JSON.stringify(allAlertForFilter))
  );
  const [allMonitorFor, setAllMonitorFor] = React.useState(
    JSON.parse(JSON.stringify(allMonitorForFilter))
  );

  const [allIsMonitored, setAllIsMonitored] = React.useState(
    JSON.parse(JSON.stringify(allIsMonitoredFilter))
  );

  const [userFilter, setUserFilter] = useState([]);
  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };

  React.useEffect(() => {
    if (isUsersLoaded === false) {
      saveAllUsersGlobal();
    }
    getUserData();
  }, [isUsersLoaded]);

  const getUserData = () => {
    userEmailAndName(allUsers);
  };

  const userEmailAndName = (data) => {
    let allEmails = [];
    data?.map((single) => {
      allEmails.push({
        name: `${single.email}-${single.firstname} ${single.lastname}`,
        value: single.email,
        checked: false,
      });
    });
    setUserFilter([
      {
        name: "Requester",
        isExpanded: true,
        checked: false,
        children: allEmails,
      },
    ]);
  };

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
  }, [isCompaniesLoaded]);

  const headCells = useMemo(() => {
    const hc = [
      {
        id: "depotName",
        label: "Depot Name",
        disableSorting: true,
      },
      {
        id: "accountName",
        label: "Account Name",
        disableSorting: true,
      },

      {
        id: "monitorFor",
        label: "Monitor For",
      },
      {
        id: "alertFor",
        label: "Alert For",
      },
      {
        id: "load",
        label: "Load",
      },
      {
        id: "maxLoad",
        label: "Max Load",
      },
      {
        id: "ts",
        label: "Time of Alert",
        type: "date",
        render: (row, col) =>
          row[col]?.replace("GMT", "UTC") || titleLabels.NOT_AVAILABLE,
      },
      {
        id: "monitoredBy",
        label: "Monitored By",
        render: (row) => row.monitoredBy || titleLabels.NOT_AVAILABLE,
      },
    ];
    if (cllmPrivilege.includes("Alert Log Status")) {
      {
        hc.push({
          id: "id",
          label: "Status",
          render: (row, col) => (
            <div>
              {!row.isMonitored ? (
                <>
                  {" "}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          value={row?.isMonitored}
                          onChange={() => changeStatus(row)}
                        />
                      }
                      label="Monitor"
                    />
                  </FormGroup>
                </>
              ) : (
                <>
                  <Chip
                    icon={<MonitorIcon />}
                    color="success"
                    label="Monitored"
                  />
                </>
              )}
            </div>
          ),
        });
      }
    }

    return hc;
  }, [allCompaniesV2]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
    searchData: searchData,
    page,
    rowsPerPage,
    orderBy,
    order,
  } = useTable(
    tableData,
    headCells,
    filterFn,
    false,
    true,
    null,
    null,
    totalCount,
    true
  );

  React.useEffect(() => {
    if (isCompaniesLoaded === true) {
      let payload = JSON.parse(JSON.stringify(filterPayload));
      payload["page"] = page;
      payload["count"] = rowsPerPage;
      if (orderBy && order) {
        payload["orderBy"] = orderBy;
        payload["order"] = order == "asc" ? 1 : -1;
      }

      getAlertData(payload);
    }
  }, [
    isCompaniesLoaded,
    count,
    filterPayload,
    page,
    rowsPerPage,
    orderBy,
    order,
  ]);

  const getAlertData = async (payload = {}) => {
    setloading(true);
    const tempData = [];
    const res = await getLoadMonitoringAlert(payload);
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      res?.data?.data?.forEach((single) => {
        single["depotName"] = allCompaniesV2
          ?.find((obj) =>
            obj.depotsList.some((depot) => depot.depotId === single?.siteId)
          )
          ?.depotsList.find(
            (item) => item.depotId == single?.siteId
          )?.depotName;
        single["accountName"] = allCompaniesV2?.find((obj) =>
          obj.depotsList.some((depot) => depot.depotId === single?.siteId)
        )?.companyName;
        tempData.push(single);
      });

      setTableData(tempData);
      setTotalCount(res?.data?.count);
      setloading(false);
    } else {
      setTableData([]);
      setloading(false);
    }
  };

  React.useEffect(() => {
    if (isCompaniesLoaded === true) {
      let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
      if (tempAllCompaniesV2.length !== 0) {
        const accData = tempAllCompaniesV2.map((row) => {
          row.checked = false;
          row.isExpanded = true;
          if ("depotsList" in row) {
            row.depotsList?.map((item) => {
              item.checked = false;
            });
          }
          return row;
        });
        setAllAccounts([{ ...allAccounts[0], children: accData }]);
      }
    }
  }, [isCompaniesLoaded]);

  const changeStatus = async (row) => {
    const res = await updateLoadMonitoringAlert(row.id);
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: `You Are Monitoring This Alert`,
        type: "success",
      });
      setCount((prevCount) => prevCount + 1);
    } else {
      setToast({
        isOpen: true,
        message: `Sorry, Something Went Wrong`,
        type: "error",
      });
    }
  };

  const applyFilter = (
    tempAllAccounts,
    tempUserFilter,
    tempAllAlertFor,
    tempAllMonitorFor,
    tempAllIsMonitored,
    tempGeneratedOnType
  ) => {
    toggleDrawer(false, "FILTER", {});
    let selectedAccFilters = [];
    let selectedUsers = [];
    let selectedAlertFor = [];
    let selectedMonitorFor = [];
    let selectedIsMonitored = [];

    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    if (tempUserFilter[0].children.length) {
      selectedUsers = tempUserFilter[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    if (tempAllAlertFor[0].children.length) {
      selectedAlertFor = tempAllAlertFor[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    if (tempAllMonitorFor[0].children.length) {
      selectedMonitorFor = tempAllMonitorFor[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    if (tempAllIsMonitored[0].children.length) {
      selectedIsMonitored = tempAllIsMonitored[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }

    if (tempGeneratedOnType !== "custom" || (customFromDate && customToDate))
      setGeneratedOnType(tempGeneratedOnType);
    else setGeneratedOnType("");

    setAllAccounts([...tempAllAccounts]);
    setUserFilter([...tempUserFilter]);
    setAllAlertFor([...tempAllAlertFor]);
    setAllMonitorFor([...tempAllMonitorFor]);
    setAllIsMonitored([...tempAllIsMonitored]);
    if (
      selectedAccFilters.length !== 0 ||
      selectedUsers.length !== 0 ||
      selectedAlertFor.length !== 0 ||
      selectedMonitorFor.length !== 0 ||
      selectedIsMonitored.length !== 0 ||
      tempGeneratedOnType
    ) {
      let queryParamTempObj = {};
      if (selectedAccFilters.length !== 0)
        queryParamTempObj["siteIds"] = selectedAccFilters;

      if (selectedUsers.length !== 0)
        queryParamTempObj["monitoredBy"] = selectedUsers;
      if (selectedAlertFor.length !== 0)
        queryParamTempObj["alertFor"] = selectedAlertFor;
      if (selectedMonitorFor.length !== 0)
        queryParamTempObj["monitorFor"] = selectedMonitorFor;
      if (selectedIsMonitored.length !== 0)
        queryParamTempObj["isMonitored"] = selectedIsMonitored;

      if (tempGeneratedOnType) {
        if (tempGeneratedOnType === "today") {
          queryParamTempObj["startTime"] = Math.floor(
            Date.parse(
              sub(new Date(Math.floor(new Date().getTime())), {
                hours: 24,
              }).toISOString()
            ) / 1000
          );
          queryParamTempObj["endTime"] = Math.floor(Date.now() / 1000);
        } else if (tempGeneratedOnType === "Last7days") {
          queryParamTempObj["startTime"] = Math.floor(
            Date.parse(
              sub(new Date(Math.floor(new Date().getTime())), {
                days: 7,
              }).toISOString()
            ) / 1000
          );
          queryParamTempObj["endTime"] = Math.floor(Date.now() / 1000);
        } else if (tempGeneratedOnType === "Last30days") {
          queryParamTempObj["startTime"] = Math.floor(
            Date.parse(
              sub(new Date(Math.floor(new Date().getTime())), {
                days: 30,
              }).toISOString()
            ) / 1000
          );
          queryParamTempObj["endTime"] = Math.floor(Date.now() / 1000);
        } else if (tempGeneratedOnType === "custom") {
          if (customFromDate && customToDate) {
            queryParamTempObj["startTime"] = Math.floor(
              Date.parse(new Date(customFromDate).toISOString()) / 1000
            );
            queryParamTempObj["endTime"] = Math.floor(
              Date.parse(
                add(new Date(Math.floor(new Date(customToDate).getTime())), {
                  hours: 23,
                  minutes: 59,
                }).toISOString()
              ) / 1000
            );
          }
        }
      }

      setFilterPayload(queryParamTempObj);
      setIsFiltered(true);
    } else {
      setFilterPayload({});
      setIsFiltered(false);
    }
  };

  const ToolbarMemoised = useCallback(
    ({ searchData, toggleDrawer }) => {
      return (
        <Toolbar className="table_toolbar">
          <Grid sm item />
          <Badge color="primary" variant="dot" invisible={!isFiltered}>
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={
                <TuneIcon
                  style={{
                    color: isFiltered ? "#2770D8" : "#A3B6C7",
                  }}
                />
              }
              onClick={() => {
                toggleDrawer(true, "FILTER", {});
              }}
              className="filter"
              style={{
                border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
              }}
            />
          </Badge>
        </Toolbar>
      );
    },
    [isFiltered]
  );

  return (
    <>
      <NewPageMainContent>
        <OpsBreadcrumb
          AllBreadcrumbsLinks={AllBreadcrumbsLinks}
          title="Alert Log"
        />
        <div className="circuit_monitor">
          <div>
            <ToolbarMemoised
              searchData={searchData}
              toggleDrawer={toggleDrawer}
            />
            <TableContainer>
              <TableHead />
              {TableBody}
            </TableContainer>

            {TablePagination}
          </div>
        </div>
        <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
          <CLLMAlertFilter
            allAccounts={allAccounts}
            userFilter={userFilter}
            allAlertFor={allAlertFor}
            allMonitorFor={allMonitorFor}
            allIsMonitored={allIsMonitored}
            generatedOnType={generatedOnType}
            customFromDate={customFromDate}
            customToDate={customToDate}
            setCustomFromDate={setCustomFromDate}
            setCustomToDate={setCustomToDate}
            applyFilter={applyFilter}
          />
        </NewCommonFilterDrawer>
        <Loader isLoading={loading} />
        <ToastMessage toast={toast} setToast={setToast} />
      </NewPageMainContent>
    </>
  );
};

export default CLLMAlertTable;
