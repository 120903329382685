import {
  apiProvider,
  indexedEndPoints,
} from "../services/api/utilities/provider";

export const getZipcode = (placeId, setValues) => {
  const sessionToken = new window.google.maps.places.AutocompleteSessionToken();

  var request = {
    placeId: placeId,
    fields: ["address_components", "utc_offset"],
    sessionToken: sessionToken,
  };
  const service = new window.google.maps.places.PlacesService(
    document.createElement("div")
  );

  service.getDetails(request, callback);

  function callback(place, status) {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      place.address_components.forEach((address) => {
        if (address.types.includes("postal_code")) {
          setValues((prevState) => ({
            ...prevState,
            zipcode: address.long_name,
          }));
        }
      });
    }

    return place;
  }
};

export const getLatLng = (placeId, setLat, setLng) => {
  const geoCoder = new window.google.maps.Geocoder();

  geoCoder.geocode({ placeId }, callback);
  function callback(geocode, status) {
    if (status === window.google.maps.GeocoderStatus.OK) {
      setLat(geocode[0].geometry.location.lat());
      setLng(geocode[0].geometry.location.lng());
    }
  }
};

export const getTimezoneAndZipcode = async (
  place_id,
  setValues,
  setAdditionalInfoLoader
) => {
  const { PLACE_DETAILS } = indexedEndPoints;
  setAdditionalInfoLoader(true);
  const response = await apiProvider.post(PLACE_DETAILS, { place_id });
  if (response?.statusCode === 200) {
    const {
      timeZoneId,
      timeZoneOffset,
      latitude,
      longitude,
      city,
      country,
      state,
      postalCode,
      countryCode,
      streetAddress,
    } = response?.data;
    setValues((prevState) => ({
      ...prevState,
      timeZoneId,
      timeZoneOffset,
      latitude,
      longitude,
      city,
      country,
      state,
      postalCode,
      countryCode,
      streetAddress,
    }));
  }
  setAdditionalInfoLoader(false);
};

export const getTimezoneAndZipcodeForBilling = async (
  place_id,
  setValues,
  setAdditionalInfoLoader,
  setIsCountryLoaded
) => {
  const { PLACE_DETAILS } = indexedEndPoints;
  setIsCountryLoaded(true);
  setAdditionalInfoLoader(true);
  const response = await apiProvider.post(PLACE_DETAILS, { place_id });
  if (response?.statusCode === 200) {
    setValues((prevState) => ({
      ...prevState,
      billingAddress: response?.data,
    }));
  }
  setAdditionalInfoLoader(false);
  setIsCountryLoaded(false);
};
