import * as React from "react";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Checkbox, Autocomplete, TextField, Tooltip } from "@mui/material";
import Controls from "../controls/Controls";
import InfoIcon from "@mui/icons-material/Info";

const AccountsAndDepotsFilter = (props) => {
  const depotType = ["CUSTOMER", "TEST", "INTERNAL", "PILOT", "DEALER"];
  let { _allAccounts, setFuntion, extraFilter = null } = props;
  const [selectedDepotType, setDepotType] = React.useState([]);
  const [selectedExtraFilter, setExtraFilter] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [allAccounts, setAllAccounts] = React.useState([]);
  React.useEffect(() => {
    if (_allAccounts.length) {
      for (var i = 0; i < _allAccounts.length; i++) {
        _allAccounts[i].index = i;
        for (var j = 0; j < _allAccounts[i].children.length; j++) {
          _allAccounts[i].children[j].index = j;
          for (
            var k = 0;
            k < _allAccounts[i].children[j].depotsList.length;
            k++
          ) {
            _allAccounts[i].children[j].depotsList[k].index = k;
          }
        }
      }
      setFuntion([..._allAccounts]);
    }
  }, []);

  React.useEffect(() => {
    if (_allAccounts.length) {
      let filtered = [];
      const _allAccounts_ = JSON.parse(JSON.stringify(_allAccounts));

      for (const company of _allAccounts_[0].children) {
        const matchedDepots = company.depotsList?.filter((depot) =>
          depot.depotName.toLowerCase().includes(searchText.toLowerCase())
        );
        const matchedCompany = company?.companyName
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());

        if (matchedDepots.length) {
          filtered.push({
            ...company,
            depotsList: matchedDepots,
          });
        } else if (matchedCompany) {
          filtered.push(company);
        }
      }

      if (selectedDepotType.length) {
        const _filtered = [];
        for (const company of filtered) {
          const matchedDepots = company.depotsList.filter((depot) =>
            selectedDepotType.includes(depot.depotType)
          );
          if (matchedDepots.length) {
            _filtered.push({
              ...company,
              depotsList: matchedDepots,
            });
          }
        }
        filtered = _filtered;
      }

      if (Object.values(selectedExtraFilter)?.length) {
        const _filtered = [];
        for (const company of filtered) {
          let matchedDepots = [];
          if (selectedExtraFilter["Demand Response"]?.length)
            matchedDepots = company.depotsList.filter(
              (depot) =>
                depot.energyServicePrograms?.find((row) =>
                  selectedExtraFilter["Demand Response"]?.includes(
                    row.programName
                  )
                ) !== undefined
            );
          if (matchedDepots.length) {
            _filtered.push({
              ...company,
              depotsList: matchedDepots,
            });
          }
        }
        if (_filtered?.length) filtered = _filtered;
      }

      setAllAccounts([
        {
          ..._allAccounts_[0],
          children: filtered,
        },
      ]);
    }
  }, [
    selectedDepotType,
    selectedExtraFilter,
    searchText,
    setFuntion,
    _allAccounts,
  ]);

  const expandCollapse = (
    parentInd,
    firstChildIndex = null,
    secondChildIndex = null
  ) => {
    if (secondChildIndex !== null) {
      _allAccounts[parentInd].children[firstChildIndex].depotsList[
        secondChildIndex
      ].isExpanded =
        !_allAccounts[parentInd].children[firstChildIndex].depotsList[
          secondChildIndex
        ].isExpanded;
      setFuntion([..._allAccounts]);
    } else if (firstChildIndex !== null) {
      _allAccounts[parentInd].children[firstChildIndex].isExpanded =
        !_allAccounts[parentInd].children[firstChildIndex].isExpanded;
      setFuntion([..._allAccounts]);
    } else {
      _allAccounts[parentInd].isExpanded = !_allAccounts[parentInd].isExpanded;
      setFuntion([..._allAccounts]);
    }
  };

  const parentClicked = (accountIndex, visAccountIndex) => {
    const isChecked = !_allAccounts[accountIndex].checked;
    _allAccounts[accountIndex].checked = isChecked;
    for (let j = 0; j < allAccounts[visAccountIndex].children.length; j++) {
      const j_index = allAccounts[visAccountIndex].children[j].index;
      _allAccounts[accountIndex].children[j_index].checked = isChecked;
      for (
        let k = 0;
        k < allAccounts[visAccountIndex].children[j].depotsList.length;
        k++
      ) {
        const depot = allAccounts[visAccountIndex].children[j].depotsList[k];
        const k_index = depot.index;
        _allAccounts[accountIndex].children[j_index].depotsList[
          k_index
        ].checked = isChecked;
      }
    }
    setFuntion([..._allAccounts]);
  };

  const childClicked = (
    accountIndex,
    visAccountIndex,
    companyIndex,
    visCompanyIndex
  ) => {
    const isChecked =
      !_allAccounts[accountIndex].children[companyIndex].checked;
    _allAccounts[accountIndex].children[companyIndex].checked = isChecked;
    _allAccounts[accountIndex].checked = _allAccounts[
      accountIndex
    ].children.every((company) => company.checked);

    for (
      let k = 0;
      k <
      allAccounts[visAccountIndex].children[visCompanyIndex].depotsList.length;
      k++
    ) {
      const depot =
        allAccounts[visAccountIndex].children[visCompanyIndex].depotsList[k];
      const depotIndex = depot.index;
      _allAccounts[accountIndex].children[companyIndex].depotsList[
        depotIndex
      ].checked = isChecked;
    }
    setFuntion([..._allAccounts]);
  };

  const grandChildClicked = (
    accountIndex,
    visAccountIndex,
    companyIndex,
    visCompanyIndex,
    depotIndex,
    visDepotIndex
  ) => {
    const depot =
      _allAccounts[accountIndex].children[companyIndex].depotsList[depotIndex];
    depot.checked = !depot.checked;

    // Check if all depots got selected by this click.
    const visibleDepots = allAccounts[visAccountIndex].children[
      visCompanyIndex
    ].depotsList.map((depot) => depot.depotName);
    const checkedDepots = _allAccounts[accountIndex].children[
      companyIndex
    ].depotsList.filter(
      (depot) => visibleDepots.includes(depot.depotName) && depot.checked
    );

    if (!checkedDepots.length) {
      // No depots selected, hence company.checked == false, account.checked == false
      _allAccounts[accountIndex].children[companyIndex].checked = false;
      _allAccounts[accountIndex].checked = false;
    }

    if (visibleDepots.length === checkedDepots.length) {
      // If all visible depots are checked, then company.checked == true
      _allAccounts[accountIndex].children[companyIndex].checked = true;
    }

    if (_allAccounts[accountIndex].children[companyIndex].checked) {
      const visibleCompanies = allAccounts[visAccountIndex].children.map(
        (company) => company.companyName
      );
      const checkedCompanies = _allAccounts[accountIndex].children.filter(
        (company) =>
          visibleCompanies.includes(company.companyName) && company.checked
      );
      // If all visible companies are checked, then account.checked == true
      _allAccounts[accountIndex].checked =
        visibleCompanies.length === checkedCompanies.length;
    }
    setFuntion([..._allAccounts]);
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    setSearchText(e.target.value?.trim() ?? "");
  };

  const handleDepotType = (event, value) => {
    setDepotType(value);
  };

  return (
    <>
      <div className="filter_Depot_Type">
        <p>
          Depot Type{" "}
          <Tooltip title="The purpose of selecting DepotType is to filter the accounts and depots listed below based on the chosen selection. Selecting this DepotType will not apply any filter.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        </p>
        <Autocomplete
          multiple
          name="depotType"
          id="depot_type_mutli_select"
          style={{ width: "100%" }}
          disableCloseOnSelect
          value={selectedDepotType}
          onChange={handleDepotType}
          options={depotType}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder={selectedDepotType.length ? "" : "All"}
            />
          )}
        />
      </div>
      <br />
      {extraFilter?.includes("Demand Response") && (
        <div className="filter_Depot_Type">
          <p>
            Demand Response{" "}
            <Tooltip title="The purpose of selecting Demand Response program is to filter the accounts and depots listed below based on the chosen selection. Selecting this program will not apply any filter.">
              <InfoIcon fontSize="small" />
            </Tooltip>
          </p>
          <Autocomplete
            multiple
            name="depotType"
            id="depot_type_mutli_select"
            style={{ width: "100%" }}
            disableCloseOnSelect
            value={selectedExtraFilter["Demand Response"]}
            onChange={(_, val) =>
              setExtraFilter({
                ...selectedExtraFilter,
                ["Demand Response"]: val,
              })
            }
            options={["ELRP", "CPS"]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder={
                  selectedExtraFilter["Demand Response"]?.length ? "" : "All"
                }
              />
            )}
          />
        </div>
      )}
      <div className="filter_Search">
        <Controls.Input
          label={"Search"}
          style={{ width: "100%" }}
          id="depot_search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </div>
      <div>
        {allAccounts[0]?.children.length > 0 ? (
          allAccounts.length > 0 &&
          allAccounts.map((acc, index) => (
            <div
              className="side_filter_new"
              key={`allAccounts-${index}`}
              style={{
                border: "1px solid #DBE3EA",
                marginBottom: "15px",
                borderRadius: "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                className="filter_parent"
              >
                <Checkbox
                  checked={acc.checked}
                  indeterminate={
                    acc.children?.length >
                      acc.children?.filter((value) => value.checked == true)
                        ?.length &&
                    acc.children?.some((value) => value.checked == true)
                  }
                  onClick={() => parentClicked(acc.index, index)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  id="checkbox_account_and_depot_name"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => expandCollapse(acc.index)}
                >
                  <p className="parent_p">{acc.name}</p>
                  {acc.isExpanded === false ? (
                    <ExpandMoreIcon className="accordion_arrows" />
                  ) : (
                    <ExpandLessIcon className="accordion_arrows" />
                  )}
                </div>
              </div>

              {acc.isExpanded && (
                <>
                  {acc.children &&
                    acc.children.length > 0 &&
                    acc.children.map((acch, ind) => (
                      <div key={ind} style={{ border: "1px solid #DBE3EA" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          className="filter_child"
                        >
                          <Checkbox
                            checked={acch.checked}
                            id={"checkbox_" + acch?.companyId}
                            indeterminate={
                              acch.depotsList?.length >
                                acch.depotsList?.filter(
                                  (value) => value.checked == true
                                )?.length &&
                              acch.depotsList?.some(
                                (value) => value.checked == true
                              )
                            }
                            onClick={() =>
                              childClicked(acc.index, index, acch.index, ind)
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className={acch.children && "manual_pointer"}
                            onClick={() =>
                              expandCollapse(acc.index, acch.index)
                            }
                          >
                            <p className="child_p"> {acch.companyName}</p>
                            {acch.depotsList && (
                              <>
                                {acch.isExpanded === false ? (
                                  <AddIcon className="accordion_arrows" />
                                ) : (
                                  <RemoveIcon className="accordion_arrows" />
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {acch.isExpanded && (
                          <div
                            style={{
                              padding: "0px 30px",
                            }}
                            className="filter_grand_child"
                          >
                            {acch.depotsList &&
                              acch.depotsList.length > 0 &&
                              acch.depotsList.map((depot, indx) => (
                                <div key={`acch-${indx}`}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      checked={depot.checked}
                                      id={"checkbox_" + depot?.depotId}
                                      onClick={() =>
                                        grandChildClicked(
                                          acc.index,
                                          index,
                                          acch.index,
                                          ind,
                                          depot.index,
                                          indx
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                    <p className="grand_child_p">
                                      {depot.depotName}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                </>
              )}
            </div>
          ))
        ) : (
          <p className="no_data_in_filter">No Data</p>
        )}
      </div>
    </>
  );
};
export default AccountsAndDepotsFilter;
