import React, { useCallback, useState } from "react";
import UploadAndPreviewCSV from "../../../components/UploadAndPreviewCSV";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Controls from "../../../components/controls/Controls";
import ToastMessage from "../../../components/ToastMessage";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import BulkUploadAndPreview from "../BulkUploadAndPreview";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useTable from "../../../components/UseTable";
import Popup from "../../../components/Popup";
import NewPageMainContent from "../../../components/NewPageMainContent";

const headers = [
  { key: "modelYear", label: "modelYear" },
  { key: "obccESN", label: "obccESN" },
  { key: "obccMacAddress", label: "obccMacAddress" },
  { key: "programCode", label: "programCode" },
  { key: "region", label: "region" },
  { key: "vin", label: "vin" },
];

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
  {
    link: routePaths.VIN_VID_MAPPING,
    title: "VIN-VID Mapping",
  },
];

const reviewHeaders = [
  { id: "row", label: "Row number" },
  { id: "incorrect", label: "Incorrect fields" },
  { id: "reason", label: "Reason" },
];

const filterFn = { fn: (items) => items };

const BulkUploadVinVidMappings = () => {
  const { ADD_VIN_VID_MAPPING } = indexedEndPoints;
  const {
    tableData,
    setTableData,
    fileName,
    setFileName,
    setErrors,
    UploadPreviewCSV,
  } = UploadAndPreviewCSV();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [validData, setValidData] = useState([]);
  const [reviewInvalidData, setReviewInvalidData] = useState([]);
  const history = useHistory();

  const validateBulkUpload = () => {
    const validRows = [];
    const invalidRows = [];
    const validMac = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
    tableData?.forEach((row, index) => {
      const invalidRowDetail = { row: index + 1, missing: [], incorrect: [] };
      for (let [key, value] of Object.entries(row)) {
        if (!value?.length) invalidRowDetail.missing.push(key);
        else {
          if (key === "modelYear" && Number.isInteger(value))
            invalidRowDetail.incorrect.push("modelYear");
          else if (key === "obccMacAddress" && !validMac.test(value))
            invalidRowDetail.incorrect.push("obccMacAddress");
        }
      }
      if (invalidRowDetail.missing.length)
        invalidRows.push({
          row: invalidRowDetail.row,
          incorrect: invalidRowDetail.missing?.join(", "),
          reason: "Mandatory field(s) not filled",
        });
      if (invalidRowDetail.incorrect.length)
        invalidRows.push({
          row: invalidRowDetail.row,
          incorrect: invalidRowDetail.incorrect?.join(", "),
          reason: "Data is incorrectly formatted",
        });
      if (
        !invalidRowDetail.missing.length &&
        !invalidRowDetail.incorrect.length
      )
        validRows.push(row);
    });
    if (validRows?.length) setValidData(validRows);
    if (invalidRows?.length)
      setErrors(
        <div className="validationMsg">
          <p>
            {tableData?.length - validRows?.length} rows are missing mandatory
            data or have been incorrectly formatted. Please correct and
            re-select the file.
          </p>
          <Controls.Button
            text="Review"
            variant="outlined"
            startIcon={<VisibilityIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Invalid rows from the uploaded CSV",
                child: "invalidUploadedErrorCodes",
                item: invalidRows,
              })
            }
          />
        </div>
      );
    setTableData(validRows);
    setReviewInvalidData(invalidRows);
  };

  const handleBulkUpload = useCallback(async () => {
    setLoading(true);
    const response = await apiProvider.post(ADD_VIN_VID_MAPPING, tableData);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Bulk upload of VIN-VID Mapping successful",
        type: "success",
      });
      setTimeout(() => history.push(routePaths.VIN_VID_MAPPING), [3000]);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, [tableData]);

  const handleCancelUpload = () => {
    setTableData([]);
    setErrors("");
    setFileName("");
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(reviewInvalidData, reviewHeaders, filterFn);

  return (
    <NewPageMainContent>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Bulk Upload VIN-VID Mapping"
      />
      <PageHeader title="Bulk Upload VIN-VID Mapping" />
      <BulkUploadAndPreview
        name="VIN-VID mappings"
        headers={headers}
        templateFilename="VinVidMapping.csv"
        loading={loading}
        handleBulkUpload={() =>
          validData?.length ? handleBulkUpload() : validateBulkUpload()
        }
        handleCancelUpload={handleCancelUpload}
        uploadFilename={fileName}
        tableData={tableData}
        UploadPreviewCSV={UploadPreviewCSV}
        submitBtnLabel={validData?.length ? "Submit" : "Upload"}
      />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth="lg">
        <div className="bulkErrorCodeReview">
          <p className="fileNameLabel">
            File name: <span className="fileNameValue">{fileName}</span>
          </p>
          <div className="guideContainer">
            <p className="label">
              <InfoIcon fontSize="small" className="iconColor" />
              Please update values in these fields. After updating, select the
              file again and upload.
            </p>
          </div>
          <TableContainer>
            <TableHead />
            {TableBody}
          </TableContainer>
          {TablePagination}
          <div className="bulkUploadSubmit">
            <Controls.Button
              text="Close"
              onClick={() => setOpenPopup({ ...openPopup, isOpen: false })}
            />
          </div>
        </div>
      </Popup>
      <ToastMessage toast={toast} setToast={setToast} autoHideDuration={2000} />
    </NewPageMainContent>
  );
};

export default BulkUploadVinVidMappings;
