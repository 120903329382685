export const getCheckedValues = (arr, key) => {
  return arr?.filter((item) => item[key] === true).map((item) => item[key]);
};

export const cryptoRandom = () => {
  const typedArray = new Uint8Array(1);
  const randomValue = crypto.getRandomValues(typedArray)[0];
  const randomFloat = randomValue / Math.pow(2, 8);
  return randomFloat;
};
