import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";

const OpsBreadcrumb = (props) => {
  const { AllBreadcrumbsLinks, title } = props;
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className="ops_breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      <Link className="breadcrumb_link" to="/">
        <HomeIcon />
      </Link>
      {AllBreadcrumbsLinks &&
        AllBreadcrumbsLinks.length !== 0 &&
        AllBreadcrumbsLinks.map((singleLinks) => {
          return (
            <Link
              key={
                typeof singleLinks.link === "string"
                  ? singleLinks.link
                  : singleLinks.title
              }
              className="breadcrumb_link"
              to={singleLinks.link}
            >
              {singleLinks.title}
            </Link>
          );
        })}
      <Typography className="breadcrumb_title" color="textPrimary">
        {title}
      </Typography>
    </Breadcrumbs>
  );
};

export default OpsBreadcrumb;
