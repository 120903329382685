import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const {
  SERVICE_API_SIGNATURE,
  GET_SERVICE_COMPANIES,
  SERVICE_CHARGE_PORTS,
  SCHEDULED_SERVICES,
  SERVICE_HISTORY,
  SERVICE_EXECUTE,
  SERVICE_BULK_EXECUTE,
  UPDATE_FIRMWARE_EXECUTE,
  SERVICE_DEPOTS,
  CHARGER_META_FIRMWARE,
  SERVICE_HISTORY_EXPORT_CSV,
  SERVICE_BULK_FIRMWARE_REQ_DETAILS,
  SERVICE_BULK_FIRMWARE_LAST_ATTEMPTED,
  SERVICE_EXECUTE_V2,
  SERVICE_BULK_EXECUTE_V2,
  GET_UPDATED_STATUS,
} = indexedEndPoints;

export const getApiSignature = async () =>
  await apiProvider.getAll(SERVICE_API_SIGNATURE);

export const getCompanies = async () =>
  await apiProvider.getAll(GET_SERVICE_COMPANIES);

export const getChargePorts = async (payload) =>
  await apiProvider.post(SERVICE_CHARGE_PORTS, payload);

export const getBulkFirmareReqDetails = async (queryParams) =>
  await apiProvider.getAll(
    `${SERVICE_BULK_FIRMWARE_REQ_DETAILS}${queryParams}`
  );

export const getFirmwareLastAttempted = async (depotId, chargerId) =>
  await apiProvider.getAll(
    `${SERVICE_BULK_FIRMWARE_LAST_ATTEMPTED}?depotId=${depotId}&chargerId=${chargerId}`
  );

export const getChargerMetaFirmware = async (chargerMetaId) =>
  await apiProvider.getAll(`${CHARGER_META_FIRMWARE}${chargerMetaId}`);

export const getScheduledServices = async () =>
  await apiProvider.getAll(SCHEDULED_SERVICES);

export const postHistory = async (payload) =>
  await apiProvider.post(SERVICE_HISTORY, payload);

export const getHistory = async (query, payload = {}) =>
  await apiProvider.post(`${SERVICE_HISTORY}?${query}`, payload);

export const executeInternalApis = async (isProvisioning, type, payload) =>
  isProvisioning
    ? await apiProvider.post(
        type === "bulk" ? SERVICE_BULK_EXECUTE : SERVICE_EXECUTE,
        payload
      )
    : await apiProvider.post(
        type === "bulk" ? SERVICE_BULK_EXECUTE_V2 : SERVICE_EXECUTE_V2,
        payload
      );

export const executeBulkFirmwareUpdate = async (payload) =>
  await apiProvider.post(UPDATE_FIRMWARE_EXECUTE, payload);

export const getDepotDetailsByCompanyId = async (id) =>
  await apiProvider.getAll(SERVICE_DEPOTS.replace("id", id));

export const servicesHistoryExportCsv = async (payload) => {
  let dataSize = 0;
  return fetch(SERVICE_HISTORY_EXPORT_CSV, {
    method: "POST",
    headers: await apiProvider.getHeaders(),
    body: JSON.stringify(payload),
  })
    .then(function (response) {
      if (response.status !== 200) {
        return "";
      } else {
        return response.blob();
      }
    })

    .then((data) => {
      dataSize = data.size;
      if (dataSize > 0) {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `ServicesHistory.csv`;
        a.click();
        return dataSize;
      } else return 0;
    });
};

export const getUpdatedStatus = async (messageId) =>
  await apiProvider.put(`${GET_UPDATED_STATUS}${messageId}`, {});

export default {
  getApiSignature,
  getCompanies,
  getChargePorts,
  executeInternalApis,
  executeBulkFirmwareUpdate,
  postHistory,
  getHistory,
  getDepotDetailsByCompanyId,
  getBulkFirmareReqDetails,
  getFirmwareLastAttempted,
  getUpdatedStatus,
};
