import React from "react";
import Controls from "../../../../components/controls/Controls";

const ChargerCredential = (props) => {
  return (
    <>
      <div>
        <div className="single_details">
          <p className="title">Charger Name</p>
          <p className="title_value">{props?.DrawerOC?.data?.chargerName}</p>
        </div>
        <Controls.CopyToClipboard
          name={props?.DrawerOC?.data}
          isJson={true}
          setToast={props.setToast}
          max_Height={"600px"}
        />
      </div>
    </>
  );
};
export default ChargerCredential;
