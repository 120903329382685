import React, { useCallback, useMemo, useState } from "react";
import UploadAndPreviewCSV from "../../../components/UploadAndPreviewCSV";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Controls from "../../../components/controls/Controls";
import ToastMessage from "../../../components/ToastMessage";
import { useHistory, useLocation } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import BulkUploadAndPreview from "../BulkUploadAndPreview";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useTable from "../../../components/UseTable";
import Popup from "../../../components/Popup";
import NewPageMainContent from "../../../components/NewPageMainContent";

const headers = [
  { key: "model", label: "model" },
  { key: "batteryCapacity", label: "batteryCapacity" },
  { key: "efficiency", label: "efficiency" },
];

const reviewHeaders = [
  { id: "row", label: "Row number" },
  { id: "incorrect", label: "Incorrect fields" },
  { id: "reason", label: "Reason" },
];

const filterFn = { fn: (items) => items };

const BulkUploadVehicles = () => {
  const { VEHICLES } = indexedEndPoints;
  const {
    tableData,
    setTableData,
    fileName,
    setFileName,
    setErrors,
    UploadPreviewCSV,
  } = UploadAndPreviewCSV();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [validData, setValidData] = useState([]);
  const [reviewInvalidData, setReviewInvalidData] = useState([]);
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");

  const allBreadcrumbsLinks = useMemo(
    () => [
      {
        link: routePaths.DATA_CATALOG,
        title: "Data Catalog",
      },
      {
        link: `${routePaths.VEHICLES_DASHBOARD}${search}`,
        title: "Vehicles Dashboard",
      },
    ],
    []
  );

  const validateBulkUpload = () => {
    const validRows = [];
    const invalidRows = [];
    tableData?.forEach((row, index) => {
      const invalidRowDetail = { row: index + 1, missing: [], incorrect: [] };
      for (let [key, value] of Object.entries(row)) {
        if (!value?.length) invalidRowDetail.missing.push(key);
        else {
          if (key === "batteryCapacity" && isNaN(value))
            invalidRowDetail.incorrect.push("batteryCapacity");
          else if (key === "efficiency" && isNaN(value))
            invalidRowDetail.incorrect.push("efficiency");
        }
      }
      if (invalidRowDetail.missing.length)
        invalidRows.push({
          row: invalidRowDetail.row,
          incorrect: invalidRowDetail.missing?.join(", "),
          reason: "Mandatory field(s) not filled",
        });
      if (invalidRowDetail.incorrect.length)
        invalidRows.push({
          row: invalidRowDetail.row,
          incorrect: invalidRowDetail.incorrect?.join(", "),
          reason: "Data is incorrectly formatted",
        });
      if (
        !invalidRowDetail.missing.length &&
        !invalidRowDetail.incorrect.length
      )
        validRows.push(row);
    });
    if (validRows?.length) setValidData(validRows);
    if (invalidRows?.length)
      setErrors(
        <div className="validationMsg">
          <p>
            {tableData?.length - validRows?.length} rows are missing mandatory
            data or have been incorrectly formatted. Please correct and
            re-select the file.
          </p>
          <Controls.Button
            text="Review"
            variant="outlined"
            startIcon={<VisibilityIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Invalid rows from the uploaded CSV",
                child: "invalidUploadedErrorCodes",
                item: invalidRows,
              })
            }
          />
        </div>
      );
    setTableData(validRows);
    setReviewInvalidData(invalidRows);
  };

  const handleBulkUpload = useCallback(async () => {
    const data = tableData?.map((row) => {
      const rowObj = { ...row };
      rowObj.manufacturer = manufacturer;
      return rowObj;
    });
    setLoading(true);
    const response = await apiProvider.post(VEHICLES, data);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Bulk upload vehicles successful",
        type: "success",
      });
      setTimeout(() => history.push(`${routePaths.VEHICLES}${search}`), [3000]);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, [tableData]);

  const handleCancelUpload = () => {
    setTableData([]);
    setErrors("");
    setFileName("");
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(reviewInvalidData, reviewHeaders, filterFn);

  return (
    <NewPageMainContent>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Bulk Upload Vehicles"
      />
      <PageHeader title="Bulk Upload Vehicles" />
      <BulkUploadAndPreview
        name="Vehicles"
        headers={headers}
        templateFilename="Vehicles.csv"
        loading={loading}
        handleBulkUpload={() =>
          validData?.length ? handleBulkUpload() : validateBulkUpload()
        }
        handleCancelUpload={handleCancelUpload}
        uploadFilename={fileName}
        tableData={tableData}
        UploadPreviewCSV={UploadPreviewCSV}
        submitBtnLabel={validData?.length ? "Submit" : "Upload"}
      />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth="lg">
        <div className="bulkErrorCodeReview">
          <p className="fileNameLabel">
            File name: <span className="fileNameValue">{fileName}</span>
          </p>
          <div className="guideContainer">
            <p className="label">
              <InfoIcon fontSize="small" className="iconColor" />
              Please update values in these fields. After updating, select the
              file again and upload.
            </p>
          </div>
          <TableContainer>
            <TableHead />
            {TableBody}
          </TableContainer>
          {TablePagination}
          <div className="bulkUploadSubmit">
            <Controls.Button
              text="Close"
              onClick={() => setOpenPopup({ ...openPopup, isOpen: false })}
            />
          </div>
        </div>
      </Popup>
      <ToastMessage toast={toast} setToast={setToast} autoHideDuration={2000} />
    </NewPageMainContent>
  );
};

export default BulkUploadVehicles;
