import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const { LOAD_MONITOR_GET_CIRCUIT, GET_LOAD_MONITOR, GET_LOAD_MONITOR_ALERT } =
  indexedEndPoints;

export const getAllCircuits = async (id) =>
  await apiProvider.getAll(`${LOAD_MONITOR_GET_CIRCUIT}/${id}/circuit`);

export const getLoadMonitoringAlert = async (payload = {}) =>
  await apiProvider.post(`${GET_LOAD_MONITOR_ALERT}`, payload);

export const updateLoadMonitoringAlert = async (id) =>
  await apiProvider.put(`${GET_LOAD_MONITOR_ALERT}?id=${id}`, {});

export const getDepotMaxValues = async (id) =>
  await apiProvider.getAll(`${LOAD_MONITOR_GET_CIRCUIT}/${id}/max-load`);

export const getDepotChargers = async (id) =>
  await apiProvider.getAll(`${LOAD_MONITOR_GET_CIRCUIT}/${id}/chargers`);

export const getLoadMonitoring = async (payload = {}) =>
  await apiProvider.post(GET_LOAD_MONITOR, payload);

export const getChargersMonitoring = async (payload = {}, id) =>
  await apiProvider.post(
    `${LOAD_MONITOR_GET_CIRCUIT}/${id}/chargers-graph`,
    payload
  );

export default {
  getAllCircuits,
  getLoadMonitoring,
  getDepotMaxValues,
  getDepotChargers,
  getLoadMonitoringAlert,
  updateLoadMonitoringAlert,
  getChargersMonitoring,
};
