export const ALL_MODULES = [
  "Home",
  "Charger Health",
  "Chargers",
  "Usage Analytics",
  "Circuit Monitor",
  "Users",
  "Requests",
  "Companies",
  "Billing",
  "Services",
  "Pre Provisioning",
  "Reports",
  "Data Catalog",
  "Vehicle Discovery",
  "Datasync Events",
];
