import React from "react";
import ErrorCodeDrawerDetails from "../../../data-catalog/errorCodes/ErrorCodeDrawerDetails";
import environment from "../../../../environment";

const FaultsTroubleShoot = (props) => {
  const { troubleShootData, DrawerTCG } = props;
  const [isTS, setIsTS] = React.useState({});
  React.useEffect(() => {
    if (troubleShootData) {
      const matchingList1 = ["vendorErrorCode", "soldAsId"];
      const matchingList2 = ["errorCode", "soldAsId"];
      let matchedRow;
      const genericSoldAsId =
        environment[process.env.REACT_APP_NODE_ENV].GENERIC_CHARGER_SOLD_AS_ID;
      const genericMatch = {
        ...DrawerTCG.data,
        soldAsId: genericSoldAsId,
      };
      if (DrawerTCG.data.vendorErrorCode) {
        matchedRow = troubleShootData.find((single) =>
          matchingList1?.every((item) => single[item] === DrawerTCG.data[item])
        );
        if (matchedRow) {
          setIsTS(matchedRow);
          return;
        }
      }
      if (!matchedRow && DrawerTCG.data.errorCode) {
        matchedRow = troubleShootData.find((single) =>
          matchingList2?.every((item) => single[item] === DrawerTCG.data[item])
        );
        if (matchedRow) {
          setIsTS(matchedRow);
          return;
        }
        matchedRow = troubleShootData.find(
          (single) =>
            matchingList2?.every((item) => single[item] === genericMatch[item]) // match with generic soldAsId
        );
        setIsTS(matchedRow);
        return;
      }
      setIsTS(false); // if both vendorErrorCode and errorCode are missing, don't show any resolution
    }
  }, [troubleShootData]);

  return isTS ? (
    <ErrorCodeDrawerDetails drawerObj={isTS} />
  ) : (
    <div className="troubleShoot_sec_bg">
      <p className="no_data">
        There are no possible resolutions provided by the manufacturer.
      </p>
    </div>
  );
};

export default FaultsTroubleShoot;
