import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import { Form, UseForm } from "../../../components/UseForm";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import { validateNonEmptyFields } from "../../../utils/FieldValidations";
import environment from "../../../environment";

export default function AddEditErrorCodeGroups({
  type,
  openPopup,
  setOpenPopup,
  setRefreshECGroups,
  setToast,
  chargersMeta,
}) {
  const [loading, setLoading] = useState(false);
  const [modelOptions, setModelOptions] = useState([]);

  const addEditInitialValues = useMemo(
    () => ({
      groupName:
        type === "updateErrorCodeGroup" ? openPopup?.item?.groupName : "",
      vendorManufacturer:
        type === "updateErrorCodeGroup"
          ? openPopup?.item?.vendorManufacturer
          : "",
      model:
        type === "updateErrorCodeGroup" ? openPopup?.item?.chargerModels : [],
      chargerMetaIdList:
        type === "updateErrorCodeGroup"
          ? openPopup?.item?.chargerMetaIdList
          : [],
    }),
    [openPopup]
  );

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues);

  useEffect(() => {
    const models = chargersMeta?.find(
      (row) => row.name === values.vendorManufacturer
    )?.chargers;
    if (models)
      setModelOptions(
        models?.filter(
          (model) => !model.errorCodeGroupId || !model.errorCodeGroupId?.length
        )
      );
  }, [values.vendorManufacturer]);

  const { ERROR_CODE_GROUPS } = indexedEndPoints;

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validData = validateNonEmptyFields(
        ["groupName", "vendorManufacturer", "model"],
        values,
        setErrors
      );
      if (validData) setErrors({});
      else return;

      const payload = {
        groupName: values?.groupName?.trim(),
        vendorManufacturer: values?.vendorManufacturer?.trim(),
        chargerMetaIdList: values?.chargerMetaIdList,
      };
      setLoading(true);
      if (type === "addErrorCodeGroup") {
        const response = await apiProvider.post(ERROR_CODE_GROUPS, payload);
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Error code group added successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshECGroups(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.response,
            type: "error",
          });
        }
      } else {
        delete payload["vendorManufacturer"]; // manufacturer is non-editable
        const response = await apiProvider.put(
          `${ERROR_CODE_GROUPS}/${openPopup?.item?.groupId}`,
          payload
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Error code group updated successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshECGroups(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.response,
            type: "error",
          });
        }
      }
      setLoading(false);
    },
    [values]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Loader isLoading={loading} />
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="groupName"
            label="Group name*"
            value={values.groupName}
            error={errors.groupName}
            onChange={handleFormInputChange()}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="vendorManufacturer"
            label="Manufacturer*"
            disabled={type === "updateErrorCodeGroup"}
            data-testid="manufacturer"
            options={chargersMeta?.map((row) => row.name)}
            value={values.vendorManufacturer}
            onChange={handleFormInputChange()}
            error={errors.vendorManufacturer}
          />
        </Grid>
        <Grid item xs={12}>
          <p className="ECGroupNameExample">Ex. Lite-on series 1 AC chargers</p>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            multiple
            getOptionLabel={(item) => item.label}
            value={values.model?.map((item, ind) => ({
              label: item,
              value: values.chargerMetaIdList[ind],
            }))}
            ListboxProps={{ "data-testid": "model" }}
            options={modelOptions?.map((item) => ({
              label: item.vendorModel,
              value: item.chargerMetaId,
            }))}
            onChange={(_, value) => {
              setValues({
                ...values,
                model: value?.map((item) => item.label),
                chargerMetaIdList: value?.map((item) => item.value),
              });
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Models*"
                placeholder="Models"
                error={errors.model}
                helperText={errors.model}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Controls.Button
              type="submit"
              text={"Submit"}
              testid="addErrorCodeGroupSubmitBtn"
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
