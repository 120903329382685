import React from "react";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LinearStepper from "../../components/LinearStepper";
import Controls from "../../components/controls/Controls";

export default function BulkUploadSteps({
  headers,
  filename,
  refGuideName = "",
  refGuideFile = null,
}) {
  const stepsLabel = [
    <p className="bulkUploadSteps">
      Download this CSV template file
      <CSVLink
        filename={filename}
        headers={headers}
        data={[]}
        className="downloadTemplateLink"
      >
        <div className="downloadBtns">
          <FileDownloadIcon fontSize="small" />
          Download Template
        </div>
      </CSVLink>
    </p>,
    <p className="bulkUploadSteps">
      Open the template in a spreadsheet program such as Excel
    </p>,
  ];
  {
    refGuideName?.length > 0 &&
      refGuideFile &&
      stepsLabel.push(
        <p className="bulkUploadSteps">
          Refer to the guide provided below and add records
          <Controls.Button
            text="Download Guide"
            variant="outlined"
            startIcon={<FileDownloadIcon fontSize="small" />}
            href={refGuideFile}
            target="_blank"
            download={refGuideName}
          />
        </p>
      );
  }
  stepsLabel.push(
    <p className="bulkUploadSteps">Select the CSV file to upload</p>,
    <p className="bulkUploadSteps">
      Preview the uploaded data and upload the file
    </p>
  );

  return <LinearStepper stepsLabel={stepsLabel} />;
}
