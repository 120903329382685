import { msalInstance } from "..";
import environment from "../environment";

export const setData = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeData = (key) => {
  return localStorage.removeItem(key);
};

export const getData = (key) => {
  return localStorage.getItem(key);
};

export const getAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) return;

  try {
    const activeAccount = msalInstance.getActiveAccount() || accounts[0];
    let authResult = await msalInstance.acquireTokenSilent({
      scopes: [
        `${
          environment[process.env.REACT_APP_NODE_ENV].VITE_AZURE_CLIENT_ID
        }/.default`,
      ],
      account: activeAccount,
    });
    return authResult;
  } catch (error) {
    msalInstance.clearCache();
  }
};

export const clearLocalStorage = () => {
  return localStorage.clear();
};
