import React from "react";
import { Grid, TextField } from "@mui/material";
import Controls from "../../components/controls/Controls";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useVehiclesDiscovery } from "./VehiclesDiscoveryContext";
import "./vehicleDiscovery.scss";
import { enGB } from "date-fns/locale";

export default function DateRangePicker() {
  const date = new Date();
  const {
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    applyFilter,
    allAccounts,
    allDepotType,
    allMakes,
    allPowerType,
    countryCodes,
    excludeDepotsWithNoVehicles,
    allHomeChargingFlag,
    excludeNoEnergyDeliveredSessions,
    excludeShortSessions,
  } = useVehiclesDiscovery();

  const [tempFromDate, setTempFromDate] = React.useState(
    JSON.parse(JSON.stringify(fromDate))
  );
  const [tempToDate, setTempToDate] = React.useState(
    JSON.parse(JSON.stringify(toDate))
  );
  const handleDateChange = (date) => (e) => {
    if (date === "fromDate") {
      setTempFromDate(e);
      setFromDate(e);
    } else {
      setTempToDate(e);
      setToDate(e);
    }
  };
  const clearAll = () => {
    setTempFromDate(new Date(date.setDate(date.getDate() - 30)));
    setTempToDate(new Date());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="dateContainer">
        <div className="fromToDates">
          <DatePicker
            className="mui-date-picked-reports"
            id="date-picker-dialog"
            label="From Date"
            inputVariant="outlined"
            disableFuture={true}
            value={tempFromDate}
            onChange={handleDateChange("fromDate")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
          />
          <DatePicker
            className="mui-date-picked-reports"
            id="date-picker-dialog"
            label="To Date"
            inputVariant="outlined"
            disableFuture={true}
            value={tempToDate}
            onChange={handleDateChange("toDate")}
            maxDate={new Date()}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
          />
          <Controls.Button
            size="small"
            text="Go"
            onClick={() => {
              applyFilter(
                allAccounts,
                allMakes,
                allPowerType,
                countryCodes,
                allDepotType,
                tempFromDate,
                tempToDate,
                excludeDepotsWithNoVehicles,
                allHomeChargingFlag,
                excludeNoEnergyDeliveredSessions,
                excludeShortSessions
              );
              setFromDate(tempFromDate);
              setToDate(tempToDate);
            }}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
}
