import environment from "../../environment";
import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const {
  GET_ALL_V2_COMPANIES,
  GET_REPORTS_HISTORY,
  GENERATE_REPORTS,
  POST_REPORTS_GENERATE,
} = indexedEndPoints;
const BASE_URL = environment[process.env.REACT_APP_NODE_ENV].BACK_END_URL;

// export const getCompanies = async () =>
//   await apiProvider.getAll(GET_ALL_V2_COMPANIES);

export const getReportsHistory = async (queryparam, payload) =>
  payload
    ? await apiProvider.post(`${GET_REPORTS_HISTORY}?${queryparam}`, payload)
    : await apiProvider.post(`${GET_REPORTS_HISTORY}?${queryparam}`, {});

export const reportGenerate = async (payload) =>
  await apiProvider.post(POST_REPORTS_GENERATE, payload);

// export const form = async (payload, depotName) => {
//   let dataSize = 0;
//   return fetch(`${BASE_URL}/${GENERATE_REPORTS}`, {
//     method: "POST",
//     body: JSON.stringify(payload),
//     headers: await apiProvider.getHeaders(),
//   })
//     .then(function (response) {
//       if (response.status !== 200) {
//         return "";
//       } else {
//         return response.blob();
//       }
//     })
//     .then((data) => {
//       dataSize = data.size;
//       if (dataSize > 0) {
//         var a = document.createElement("a");
//         a.href = window.URL.createObjectURL(data);
//         a.download = `${payload.toDate}-${depotName}-${payload.reportType}.csv`;
//         a.click();
//         return dataSize;
//       } else return 0;
//     });
// };
