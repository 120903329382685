import * as React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Controls from "../../../components/controls/Controls";
import AccountsAndDepotsFilter from "../../../components/all-filter-types/accountAndDepot";
import TabPanel from "../../../components/all-filter-types/FilterTabPanel";
import SingleTypeFilter from "../../../components/all-filter-types/SingleFilter";
import {
  checkDepotSelected,
  checkSingleFilterSelected,
} from "../../../components/all-filter-types/isFilteredCheckFunctions";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const CLLMAlertFilter = (props) => {
  const {
    allAccounts,
    userFilter,
    allAlertFor,
    allMonitorFor,
    allIsMonitored,
    generatedOnType,
    customFromDate,
    customToDate,
    setCustomFromDate,
    setCustomToDate,
    applyFilter,
  } = props;

  const [value, setValue] = React.useState("Account and depot");

  const [tempAllAccounts, setTempAllAccounts] = React.useState(
    JSON.parse(JSON.stringify(allAccounts))
  );

  const [tempUserFilter, setTempUserFilter] = React.useState(
    JSON.parse(JSON.stringify(userFilter))
  );

  const [tempAllAlertFor, setTempAllAlertFor] = React.useState(
    JSON.parse(JSON.stringify(allAlertFor))
  );

  const [tempAllMonitorFor, setTempAllMonitorFor] = React.useState(
    JSON.parse(JSON.stringify(allMonitorFor))
  );
  const [tempAllIsMonitored, setTempAllIsMonitored] = React.useState(
    JSON.parse(JSON.stringify(allIsMonitored))
  );
  const [tempGeneratedOnType, setTempGeneratedOnType] = React.useState(
    JSON.parse(JSON.stringify(generatedOnType))
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const clearAll = () => {
    if (tempAllAccounts[0].children) {
      tempAllAccounts[0].checked = false;
      tempAllAccounts[0].children.map((child) => {
        child.checked = false;
        if (child.depotsList) {
          child.depotsList.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }

    if (tempUserFilter[0].children) {
      tempUserFilter[0].checked = false;
      tempUserFilter[0].children?.map((single) => {
        single.checked = false;
      });
    }

    if (tempAllAlertFor[0].children) {
      tempAllAlertFor[0].checked = false;
      tempAllAlertFor[0].children.map((child) => {
        child.checked = false;
      });
    }

    if (tempAllMonitorFor[0].children) {
      tempAllMonitorFor[0].checked = false;
      tempAllMonitorFor[0].children.map((child) => {
        child.checked = false;
      });
    }

    if (tempAllIsMonitored[0].children) {
      tempAllIsMonitored[0].checked = false;
      tempAllIsMonitored[0].children.map((child) => {
        child.checked = false;
      });
    }

    setTempAllAccounts([...tempAllAccounts]);
    setTempUserFilter([...tempUserFilter]);
    setTempAllAlertFor([...tempAllAlertFor]);
    setTempAllMonitorFor([...tempAllMonitorFor]);
    setTempAllIsMonitored([...tempAllIsMonitored]);

    setTempGeneratedOnType("");
    setCustomFromDate("");
    setCustomToDate("");
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Account and depot"
              value={"Account and depot"}
              icon={
                checkDepotSelected(tempAllAccounts) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Monitored by"
              value={"Monitored by"}
              icon={
                checkSingleFilterSelected(tempUserFilter) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Alert For"
              value={"Alert For"}
              icon={
                checkSingleFilterSelected(tempAllAlertFor) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Monitor For"
              value={"Monitor For"}
              icon={
                checkSingleFilterSelected(tempAllMonitorFor) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Is Monitored"
              value={"Is Monitored"}
              icon={
                checkSingleFilterSelected(tempAllIsMonitored) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />

            <Tab
              label="Generated on"
              value={"Generated on"}
              icon={
                tempGeneratedOnType ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={value} index={"Account and depot"}>
            <div className="filter_tab_vertical_body">
              <AccountsAndDepotsFilter
                _allAccounts={tempAllAccounts}
                setFuntion={setTempAllAccounts}
              />
            </div>
          </TabPanel>

          <TabPanel value={value} index={"Monitored by"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempUserFilter}
                setFuntion={setTempUserFilter}
                isSearchable={true}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Alert For"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllAlertFor}
                setFuntion={setTempAllAlertFor}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Monitor For"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllMonitorFor}
                setFuntion={setTempAllMonitorFor}
              />
            </div>
          </TabPanel>

          <TabPanel value={value} index={"Is Monitored"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllIsMonitored}
                setFuntion={setTempAllIsMonitored}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Generated on"}>
            <div className="filter_tab_vertical_body">
              <div className="filter-radio-btns">
                <FormControl>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={tempGeneratedOnType}
                    onChange={(e) => setTempGeneratedOnType(e.target.value)}
                    color="black"
                  >
                    <FormControlLabel
                      value="today"
                      control={<Radio />}
                      label="Today"
                    />
                    <FormControlLabel
                      value="Last7days"
                      control={<Radio />}
                      label="Last 7 days"
                    />
                    <FormControlLabel
                      value="Last30days"
                      control={<Radio />}
                      label="Last 30 days"
                    />

                    <FormControlLabel
                      value="custom"
                      control={<Radio />}
                      label="Custom"
                    />
                  </RadioGroup>
                </FormControl>
                {tempGeneratedOnType === "custom" && (
                  <>
                    <div className="custom-date-range">
                      <div className="single-date">
                        <TextField
                          label="From Date"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={customFromDate}
                          onChange={(event) => {
                            setCustomFromDate(event.target.value);
                          }}
                          inputProps={{
                            max: customToDate ? customToDate : null,
                          }}
                        />
                      </div>
                      <div className="single-date">
                        <TextField
                          label="To Date"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={customToDate}
                          onChange={(event) => {
                            setCustomToDate(event.target.value);
                          }}
                          inputProps={{
                            min: customFromDate ? customFromDate : null,
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </TabPanel>
        </Box>

        <div className="filter_action_btn">
          <Controls.Button
            disabled={
              !(
                checkSingleFilterSelected(tempAllAlertFor) ||
                checkDepotSelected(tempAllAccounts) ||
                checkSingleFilterSelected(tempAllMonitorFor) ||
                checkSingleFilterSelected(tempUserFilter) ||
                checkSingleFilterSelected(tempAllIsMonitored) ||
                tempGeneratedOnType
              )
            }
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() =>
              applyFilter(
                tempAllAccounts,
                tempUserFilter,
                tempAllAlertFor,
                tempAllMonitorFor,
                tempAllIsMonitored,
                tempGeneratedOnType
              )
            }
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default CLLMAlertFilter;
