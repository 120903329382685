import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
  Tooltip,
  InputBase,
  FormControl,
  Badge,
} from "@mui/material";
import { isEqual } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import Controls from "../../../../components/controls/Controls";
import LaunchIcon from "@mui/icons-material/Launch";
import useTable from "../../../../components/UseTable";
import { titleLabels } from "../../../../constants/TitleLabels";
import TuneIcon from "@mui/icons-material/Tune";
import { formatDistance, format } from "date-fns";
import no_Data from "./../../../../assets/images/png/Vector.png";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { updateOfflineNotes } from "../../ChargerHealthService";
import Popup from "../../../../components/Popup";
import ExportCSV from "../../../../components/ExportCSV";
import "../../ChargerHealth.scss";
import ActiveFilters from "../../../../components/all-filter-types/ActiveFilters";

const headCells = [
  { key: "accountName", id: "accountName", label: "Account" },
  { key: "depotName", id: "depotName", label: "Depot" },
  {
    key: "vendorManufacturer",
    id: "vendorManufacturer",
    label: "Manufacturer (OEM)",
  },
  { key: "vendorModel", id: "vendorModel", label: "Model (OEM)" },
  { key: "make", id: "make", label: "Sold as manufacturer" },
  { key: "model", id: "model", label: "Sold as model" },
  { key: "chargePortId", id: "chargePortId", label: "ChargerID" },
  { key: "latest_time", id: "latest_time", label: "Last HeartBeat At" },
  { key: "time", id: "time", label: "Offline Time" },
  {
    disableSorting: true,
    key: "notes",
    id: "notes",
    label: "Notes/Ticket details",
  },
  { disableSorting: true, key: "action", id: "action", label: "Action" },
];

const Rows = React.memo((props) => {
  const { item, key, apicall, sendNotes, componentPrivilege } = props;
  const [notes, setNotes] = React.useState(item.notes ? item.notes : "");
  const [originalNotes, setOriginalNotes] = React.useState(
    item.notes ? item.notes : ""
  );
  const handleNotesChange = (event) => {
    if (event.target.value !== " ") setNotes(event.target.value);
  };

  const [editable, setEditable] = React.useState(true);
  return (
    <>
      <TableRow hover={true} className="opentable" id="offlinePortsTable">
        <TableCell>{item.accountName || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.depotName || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {item.vendorManufacturer || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.vendorModel || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.make || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.model || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.chargePortId || titleLabels.NOT_AVAILABLE}</TableCell>

        <TableCell>
          {(
            <>
              {" "}
              {format(
                new Date(`${item.latest_time}`),
                "dd-MMM-yyyy, h.mm a"
              )}{" "}
              UTC{" "}
            </>
          ) || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>
          {formatDistance(
            new Date(new Date(`${item.latest_time}Z`)),
            new Date(),
            { addSuffix: true }
          ) || titleLabels.NOT_AVAILABLE}
        </TableCell>

        <TableCell
          className="notesCell"
          style={{ border: !editable ? "1px solid #0064D4" : "" }}
        >
          <div className="container">
            {item.connectivityId &&
            notes &&
            notes.includes("https") &&
            editable ? (
              <Tooltip title={notes ? notes : ""}>
                <a href={notes} target="_blank" className="link">
                  {notes.slice(-20)}
                </a>
              </Tooltip>
            ) : item.connectivityId ? (
              <FormControl>
                <Tooltip title={notes ? notes : ""}>
                  <InputBase
                    placeholder="Add a note/ticket link"
                    readOnly={editable}
                    size="medium"
                    value={notes}
                    onChange={handleNotesChange}
                    className="notesInputBase"
                  />
                </Tooltip>
              </FormControl>
            ) : (
              "No Status"
            )}
            {!editable && item.connectivityId && (
              <Controls.ActionButton
                onClick={() => {
                  sendNotes(item, notes);
                  setOriginalNotes(notes);
                  setEditable(true);
                }}
              >
                <Tooltip title={"Save"}>
                  <DoneIcon color="primary" className="doneIcon" />
                </Tooltip>
              </Controls.ActionButton>
            )}
            {!editable && item.connectivityId && (
              <Controls.ActionButton
                onClick={() => {
                  setNotes(originalNotes);
                  setEditable(true);
                }}
              >
                <Tooltip title="Discard">
                  <CloseIcon color="error" />
                </Tooltip>
              </Controls.ActionButton>
            )}
            {editable &&
              item.connectivityId &&
              componentPrivilege?.includes(
                "Connectivity - Edit Notes Details"
              ) && (
                <Controls.ActionButton
                  onClick={() => {
                    setEditable(false);
                  }}
                >
                  <Tooltip title="Edit">
                    <EditIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Controls.ActionButton>
              )}
          </div>
        </TableCell>

        <TableCell className="dataDogCell">
          <div>
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                window.open(
                  `https://app.datadoghq.com/logs?query=%40siteId%3A${item.depotId}%20%40chargerId%3A${item.chargePortId}&agg_m=count&agg_m_source=base&agg_t=count&cols=host%2Cservice&fromUser=true&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=desc&viz=stream&live=true`,
                  "_blank"
                );
              }}
            >
              <Tooltip title="Link to DataDog">
                <LaunchIcon fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
});

const ToolbarMemoised = React.memo(
  ({
    handleSearch,
    className,
    toggleDrawer,
    isFiltered,
    setOpenPopup,
    data,
    activeFilters,
    clearAllFilters,
  }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className={className}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Grid sm item />
        <Controls.Button
          text="Export CSV"
          variant="outlined"
          disabled={data.length === 0}
          id="offlinePortsExportCSV"
          onClick={() => {
            setOpenPopup({
              isOpen: true,
              title: "Export CSV",
              child: "exportCSV",
            });
          }}
        />
        <ActiveFilters filters={activeFilters} clearAll={clearAllFilters} />
        <Badge color="primary" variant="dot" invisible={!isFiltered}>
          <Controls.Button
            text="Filter"
            variant="outlined"
            startIcon={
              <TuneIcon style={{ color: isFiltered ? "#2770D8" : "#A3B6C7" }} />
            }
            onClick={() => {
              toggleDrawer(true, "FILTER", {});
            }}
            className="filter"
            style={{
              border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
            }}
          />
        </Badge>
      </Toolbar>
    );
  }
);

const RowsMemoised = React.memo((props) => {
  return <Rows {...props} />;
});

const TableBodyMemoised = React.memo(
  ({ data, callAPI, sendNotes, componentPrivilege }) => {
    return (
      <TableBody>
        {data.map((item, index) => (
          <RowsMemoised
            item={item}
            key={`${item.email}_${index}`}
            callAPI={callAPI}
            sendNotes={sendNotes}
            componentPrivilege={componentPrivilege}
          />
        ))}
      </TableBody>
    );
  },
  function (prevProps, nextProps) {
    if (isEqual(prevProps.data, nextProps.data)) {
      return true;
    }
    return false;
  }
);

const OfflinePortsTable = (props) => {
  const {
    toggleDrawer,
    offLinePorts,
    openDurationFilter,
    selectedFilter,
    isFiltered,
    activeFilters,
    clearAllFilters,
  } = props;

  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const componentPrivilege = useSelector(
    (state) => state?.user?.componentPrivilege?.chargerHealth
  );
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [headers, setHeaders] = useState(
    headCells
      ?.filter((row) => row.id !== "action")
      ?.map((row) => ({ ...row, name: row.label, checked: false }))
  );
  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });

  const sendNotes = (item, notes) => {
    let payload = {
      connectivityId: item.connectivityId,
      depotId: item.depotId,
      chargePortId: item.chargePortId,
      notes: notes,
    };
    updateOfflineNotes(item.connectivityId, payload);
  };
  const result = useTable(offLinePorts, headCells, filterFn, true);

  React.useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  React.useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  React.useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);
  React.useEffect(() => {
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target?.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  return (
    <>
      <ToolbarMemoised
        handleSearch={handleSearch}
        className="searchInput"
        toggleDrawer={toggleDrawer}
        setOpenPopup={setOpenPopup}
        isFiltered={isFiltered}
        data={result?.searchData}
        activeFilters={activeFilters}
        clearAllFilters={clearAllFilters}
      />
      <TableContainer>
        <TableHead />
        <React.Fragment>
          <TableBodyMemoised
            data={recordsAfterPagingAndSorting}
            sendNotes={sendNotes}
            componentPrivilege={componentPrivilege}
          />
        </React.Fragment>
      </TableContainer>
      {recordsAfterPagingAndSorting.length === 0 && (
        <div className="no_data_in_table">
          <div className="img_title">
            <img src={no_Data} alt="No data available" />
            <p className="no_data_title">No data available</p>
          </div>
        </div>
      )}
      {TablePagination}
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {openPopup.child === "exportCSV" ? (
          <ExportCSV
            setOpenPopup={setOpenPopup}
            data={result?.searchData?.map((row) => ({
              ...row,
              latest_time: format(
                new Date(row.latest_time),
                "dd-MM-yyyy hh:mm:ss"
              ),
              time:
                formatDistance(new Date(new Date(`${row.time}Z`)), new Date(), {
                  addSuffix: true,
                }) || titleLabels.NOT_AVAILABLE,
            }))}
            allColumnsHeaders={headCells?.slice(0, -1)}
            headers={headers}
            setHeaders={setHeaders}
            fileName={
              Object.values(selectedFilter).filter((e) => e.length).length !== 0
                ? `${
                    new Date().toISOString().slice(0, 10) +
                    "FPC offline ports overview - filtered.csv"
                  }`
                : `${
                    new Date().toISOString().slice(0, 10) +
                    "FPC offline ports overview.csv"
                  }`
            }
          />
        ) : null}
      </Popup>
    </>
  );
};

export default OfflinePortsTable;
