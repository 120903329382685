import { combineReducers } from "redux";
import {
  chdFilterReducer,
  chdFilterListReducer,
  allAccountsAndDepots,
  allMakeAndModel,
  allCountryCode,
  allDepotType,
  allPowerType,
  allHomeChargingFlag,
} from "./CHDFilterReducer";
import userReducer from "./UserReducer";
import { getAllUsers_Global, getAllRoles_Global } from "./UsersReducer";
import {
  getAllCompaniesV2_Global,
  getAllChargersMeta,
  getAllCompaniesV1_Global,
  getAllDepotsInfo_Global,
  getAllTheDepots_Global,
  getAllServiceApiSignature,
} from "./CommonApiReducer";
import {
  getBillableCustomersCount_Global,
  getBillablePorts_Global,
  getBillingPackage_Global,
  getBillingRatePlans_Global,
} from "./BillingReducer";
getAllTheDepots_Global;
const reducers = combineReducers({
  user: userReducer,
  chdFilter: chdFilterReducer,
  chdOrigList: chdFilterListReducer,
  chdAccounts: allAccountsAndDepots,
  chdMakes: allMakeAndModel,
  chdPowerTypes: allPowerType,
  chdCountryCode: allCountryCode,
  chdDepotType: allDepotType,
  chdHomeChargingFlag: allHomeChargingFlag,
  allUsersGlobal: getAllUsers_Global,
  getAllRoles_Global: getAllRoles_Global,
  getAllCompaniesV2_Global: getAllCompaniesV2_Global,
  getAllChargersMeta: getAllChargersMeta,
  getAllCompaniesV1_Global: getAllCompaniesV1_Global,
  getAllDepotsInfo_Global: getAllDepotsInfo_Global,
  getBillablePorts_Global: getBillablePorts_Global,
  getBillableCustomersCount_Global: getBillableCustomersCount_Global,
  getBillingPackage_Global: getBillingPackage_Global,
  getBillingRatePlans_Global: getBillingRatePlans_Global,
  getAllTheDepots_Global: getAllTheDepots_Global,
  getAllServiceApiSignature: getAllServiceApiSignature,
});

export default reducers;
