export const checkDepotSelected = (allAccounts) => {
  let count = 0;
  allAccounts[0]?.children?.map((child) => {
    child?.depotsList?.map((single) => {
      if (single.checked === true) {
        count += 1;
      }
    });
  });
  if (count === 0) {
    return false;
  } else {
    return true;
  }
};

export const checkChargersSelected = (allChargers) => {
  let count = 0;
  allChargers[0]?.children?.map((child) => {
    child?.chargers?.map((single) => {
      single?.soldAs?.map((singl) => {
        if (singl.checked === true) {
          count += 1;
        }
      });
    });
  });
  if (count === 0) {
    return false;
  } else {
    return true;
  }
};

export const checkNestedFilterSelected = (all) => {
  let count = 0;
  all[0]?.children?.map((child) => {
    child?.children?.map((single) => {
      if (single.checked === true) {
        count += 1;
      }
    });
  });
  if (count === 0) {
    return false;
  } else {
    return true;
  }
};

export const checkSingleFilterSelected = (all) => {
  let count = 0;
  all[0]?.children?.map((child) => {
    if (child?.checked === true) {
      count += 1;
    }
  });
  if (count === 0) {
    return false;
  } else {
    return true;
  }
};

export const checkFilterMultiSelectSelected = (all) => {
  if (all?.length > 0) return true;
  return false;
};
