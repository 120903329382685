import React from "react";
import {
  Grid,
  Typography,
  Tooltip,
  Autocomplete,
  TextField,
} from "@mui/material";
import Controls from "../../../components/controls/Controls";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCard from "../../../components/ContentCard";
import ToastMessage from "../../../components/ToastMessage";
import ComponentLoader from "../../../components/ComponentLoader";
import { checkVINVIDMappingExists } from "../vehicleDiscoveryServices";
import { useVehiclesDiscovery } from "../VehiclesDiscoveryContext";
import "../vehicleDiscovery.scss";

const CheckVINVIDMapping = () => {
  const [depotName, setDepotName] = React.useState(null);
  const [vinNumber, setVinNumber] = React.useState(null);
  const [vinNumberError, setVinNumberError] = React.useState(false);
  const [vinList, setVINList] = React.useState([]);
  const { vehicleDiscoveryImprovementMetrics } = useVehiclesDiscovery();
  const [isLoading, setLoading] = React.useState(false);
  const [vinVidMappingExists, setVinVidMappingExists] = React.useState(null);
  const [selectedDepotsWithVINs, setSelectedDepotsWithVINs] = React.useState(
    []
  );
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  React.useEffect(() => {
    setSelectedDepotsWithVINs(
      vehicleDiscoveryImprovementMetrics.selectedDepotsWithVINs
    );
  }, [vehicleDiscoveryImprovementMetrics]);

  const handleDepotNameChange = (event, newValue) => {
    setDepotName(newValue);
    setVINList(newValue?.vins || []);
    setVinNumber("");
    setVinNumberError(false);
    setVinVidMappingExists(null);
  };

  const handleDepotVINChange = (_, newValue) => {
    setVinNumber(newValue);
    setVinNumberError(false);
    setVinVidMappingExists(null);
  };

  const apiCallToCheckIfVINVIDExists = async () => {
    setVinNumberError(vinNumber?.trim()?.length ? false : true);
    if (!vinNumber?.trim()?.length) return;
    setLoading(true);
    const payload = { vin: vinNumber };
    const res = await checkVINVIDMappingExists(payload);
    setLoading(false);

    if (res.statusCode === 200) {
      setVinVidMappingExists(res.data);
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
  };

  const circularLoader = (
    <div className="checkVinVid_section">
      <div className="checkVinVid_section_loader">
        <ComponentLoader isLoading={isLoading} />
      </div>
    </div>
  );

  const resultFound = (
    <Tooltip title="VIN-VID Mapping Exists">
      <span className="success" style={{ paddingLeft: "10px" }}>
        <CheckCircleIcon fontSize="large" className="successIconColor" />
      </span>
    </Tooltip>
  );

  const resultNotFound = (
    <Tooltip title="VIN-VID Mapping Does Not Exist">
      <span className="error" style={{ paddingLeft: "10px" }}>
        <CancelIcon fontSize="large" className="errorIconColor" />
      </span>
    </Tooltip>
  );

  return (
    <>
      <ContentCard>
        <Typography variant="h5">
          Check if VIN-VID mapping exists for a vehicle in the FPC data catalog
        </Typography>
        <br />
        <Grid
          container
          flexDirection={"row"}
          rowSpacing={0.5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={6}>
            <Autocomplete
              label="Select Depot"
              value={depotName}
              loading={selectedDepotsWithVINs.length === 0}
              name="depotName"
              onChange={handleDepotNameChange}
              options={selectedDepotsWithVINs}
              getOptionLabel={(x) => x.depotName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select Depot"
                  variant="outlined"
                />
              )}
            />
            <br />
            <Autocomplete
              label="Select/Enter VIN"
              freeSolo={true}
              value={vinNumber}
              name="depotVIN"
              onChange={handleDepotVINChange}
              onInputChange={handleDepotVINChange}
              options={vinList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  value={vinNumber}
                  label="Select/Enter VIN"
                  variant="outlined"
                  error={vinNumberError}
                />
              )}
            />

            <br />
            <div className="checkVinVid">
              <Controls.Button
                text="Find"
                data-testid="checkVINBtn"
                onClick={apiCallToCheckIfVINVIDExists}
              />
              {isLoading ? (
                circularLoader
              ) : vinVidMappingExists ? (
                resultFound
              ) : vinVidMappingExists === null ? (
                <></>
              ) : (
                resultNotFound
              )}
            </div>
          </Grid>
        </Grid>
        <ToastMessage toast={toast} setToast={setToast} />
      </ContentCard>
    </>
  );
};

export default CheckVINVIDMapping;
