import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Controls from "../../components/controls/Controls";
import SingleTypeFilter from "../../components/all-filter-types/SingleFilter";
import TabPanel from "../../components/all-filter-types/FilterTabPanel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AccountsAndDepotsFilter from "../../components/all-filter-types/accountAndDepot";
import NestedFilterWithSearch from "../../components/all-filter-types/NestedFilter";
import {
  checkDepotSelected,
  checkNestedFilterSelected,
  checkSingleFilterSelected,
} from "../../components/all-filter-types/isFilteredCheckFunctions";

const AnalyticsFilter = (props) => {
  const {
    countryCode,
    allDepotTypes,
    depotStatus,
    allAccounts,
    allHomeChargingFlag, // this flag will be present only when called from home page
    applyFilter,
  } = props;
  const [value, setValue] = React.useState("Depot Type");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [tempAllDepotType, setTempAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotTypes))
  );
  const [tempAllCountryCode, setTempAllCountryCode] = React.useState(
    JSON.parse(JSON.stringify(countryCode))
  );
  const [tempAllDepotStatus, setTempAllDepotStatus] = React.useState(
    JSON.parse(JSON.stringify(depotStatus))
  );
  const [tempAllAccounts, setTempAllAccounts] = React.useState(
    JSON.parse(JSON.stringify(allAccounts))
  );
  const [tempAllHomeChargingFlag, setTempAllHomeChargingFlag] = React.useState(
    JSON.parse(JSON.stringify(allHomeChargingFlag || []))
  );
  const clearAll = () => {
    if (tempAllDepotType[0].children) {
      tempAllDepotType[0].checked = false;
      tempAllDepotType[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempAllDepotType([...tempAllDepotType]);
    if (tempAllCountryCode[0].children) {
      tempAllCountryCode[0].checked = false;
      tempAllCountryCode[0].children.map((child) => {
        child.checked = false;
        if (child.children) {
          child.children.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    setTempAllCountryCode([...tempAllCountryCode]);
    if (tempAllDepotStatus[0].children) {
      tempAllDepotStatus[0].checked = false;
      tempAllDepotStatus[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempAllDepotStatus([...tempAllDepotStatus]);
    if (tempAllAccounts[0].children) {
      tempAllAccounts[0].checked = false;
      tempAllAccounts[0].children.map((child) => {
        child.checked = false;
        if (child.depotsList) {
          child.depotsList.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    setTempAllAccounts([...tempAllAccounts]);
    if (tempAllHomeChargingFlag?.[0]?.children) {
      tempAllHomeChargingFlag[0].checked = false;
      tempAllHomeChargingFlag[0].children.map((child) => {
        child.checked = false;
      });
    }
    setTempAllHomeChargingFlag([...tempAllHomeChargingFlag]);
  };

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Depot Type"
              value={"Depot Type"}
              icon={
                checkSingleFilterSelected(tempAllDepotType) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Country"
              value={"Country"}
              icon={
                checkNestedFilterSelected(tempAllCountryCode) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Depot Status"
              value={"Depot Status"}
              icon={
                checkSingleFilterSelected(tempAllDepotStatus) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            <Tab
              label="Account and depot"
              value={"Account and depot"}
              icon={
                checkDepotSelected(tempAllAccounts) ? (
                  <FiberManualRecordIcon className="filter_dot" />
                ) : null
              }
              iconPosition="end"
            />
            {allHomeChargingFlag && (
              <Tab
                label="CONFIG FLAGS"
                value={"CONFIG FLAGS"}
                icon={
                  checkSingleFilterSelected(tempAllHomeChargingFlag) ? (
                    <FiberManualRecordIcon className="filter_dot" />
                  ) : null
                }
                iconPosition="end"
              />
            )}
          </Tabs>
          <TabPanel value={value} index={"Depot Type"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotType}
                setFuntion={setTempAllDepotType}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Country"}>
            <div className="filter_tab_vertical_body">
              <NestedFilterWithSearch
                _allData={tempAllCountryCode}
                setFuntion={setTempAllCountryCode}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Depot Status"}>
            <div className="filter_tab_vertical_body">
              <SingleTypeFilter
                _stateVariable={tempAllDepotStatus}
                setFuntion={setTempAllDepotStatus}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={"Account and depot"}>
            <div className="filter_tab_vertical_body">
              <AccountsAndDepotsFilter
                _allAccounts={tempAllAccounts}
                setFuntion={setTempAllAccounts}
              />
            </div>
          </TabPanel>
          {allHomeChargingFlag && (
            <TabPanel value={value} index={"CONFIG FLAGS"}>
              <div className="filter_tab_vertical_body">
                <SingleTypeFilter
                  _stateVariable={tempAllHomeChargingFlag}
                  setFuntion={setTempAllHomeChargingFlag}
                />
              </div>
            </TabPanel>
          )}
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={
              !(
                checkSingleFilterSelected(tempAllDepotType) ||
                checkNestedFilterSelected(tempAllCountryCode) ||
                checkSingleFilterSelected(tempAllDepotStatus) ||
                checkDepotSelected(tempAllAccounts) ||
                (allHomeChargingFlag &&
                  checkSingleFilterSelected(tempAllHomeChargingFlag))
              )
            }
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() =>
              applyFilter(
                tempAllDepotType,
                tempAllCountryCode,
                tempAllDepotStatus,
                tempAllAccounts,
                tempAllHomeChargingFlag
              )
            }
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default AnalyticsFilter;
