import _ from "lodash";
import {
  getAllCompanies,
  getAllTheCompanies,
} from "../../pages/analytics/AnalyticsService";
import { getAllMakeAndModels } from "../../pages/charger-health/ChargerHealthService";
import { getApiSignature } from "../../pages/services/InternalApisService";
import apiDepots from "../../services/api/Depots";

export const saveAllCompaniesV2Global = () => {
  return async (dispatch) => {
    const res = await getAllCompanies();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "GLOBAL_COMPANIES_V2",
        payload: { allCompaniesV2: res.data, isCompaniesLoaded: true },
      });
    } else {
      dispatch({
        type: "GLOBAL_COMPANIES_V2",
        payload: { allCompaniesV2: [], isCompaniesLoaded: true },
      });
    }
  };
};

export const saveAllChargersMetaGlobal = () => {
  return async (dispatch) => {
    const res = await getAllMakeAndModels();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      const sortedData = res?.data?.map((obj) => ({
        ...obj,
        chargers: obj.chargers
          ?.slice()
          .sort((a, b) => a.model.localeCompare(b.model)),
      }));

      dispatch({
        type: "GLOBAL_CHARGERS_META",
        payload: { allChargersMeta: sortedData, isChargersMetaLoaded: true },
      });
    } else {
      dispatch({
        type: "GLOBAL_CHARGERS_META",
        payload: { allChargersMeta: [], isChargersMetaLoaded: true },
      });
    }
  };
};
export const saveAllCompaniesV1Global = () => {
  return async (dispatch) => {
    const res = await apiDepots.getAllCompanies();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "GLOBAL_COMPANIES_V1",
        payload: { allCompaniesV1: res.data, isCompaniesLoadedV1: true },
      });
    } else {
      dispatch({
        type: "GLOBAL_COMPANIES_V1",
        payload: { allCompaniesV1: [], isCompaniesLoadedV1: true },
      });
    }
  };
};

export const saveAllDepotsInfo = () => {
  return async (dispatch) => {
    const res = await apiDepots.getDepotInfo();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "DEPOTS_INFO",
        payload: { allDepotsInfo: res, isDepotsInfoLoaded: true },
      });
    } else {
      dispatch({
        type: "DEPOTS_INFO",
        payload: { allDepotsInfo: [], isDepotsInfoLoaded: true },
      });
    }
    return res;
  };
};

export const saveAllTheDepots = () => {
  return async (dispatch) => {
    const res = await getAllTheCompanies();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "All_THE_DEPOTS",
        payload: { allTheDepotsResponse: res, isAllTheDepotsLoaded: true },
      });
    } else {
      dispatch({
        type: "All_THE_DEPOTS",
        payload: { allTheDepotsResponse: [], isAllTheDepotsLoaded: true },
      });
    }
    return res;
  };
};

export const saveAllServiceApiSignature = () => {
  return async (dispatch) => {
    const res = await getApiSignature();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      dispatch({
        type: "SERVICE_API_SIGNATURE",
        payload: {
          allServiceApiSignature: res.data,
          isAllServiceApiSignatureLoaded: true,
        },
      });
    } else {
      dispatch({
        type: "SERVICE_API_SIGNATURE",
        payload: {
          allServiceApiSignature: [],
          isAllServiceApiSignatureLoaded: true,
        },
      });
    }
  };
};
