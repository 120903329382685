import React from "react";
import { AreaChart, Area } from "recharts";

const MiniChartData1 = [
  {
    name: "A",
    uv: 1000,
  },
  {
    name: "B",
    uv: 1500,
  },
  {
    name: "C",
    uv: 1000,
  },
  {
    name: "D",
    uv: 2000,
  },
  {
    name: "E",
    uv: 1600,
  },
  {
    name: "F",
    uv: 4000,
  },
];

const MiniChartData2 = [
  {
    name: "A",
    uv: 4000,
  },
  {
    name: "B",
    uv: 3000,
  },
  {
    name: "C",
    uv: 3400,
  },
  {
    name: "D",
    uv: 2000,
  },
  {
    name: "E",
    uv: 2300,
  },
  {
    name: "F",
    uv: 1000,
  },
  {
    name: "G",
    uv: 1200,
  },
  {
    name: "H",
    uv: 800,
  },
];

export default function MiniChart({ chartType }) {
  let data, strokeColor, fillColor;
  if (chartType === "up") {
    data = MiniChartData1;
    strokeColor = "#1c7e50";
    fillColor = "#d6f5d6";
  } else {
    data = MiniChartData2;
    strokeColor = "#cc0000";
    fillColor = "#ea9999";
  }
  return (
    <AreaChart
      width={80}
      height={50}
      data={data}
      margin={{
        top: 5,
        right: 0,
        left: 0,
        bottom: 5,
      }}
    >
      <Area
        type="linear"
        dataKey="uv"
        stroke={strokeColor}
        strokeWidth={2}
        fill={fillColor}
      />
    </AreaChart>
  );
}
