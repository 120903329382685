import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useHistory } from "react-router-dom";
import fordLogo from "../assets/images/png/ford-logo-light.png";
import ToastMessage from "../components/ToastMessage";
import "./components-scss/Header.scss";
import Popup from "../components/Popup";
import { removeUser } from "../redux-state/actions/UserAction";
import { clearLocalStorage, getData } from "../utils/Storage";
import { UseForm, Form } from "../components/UseForm";
import { messages } from "../constants/Messages";
import Controls from "../components/controls/Controls";
import { updatePassword } from "../services/api/Users";
import environment from "../../src/environment";
import { Link } from "react-router-dom";
import { variables } from "../constants/Variables";

const FFM_URL = environment[process.env.REACT_APP_NODE_ENV].FFM_URL;
const FDC_URL = environment[process.env.REACT_APP_NODE_ENV].FDC_URL;

const useStyles = makeStyles(() => ({
  // root: {
  //   padding: "5px 10px",
  //   boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)"
  // },
  // logo: {
  //   width: "100%"
  // },
  // badges: {
  //   fill: "#fff"
  // },
  // userInfo: {
  //   padding: "0px 5%",
  //   borderRight: "2px solid #ffffff",
  // },
  // userName: {
  //   lineHeight: "1"
  // }
}));

const ChangePasswordForm = (props) => {
  const classes = useStyles();
  const { setOpenPopup, setToast } = props;
  const initialFieldValues = {
    currentPassword: "",
    password1: "",
    password2: "",
  };
  const userInfo = useSelector((state) => state.user);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("currentPassword" in fieldValues)
      temp.currentPassword =
        fieldValues.currentPassword !== "" ? "" : messages.FIELD_REQUIRED;
    if ("password1" in fieldValues)
      temp.password1 =
        fieldValues.password1 !== "" ? "" : messages.FIELD_REQUIRED;
    if ("password2" in fieldValues)
      temp.password2 =
        fieldValues.password2 !== "" ? "" : messages.FIELD_REQUIRED;

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const validatePasswordMatch = (fieldValues = values) => {
    const passwordMatchError =
      fieldValues.password2 === fieldValues.password1
        ? ""
        : messages.PASSWORD_MISSMATCH;
    setErrors({ password2: passwordMatchError });
    if (fieldValues === values) return passwordMatchError === "";
    return false;
  };

  const { values, errors, setErrors, handleFormInputChange, resetForm } =
    UseForm(initialFieldValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && validatePasswordMatch()) {
      const payload = {
        username: userInfo.userEmail,
        currentPassword: values.currentPassword,
        newPassword: values.password2,
      };
      const res = await updatePassword(payload);
      if (res?.statusCode === 200 || res?.statusCode === 201) {
        setToast({
          isOpen: true,
          message: "Password Updated Successfully",
          type: "success",
        });
        setOpenPopup((prevState) => ({ ...prevState, isOpen: false }));
      } else {
        if (res?.statusCode === 403) {
          setToast({
            isOpen: true,
            message: "Incorrect Currrent Password",
            type: "error",
          });
        } else {
          setToast({
            isOpen: true,
            message: " Something went wrong",
            type: "error",
          });
        }
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid>
          <Controls.Input
            name="currentPassword"
            label="Current Password"
            value={values.currentPassword}
            onChange={handleFormInputChange()}
            error={errors.currentPassword}
            type="password"
            autocomplete="current-password"
          />
          <Controls.Input
            name="password1"
            label="New Password"
            value={values.password1}
            onChange={handleFormInputChange()}
            error={errors.password1}
            type="password"
          />
          <Controls.Input
            name="password2"
            label="Re-enter New Password"
            value={values.password2}
            onChange={handleFormInputChange()}
            error={errors.password2}
            type="password"
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <Grid item xs={5}>
          <div>
            <Controls.Button type="submit" text={"Update Password"} />
          </div>
        </Grid>
        {/* <Grid item xs={6}>
          <div>
            <Controls.Button color="primary" text="Reset" onClick={resetForm} />
          </div>
        </Grid> */}
      </Grid>
    </Form>
  );
};

const Header = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "Change Password",
  });
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserSettings = () => {
    setAnchorEl(null);
    history.push("/userAlertSettings");
  };

  const handleChangePassword = () => {
    setAnchorEl(null);
    setOpenPopup({ ...openPopup, isOpen: true });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      clearLocalStorage();
      dispatch(removeUser());
      history.push("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <div className="header">
      <AppBar position="fixed">
        <Grid container alignItems="center">
          <Grid item xs={7} sm={5} md={2}>
            <Link to="/">
              <img src={fordLogo} alt="Ford Logo" className="logo" />
            </Link>
          </Grid>
          <Grid xs item />
          <Grid item>
            <IconButton onClick={handleClick} size="large">
              <AccountCircle fontSize="default" style={{ color: "white" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className="menu"
        >
          <MenuItem disabled className="menuUserName">
            {userInfo.userFirstName + " " + userInfo.userLastName}
          </MenuItem>
          <MenuItem disabled className="menuUserRole">
            {userInfo.userRoleName}
          </MenuItem>
          <MenuItem onClick={handleUserSettings}>User Settings</MenuItem>
          <Divider />
          <MenuItem href={FFM_URL} target="_blank" component="a">
            FFM
          </MenuItem>
          <MenuItem href={FDC_URL} target="_blank" component="a">
            FDC
          </MenuItem>
          <Divider />
          <MenuItem onClick={props.handleLogOut}>Logout</MenuItem>
        </Menu>
      </AppBar>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <ChangePasswordForm setOpenPopup={setOpenPopup} setToast={setToast} />
      </Popup>
      <ToastMessage toast={toast} setToast={setToast} />
    </div>
  );
};

export default Header;
