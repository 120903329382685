import React from "react";
import { Grid } from "@mui/material";
import ContentCard from "../../../components/ContentCard";
import VehicleDiscoveryChartInsights from "./chart";
import VehicleDiscoveryPieChartInsights from "./pie-chart";
import DataTable from "./table/DataTable";
import Loader from "../../../components/Loader";
import { useVehiclesDiscovery } from "../VehiclesDiscoveryContext";
import "../vehicleDiscovery.scss";
import {
  INSIGHTS_TABLE_HEADERS_1,
  INSIGHTS_COLUMN_HEADERS_1,
  INSIGHTS_TABLE_HEAD_CELLS_1,
  INSIGHTS_COLUMN_HEADERS_2,
  INSIGHTS_TABLE_HEADERS_2,
  INSIGHTS_TABLE_HEAD_CELLS_2,
} from "../constants";
const Insights = () => {
  const { isLoading, vehicleDiscoveryMetrics, fromDate, toDate } =
    useVehiclesDiscovery();

  return (
    <>
      {<Loader isLoading={isLoading} />}
      <Grid
        container
        flexDirection={"row"}
        rowSpacing={0.5}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={6}>
          <ContentCard>
            <VehicleDiscoveryChartInsights
              chartName={"averageSuccessPercentage"}
            />
          </ContentCard>
        </Grid>
        <Grid item xs={6}>
          <ContentCard>
            <VehicleDiscoveryChartInsights
              chartName={"averageNumberOfDiscoveryAttempts"}
            />
          </ContentCard>
        </Grid>
        <Grid item xs={6}>
          <ContentCard>
            <VehicleDiscoveryPieChartInsights chartName={"vehicleInsights"} />
          </ContentCard>
        </Grid>
        <Grid item xs={6}>
          <ContentCard>
            <VehicleDiscoveryPieChartInsights chartName={"chargerInsights"} />
          </ContentCard>
        </Grid>
        <Grid item xs={6}>
          <DataTable
            headerClassName="monthlyChargerUtilization"
            headerTitle="Depot success rates"
            insightsTableHeaders={INSIGHTS_TABLE_HEADERS_1}
            insightsTableColumnHeaders={INSIGHTS_COLUMN_HEADERS_1}
            insightsTableHeaderCells={INSIGHTS_TABLE_HEAD_CELLS_1}
            isDepotSuccessRateTable={true}
            vehicleDiscoveryMetrics={vehicleDiscoveryMetrics}
            fromDate={fromDate}
            toDate={toDate}
            fileName="Depot-Vehicle-Discovery-Success-Rate.csv"
          />
        </Grid>
        <Grid item xs={6}>
          <DataTable
            headerClassName="modelSuccessTitle"
            headerTitle="Model success rates"
            insightsTableHeaders={INSIGHTS_TABLE_HEADERS_2}
            insightsTableColumnHeaders={INSIGHTS_COLUMN_HEADERS_2}
            insightsTableHeaderCells={INSIGHTS_TABLE_HEAD_CELLS_2}
            isModelSuccessRateTable={true}
            vehicleDiscoveryMetrics={vehicleDiscoveryMetrics}
            fromDate={fromDate}
            toDate={toDate}
            fileName="Model-Vehicle-Discovery-Success-Rate.csv"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Insights;
