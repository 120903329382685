export const saveCHDFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: "CHD_FILTER", payload: filter });
  };
};

export const saveCHDFilterList = (list, name) => {
  return (dispatch) => {
    dispatch({ type: "CHD_FILTER_LIST", payload: list, name });
  };
};

export const saveAllAccountsAndDepots = (list) => {
  return (dispatch) => {
    dispatch({ type: "CHD_FILTER_ALL_ACCOUNTS_DEPOTS", payload: list });
  };
};

export const saveAllMakeAndModels = (list) => {
  return (dispatch) => {
    dispatch({ type: "CHD_FILTER_ALL_MAKE_MODELS", payload: list });
  };
};

export const saveAllPowerTypes = (list) => {
  return (dispatch) => {
    dispatch({ type: "CHD_FILTER_ALL_POWER_TYPES", payload: list });
  };
};

export const saveAllDepotTypes = (list) => {
  return (dispatch) => {
    dispatch({ type: "CHD_FILTER_ALL_DEPOT_TYPE", payload: list });
  };
};

export const saveAllCountryCodes = (list) => {
  return (dispatch) => {
    dispatch({ type: "CHD_FILTER_ALL_COUNTRY_CODE", payload: list });
  };
};

export const saveAllHomeChargingFlag = (list) => {
  return (dispatch) => {
    dispatch({ type: "CHD_FILTER_HOME_CHARGING_FLAG", payload: list });
  };
};
