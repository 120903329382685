import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import {
  Chip,
  Grid,
  InputAdornment,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import PageMainContent from "../../../components/PageMainContent";
import { Link, useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Popup from "../../../components/Popup";
import AddEditErrorCodeGroups from "./AddEditErrorCodeGroups";
import ToastMessage from "../../../components/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../../redux-state/actions";
import environment from "../../../environment";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../../../components/ConfirmDialog";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const AggErrorCodes = () => {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const [chargersMeta, setChargersMeta] = useState([]);
  const [refreshECGroups, setRefreshECGroups] = useState(true);
  const [toast, setToast] = useState("");
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "Add Error Code Group",
    child: "",
    item: {},
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const { allChargersMeta, isChargersMetaLoaded } = useSelector(
    (state) => state.getAllChargersMeta
  );
  const dispatch = useDispatch();
  const { saveAllChargersMetaGlobal } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const history = useHistory();
  const classes = useStyles();

  const headCells = useMemo(
    () => [
      {
        id: "groupName",
        label: "Group name",
        render: (row, col) => (
          <Link to={`${routePaths.ERROR_CODES}?groupId=${row.groupId}`}>
            {row[col]}
          </Link>
        ),
      },
      { id: "vendorManufacturer", label: "Manufacturer (OEM)" },
      {
        id: "chargerModels",
        label: "Model(s) (OEM)",
        render: (row, col) => (
          <div>
            {row[col]?.slice(0, 2)?.map((item) => (
              <Chip label={`${item}`} className="soldAsMakeModel" />
            ))}
            {row[col]?.length > 2 && (
              <Chip
                label={`+${row[col]?.length - 2}`}
                className="soldAsMakeModel"
              />
            )}
          </div>
        ),
      },
      { id: "errorcodesCount", label: "Error codes count" },
      {
        disableSorting: true,
        id: "actions",
        label: "Action",
        render: (row, col, ind) => (
          <div className="roleTableActions">
            <PrivilegedComponent
              permission="Manage groups"
              module="dataCatalogErrorCode"
            >
              {/* <Controls.ActionButton //commented for Feb 24 release
                color="primary"
                onClick={() => handleEditECGroup(row)}
                data-testid={`editGroupBtn${ind}`}
              >
                <Tooltip arrow TransitionComponent={Zoom} title="Update group">
                  <EditOutlined fontSize="small" />
                </Tooltip>
              </Controls.ActionButton> */}
              <Controls.ActionButton
                color="primary"
                disabled={row?.groupName === "Generic"}
                onClick={() => handleDeleteECGroup(row)}
                data-testid={`deleteGroupBtn${ind}`}
              >
                <Tooltip arrow TransitionComponent={Zoom} title="Delete group">
                  <DeleteIcon fontSize="small" />
                </Tooltip>
              </Controls.ActionButton>
            </PrivilegedComponent>
          </div>
        ),
      },
    ],
    []
  );

  const { ERROR_CODE_GROUPS } = indexedEndPoints;

  useEffect(() => {
    if (isChargersMetaLoaded === false) {
      saveAllChargersMetaGlobal();
    }
    setChargersMeta(JSON.parse(JSON.stringify(allChargersMeta)));
  }, [isChargersMetaLoaded]);

  useEffect(() => {
    const fetchErrorCodeGroups = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(ERROR_CODE_GROUPS);
      setLoading(false);
      setData(response?.data?.body);
      setRefreshECGroups(false);
    };
    refreshECGroups && fetchErrorCodeGroups();
  }, [refreshECGroups]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const handleEditECGroup = (row) => {
    setOpenPopup({
      isOpen: true,
      title: "Edit group",
      child: "updateErrorCodeGroup",
      item: row,
    });
  };

  const handleDeleteECGroup = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure you want to delete this group?",
      subTitle:
        "Deleting this group will re-assign all of its associated chargers to the 'Generic' group.",
      type: "secondary",
      onConfirm: () => deleteErrorCodeGroup(row.groupId),
    });
  };

  const deleteErrorCodeGroup = useCallback(async (groupId) => {
    setLoading(true);
    const response = await apiProvider.del(`${ERROR_CODE_GROUPS}/${groupId}`);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Error code group deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshECGroups(true);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, []);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn);

  const ToolbarMemoised = useCallback(({ handleSearch, classes }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className="searchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Grid sm />
        <PrivilegedComponent
          permission="Manage groups"
          module="dataCatalogErrorCode"
        >
          <Controls.Button
            text="Create Group"
            variant="outlined"
            testid="addErrorCodeGrpBtn"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Add Error Code Group",
                child: "addErrorCodeGroup",
                item: {},
              })
            }
          />
        </PrivilegedComponent>
      </Toolbar>
    );
  }, []);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Error code groups"
      />
      <PageHeader title="Error code groups" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <AddEditErrorCodeGroups
          type={openPopup?.child}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setRefreshECGroups={setRefreshECGroups}
          chargersMeta={chargersMeta}
          setToast={setToast}
        />
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        buttonsPosition="reverse"
        primaryBtnText="Delete"
        secondaryBtnText="Cancel"
      />
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

export default AggErrorCodes;
