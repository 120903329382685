import * as React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Controls from "../../components/controls/Controls";
import AccountsAndDepotsFilter from "../../components/all-filter-types/accountAndDepot";
import TabPanel from "../../components/all-filter-types/FilterTabPanel";
import { checkDepotSelected } from "../../components/all-filter-types/isFilteredCheckFunctions";

const AccountSelection = (props) => {
  const {
    allAccounts,
    setAllAcconts,
    messageText,
    applyFilter,
    clearAll,
    extraFilter,
  } = props;

  const [value, setValue] = React.useState("Account and depot");
  return (
    <>
      <div className="filters_with_vertical_tab" style={{ width: "550px" }}>
        {messageText && (
          <div className="chargerDerateBanner">
            <InfoIcon fontSize="small" className="leftInfoIcon" />
            <p>{messageText}</p>
          </div>
        )}
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <TabPanel value={value} index={"Account and depot"}>
            <div className="filter_tab_vertical_body">
              <AccountsAndDepotsFilter
                _allAccounts={allAccounts}
                setFuntion={setAllAcconts}
                extraFilter={extraFilter}
              />
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={!checkDepotSelected(allAccounts)}
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Select"
            color="primary"
            onClick={() => applyFilter()}
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};

export default AccountSelection;
