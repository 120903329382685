import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import PageMainContent from "../../../components/PageMainContent";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
];

const useStyles = makeStyles(() => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const headCells = [
  { id: "manufacturer", label: "Manufacturer" },
  { id: "count", label: "Vehicles Count" },
];

const VehiclesIndex = () => {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [refreshVehicles, setRefreshVehicles] = useState(true);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const onTableRowClick = (row) => {
    history.push({
      pathname: routePaths.VEHICLES,
      search: `?manufacturer=${encodeURIComponent(row.manufacturer)}`,
    });
  };

  const { GET_VEHICLES } = indexedEndPoints;

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(GET_VEHICLES);
      setLoading(false);
      setData(response?.data);
      setRefreshVehicles(false);
    };
    refreshVehicles && fetchVehicles();
  }, [refreshVehicles]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn, false, false, null, onTableRowClick);

  const ToolbarMemoised = useCallback(({ handleSearch }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className="searchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Toolbar>
    );
  }, []);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Vehicles Dashboard"
      />
      <PageHeader title="Vehicles" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
    </>
  );
};

export default VehiclesIndex;
