import React from "react";
import Controls from "../controls/Controls";
import CloseIcon from "@mui/icons-material/Close";

export default function ActiveFilters({ filters, clearAll }) {
  return (
    <div className="activeFiltersContainer">
      {filters.map(({ name, onClick }, index) => (
        <div className="activeFilters" key={index}>
          <p>{name}</p>
          <CloseIcon className="closeIcon" onClick={() => onClick(index)} />
        </div>
      ))}
      {filters?.length > 0 && (
        <Controls.ActionButton
          onClick={clearAll}
          className="clearActiveFiltersBtn"
        >
          Clear All
        </Controls.ActionButton>
      )}
    </div>
  );
}
