import React, { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import ProctectedRoute from "./ProtectedRoute";
import { CssBaseline, Drawer, IconButton, Divider } from "@mui/material";
import { ThemeProvider, makeStyles } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";
import { getData } from "../utils/Storage";
import { variables } from "../constants/Variables";
import { addUser, removeUser } from "../redux-state/actions/UserAction";
import { routePaths } from "../constants/RoutePaths";
import { clearLocalStorage } from "../utils/Storage";

import SideBar from "../components/SideBar";
import Header from "../components/Header";

const billing = lazy(() => import("../pages/billing/index"));
const users = lazy(() => import("../pages/users/index"));
const Requests = lazy(() => import("../pages/request/index"));
const Companies = lazy(() => import("../pages/companies"));
const usageAnalytics = lazy(() => import("../pages/usage-analytics/index"));
import ReviewConfirm from "../pages/request/review-confirm/index";
import CircuitMonitor from "../pages/circuit-monitor/index";
import CompanyDepotDetails from "../pages/companies/CompanyDepotDetails";
import BillingCompanyDepot from "../pages/billing/BillingCompanyDepots";
import Analytics from "../pages/analytics/index";
const ChargerHealthDashboard = lazy(() => import("../pages/charger-health"));
const InternalApis = lazy(() => import("../pages/services"));
const PreProvisioningMain = lazy(() => import("../pages/pre-provisioning"));
import BillingHistoryTable from "../pages/billing/BillingHistoryTable";
import NotFound from "../components/NotFound";
import "./../App.scss";
const InternalApisHistory = lazy(() =>
  import("../pages/services/InternalApisHistory")
);
import FaultsOpenFaults from "../pages/charger-health/charger-health-modules/FaultsOpenFaults";
import ConnectivityOfflinePorts from "../pages/charger-health/charger-health-modules/ConnectivityOfflinePorts";
const Reports = lazy(() => import("../pages/reports/index"));
import ReportsHistory from "../pages/reports/reports-history/ReportsHistory";
import VehicleDiscovery from "../pages/vehicleDiscovery";
import DepotDetailsPage from "../pages/vehicleDiscovery/depot";
import DataCatalog from "../pages/data-catalog/DataCatalog";
import ErrorCodes from "../pages/data-catalog/errorCodes/ErrorCodes";
import AggErrorCodes from "../pages/data-catalog/errorCodes/AggErrorCodes";
import BulkUploadErrorCodes from "../pages/data-catalog/errorCodes/BulkUploadErrorCodes";
import VinVidMapping from "../pages/data-catalog/vinVidMapping/VinVidMapping";
import BulkUploadVinVidMappings from "../pages/data-catalog/vinVidMapping/BulkUploadVinVidMappings";
import Chargers from "../pages/data-catalog/chargers/Chargers";
import AggChargers from "../pages/data-catalog/chargers/AggChargers";
import ChargerFirmwareDetials from "../pages/charger-health/charger-firmware-details";
import UserAlertSettings from "../pages/users/UserAlertSettings";
import UtilityProvider from "../pages/data-catalog/utilityRates/UtilityProvider";
import UtilityPlan from "../pages/data-catalog/utilityRates/UtilityPlan";
import UtilityPlanSearch from "../pages/data-catalog/utilityRates/UtilityPlanSeach";
import VehiclesIndex from "../pages/data-catalog/vehicles/vehiclesIndex";
import Vehicles from "../pages/data-catalog/vehicles/Vehicles";
import BulkUploadVehicles from "../pages/data-catalog/vehicles/BulkUploadVehicles";
import ChargerHistory from "../pages/charger-health/charger-firmware-details/ChargerHistory";
import ChargerReplacementHistory from "../pages/charger-health/charger-firmware-details/ChargerReplacementHistory";
import RolesModules from "../pages/configurations/RolesModules";
import ScheduledServices from "../pages/services/ScheduledServices";
import DataCatalogHistory from "../pages/data-catalog/DataCatalogHistory";
import CLLMAlertTable from "../pages/circuit-monitor/alert-log-table";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalProvider,
} from "@azure/msal-react";
import { loginRequest } from "../auth-config";
import NewLogin from "../pages/login/loginButtonPage";
import { EventType } from "@azure/msal-browser";
import apiUsers from "../services/api/Users";
import AlertDialog from "../components/NewAlertDialog";
import {
  apiProvider,
  indexedEndPoints,
} from "../services/api/utilities/provider";

const DatasyncEvents = lazy(() => import("../pages/datasyncEvents/index"));

const drawerWidth = 240;
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "red",
  },
  drawerOpen: {
    width: drawerWidth,
    marginTop: "70px",
    borderRadius: "4px",
    background: "linear-gradient(135deg, #002F6C 0%, #17498F 100%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "linear-gradient(135deg, #002F6C 0%, #17498F 100%)",
    overflowX: "hidden",
    marginTop: "70px",
    borderRadius: "4px",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginTop: "70px",
    overflowX: "auto",
    height: "calc(100vh - 70px)",
    width: `calc(100% - 73px)`,
  },
  shrink: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const AppContent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [alertConfirmDialog, setAlertConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const handleDrawerAll = () => {
    setOpen(!open);
  };

  const { instance } = useMsal();
  let activeAccount = instance.getActiveAccount;
  useEffect(() => {
    const eventHandler = instance.addEventCallback((event) => {
      if (
        EventType.LOGIN_SUCCESS === event.eventType &&
        event.payload?.accessToken &&
        event.payload?.idTokenClaims?.roles
      ) {
        const { idTokenClaims } = event.payload;
        const { name, preferred_username, roles } = idTokenClaims;
        localStorage.setItem("user_first_name", name.split(", ")[1]);
        localStorage.setItem("user_last_name", name.split(", ")[0]);
        localStorage.setItem("user_email", preferred_username);
        localStorage.setItem("user_role_id", roles[0].split(".")[1]);
        const userRoleID = [roles[0].split(".")[1]];

        const getRolesPre = async () => {
          const rolePrivilegeResponse = await apiUsers.roleAllPrivilege(
            userRoleID
          );
          if (rolePrivilegeResponse.code === 200) {
            const { data } = rolePrivilegeResponse;
            let roleData = data;
            localStorage.setItem("user_role", roleData.name);
            localStorage.setItem(
              variables.USER_COMPONENT_PRIVILEGE,
              JSON.stringify(roleData.componentPrivilege)
            );
            localStorage.setItem(
              variables.USER_ROLE_PRIVILEGE,
              JSON.stringify(roleData.rolePrivilege)
            );

            dispatch(
              addUser({
                userFirstName: name.split(", ")[1],
                userLastName: name.split(", ")[0],
                userEmail: preferred_username,
                userRoleName: getData(variables.USER_ROLE),
                userRole: getData(variables.USER_ROLE_ID), // Change Here
                componentPrivilege: roleData.componentPrivilege,
                rolePrivilege: roleData.rolePrivilege,
              })
            );
          } else {
            clearLocalStorage();
            dispatch(removeUser());
            instance.logout();
          }
        };

        const getUserPreferences = async () => {
          const { USER_ALERT_SETTINGS } = indexedEndPoints;
          const response = await apiProvider.getAll(
            USER_ALERT_SETTINGS.replace("email", preferred_username)
          );
          if (response?.statusCode === 200) {
            const { preferredDepotsList = [] } = response?.data?.notifications;
            localStorage.setItem(
              variables.USER_PREFERRED_DEPOTS,
              preferredDepotsList
            );
          }
        };

        getRolesPre();
        getUserPreferences();
      }
      if (EventType.LOGIN_SUCCESS === event.eventType) {
        if (!event.payload?.idTokenClaims?.roles) {
          setAlertConfirmDialog({
            isOpen: true,
            title: "It appears that you do not have access to OpsConsole.",
            subTitle:
              "Please contact the support team to obtain access. You will be automatically redirected to the login page in 5 seconds.",
            type: "secondary",
          });
        }
      }
    });

    return () => instance.removeEventCallback(eventHandler);
  }, []);

  React.useEffect(() => {
    if (getData(variables.USER_ROLE_PRIVILEGE)) {
      dispatch(
        addUser({
          userFirstName: getData(variables.USER_FIRST_NAME),
          userLastName: getData(variables.USER_LAST_NAME),
          userEmail: getData(variables.USER_EMAIL),
          userRoleName: getData(variables.USER_ROLE),
          userRole: getData(variables.USER_ROLE_ID),
          componentPrivilege: JSON.parse(
            getData(variables.USER_COMPONENT_PRIVILEGE)
          ),
          rolePrivilege: JSON.parse(getData(variables.USER_ROLE_PRIVILEGE)),
        })
      );
    } else {
      clearLocalStorage();
      dispatch(removeUser());
    }
  }, [dispatch]);

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
      })

      .catch((error) => {
        console.log("An error occurred:", error);
      });
  };

  const handleLogOut = () => {
    clearLocalStorage();
    dispatch(removeUser());
    instance.logout();
  };

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <>
            <Header handleLogOut={handleLogOut} />

            <div className={classes.root}>
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                <div className={classes.toolbar}>
                  <IconButton onClick={handleDrawerAll} size="large">
                    {open === true ? (
                      <ChevronLeftIcon style={{ color: "white" }} />
                    ) : (
                      <ChevronRightIcon style={{ color: "white" }} />
                    )}
                  </IconButton>
                </div>
                <Divider style={{ background: "white" }} />
                <SideBar />
              </Drawer>
              <main
                className={`${classes.content} ${open ? classes.shrink : ""}`}
              >
                <Switch>
                  <ProctectedRoute exact path="/" component={Analytics} />
                  <ProctectedRoute exact path="/home" component={Analytics} />
                  <ProctectedRoute
                    path={routePaths.USER_ALERT_SETTINGS}
                    component={UserAlertSettings}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.CHARGERHEALTH}
                    component={ChargerHealthDashboard}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.OPENFAULTS}
                    component={FaultsOpenFaults}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.OFFLINEPORTS}
                    component={ConnectivityOfflinePorts}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.CHARGERFIRMWAREDETAILS}
                    component={ChargerFirmwareDetials}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.CHARGER_HISTORY}
                    component={ChargerHistory}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.CHARGER_REPLACEMENT_HISTORY}
                    component={ChargerReplacementHistory}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.USAGE_ANALYTICS}
                    component={usageAnalytics}
                  />
                  <ProctectedRoute
                    path={routePaths.CIRCUITMONITOR}
                    component={CircuitMonitor}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.CLLMALERTLOG}
                    component={CLLMAlertTable}
                  />
                  <ProctectedRoute path={routePaths.USERS} component={users} />
                  <ProctectedRoute
                    exact
                    path={routePaths.REQUESTS}
                    component={Requests}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.REVIEWCONFIRM}
                    component={ReviewConfirm}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.COMPANIES}
                    component={Companies}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.COMPANYDEPOTDETAILS}
                    component={CompanyDepotDetails}
                  />
                  <ProctectedRoute
                    path={routePaths.BILLING}
                    exact
                    component={billing}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.BILLINGDEPOTDETAILS}
                    component={BillingCompanyDepot}
                  />
                  <ProctectedRoute
                    path={routePaths.BILLING_HISTORY}
                    exact
                    component={BillingHistoryTable}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.SERVICES}
                    component={InternalApis}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.SERVICES_SCHEDULED}
                    component={ScheduledServices}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.SERVICES_HISTORY}
                    component={InternalApisHistory}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.PREPROVISIONING}
                    component={PreProvisioningMain}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.REPORTS}
                    component={Reports}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.REPORTS_HISTORY}
                    component={ReportsHistory}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.DATA_CATALOG}
                    component={DataCatalog}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.DATA_CATALOG_HISTORY}
                    component={DataCatalogHistory}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.ERROR_CODES_DASHBOARD}
                    component={AggErrorCodes}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.ERROR_CODES}
                    component={ErrorCodes}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.BULK_UPLOAD_ERROR_CODES}
                    component={BulkUploadErrorCodes}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.CHARGERS_DASHBOARD}
                    component={AggChargers}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.CHARGERS}
                    component={Chargers}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.VIN_VID_MAPPING}
                    component={VinVidMapping}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.BULK_UPLOAD_VIN_VID_MAPPINGS}
                    component={BulkUploadVinVidMappings}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.UTILITY_RATES}
                    component={UtilityProvider}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.UTILITY_PROVIDER_PLANS}
                    component={UtilityPlan}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.UTILITY_PLAN_SEARCH}
                    component={UtilityPlanSearch}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.VEHICLES_DASHBOARD}
                    component={VehiclesIndex}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.VEHICLES}
                    component={Vehicles}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.BULK_UPLOAD_VEHICLES}
                    component={BulkUploadVehicles}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.VEHICLES_DISCOVERY}
                    component={VehicleDiscovery}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.VEHICLES_DISCOVERY_DEPOT_METRICS}
                    component={DepotDetailsPage}
                  />
                  <ProctectedRoute
                    exact
                    path={routePaths.DATASYNC_EVENTS}
                    component={DatasyncEvents}
                  />
                  <ProctectedRoute
                    path={routePaths.CONFIGURATIONS}
                    component={RolesModules}
                  />
                  <Route component={NotFound} />
                </Switch>
              </main>
            </div>
          </>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <NewLogin handleRedirect={handleRedirect} />
      </UnauthenticatedTemplate>
      {alertConfirmDialog.isOpen && (
        <AlertDialog
          alertConfirmDialog={alertConfirmDialog}
          setAlertConfirmDialog={setAlertConfirmDialog}
        />
      )}
    </>
  );
};

const App = ({ instance }) => {
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={instance}>
            <AppContent />
          </MsalProvider>
        </ThemeProvider>
      </StyledEngineProvider>
      <CssBaseline />
    </>
  );
};
export default App;
