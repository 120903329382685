import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, InputAdornment, Toolbar, Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import useTable from "../../../components/UseTable";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Popup from "../../../components/Popup";
import AddEditVinVidMapping from "./AddEditVinVidMapping";
import ToastMessage from "../../../components/ToastMessage";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
];

const headCells = [
  { id: "vin", label: "VIN", styles: { padding: "6px" } },
  {
    id: "vids",
    label: "VIDs",
    render: (row, col) =>
      Array.isArray(row[col]) && row[col]?.length > 0 ? row[col][1] : "",
    styles: { padding: "6px" },
  },
  { id: "modelYear", label: "Model Year", styles: { padding: "6px" } },
  { id: "obccESN", label: "Obcc ESN", styles: { padding: "6px" } },
  { id: "obccMacAddress", label: "Obcc MAC", styles: { padding: "6px" } },
  { id: "programCode", label: "Program Code", styles: { padding: "6px" } },
  { id: "region", label: "Region", styles: { padding: "6px" } },
];

const VinVidMapping = () => {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const [refreshVinVidMappings, setRefreshVinVidMappings] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [searchedString, setSearchedString] = useState("");
  const history = useHistory();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const componentPrivileges = useSelector(
    (state) => state?.user?.componentPrivilege?.dataCatalogVinVidMapping
  );
  const classes = useStyles();

  const { GET_VIN_VID_MAPPING, VIN_VID } = indexedEndPoints;

  const handleSearch = (e) => {
    setSearchedString(e.target.value?.trim());
    if (e.target.value === "") page !== 0 ? setPage(0) : fetchVinVidMappings();
  };
  const searchApiCallFunction = () => {
    fetchVinVidMappings(searchedString);
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
    page,
    setPage,
    rowsPerPage,
    orderBy,
    order,
  } = useTable(
    data,
    headCells,
    filterFn,
    false,
    true,
    null,
    null,
    totalCount,
    true
  );
  const fetchVinVidMappings = async (search = "") => {
    setLoading(true);

    let payload = `?page=${page}&count=${rowsPerPage}`;

    if (orderBy && order) {
      payload += `&orderBy=${orderBy}&order=${order == "asc" ? "1" : "-1"}`;
    }
    if (search !== "")
      payload += `${searchedString !== "" ? `&search=${searchedString}` : ``}`;
    const response = await apiProvider.getAll(
      `${GET_VIN_VID_MAPPING}${payload}`
    );
    setLoading(false);
    setTotalCount(response?.data?.count);
    const filteredData =
      response?.data?.data?.filter(
        (row) => row.vin?.length > 0 && row.vids?.length > 0
      ) || [];
    setData(filteredData);
    setRefreshVinVidMappings(false);
  };
  useEffect(() => {
    refreshVinVidMappings && fetchVinVidMappings(searchedString);
  }, [refreshVinVidMappings]);
  useEffect(() => {
    setRefreshVinVidMappings(true);
  }, [page, rowsPerPage, orderBy, order]);
  const ToolbarMemoised = useCallback(
    ({ handleSearch, searchApiCallFunction, page, setPage }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className="searchInput"
            testid="inputSearchVinVidBtn"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                page !== 0 ? setPage(0) : searchApiCallFunction();
              }
            }}
          />
          <Controls.Button
            type="button"
            text="Search"
            data-testid="searchVinVidBtn"
            size="small"
            onClick={() => (page !== 0 ? setPage(0) : searchApiCallFunction())}
          />
          <Grid sm item />
          <PrivilegedComponent
            permission="Bulk Upload"
            module="dataCatalogVinVidMapping"
          >
            <Controls.Button
              text="Bulk Upload"
              variant="outlined"
              startIcon={<ArrowUpwardIcon />}
              onClick={() =>
                history.push(routePaths.BULK_UPLOAD_VIN_VID_MAPPINGS)
              }
            />
          </PrivilegedComponent>
          <PrivilegedComponent
            permission="Add VIN-VID Mapping"
            module="dataCatalogVinVidMapping"
          >
            <Controls.Button
              text="Add VIN-VID Mapping"
              data-testid="addVinVidBtn"
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() =>
                setOpenPopup({
                  isOpen: true,
                  title: "Add VIN-VID Mapping",
                  child: "addVinVidMapping",
                  item: {},
                })
              }
            />
          </PrivilegedComponent>
        </Toolbar>
      );
    },
    []
  );

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="VIN-VID Mapping"
      />
      <PageHeader title="VIN-VID Mapping" />
      <PageMainContent>
        <ToolbarMemoised
          handleSearch={handleSearch}
          classes={classes}
          searchApiCallFunction={searchApiCallFunction}
          page={page}
          setPage={setPage}
        />
        <Loader isLoading={loading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <AddEditVinVidMapping
          type={openPopup.child}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setRefreshVinVidMappings={setRefreshVinVidMappings}
          setToast={setToast}
        />
      </Popup>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

export default VinVidMapping;
