export const chargersMetadata = {
  "EX-1193-1FDZ": {
    chargerMetaId: "e722ce34-dd21-4c8e-b3d2-2f80173f3f17",
    vendorModel: "IC 48A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 48A Series 1",
  },
  "EX-1193-1FDY": {
    chargerMetaId: "e722ce34-dd21-4c8e-b3d2-2f80173f3f17",
    vendorModel: "IC 48A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 48A Series 1",
  },
  "EX-1193-1A13": {
    chargerMetaId: "ac2ed98c-086a-4a56-ac50-fe912351ff34",
    vendorModel: "IC 80A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 1",
  },
  "EX-1193-1A15": {
    chargerMetaId: "ac2ed98c-086a-4a56-ac50-fe912351ff34",
    vendorModel: "IC 80A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 1",
  },
  "EX-1193-1FD1": {
    chargerMetaId: "ac2ed98c-086a-4a56-ac50-fe912351ff34",
    vendorModel: "IC 80A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 1",
  },
  "EX-1193-1FD2": {
    chargerMetaId: "ac2ed98c-086a-4a56-ac50-fe912351ff34",
    vendorModel: "IC 80A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 1",
  },
  "EX-1193-1FD3": {
    chargerMetaId: "ac2ed98c-086a-4a56-ac50-fe912351ff34",
    vendorModel: "IC 80A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 1",
  },
  "EX-1193-1FD4": {
    chargerMetaId: "ac2ed98c-086a-4a56-ac50-fe912351ff34",
    vendorModel: "IC 80A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 1",
  },
  "EX-1193-1FD6": {
    chargerMetaId: "ac2ed98c-086a-4a56-ac50-fe912351ff34",
    vendorModel: "IC 80A Series 1",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 1",
  },
  "EX-1193-1FDA": {
    chargerMetaId: "ac2ed98c-086a-4a56-ac50-fe912351ff34",
    vendorModel: "IC 80A Series 1",
    whiteLabelManufacturer: "Ford Land",
    whiteLabelModel: "AC Charging Station 80A Series 1",
  },
  "EX-1193-1FR1": {
    chargerMetaId: "1ada81fc-3708-480a-b570-cb0aaabf83e4",
    vendorModel: "IC 80A Series 1.5",
    whiteLabelManufacturer: "Ford",
    whiteLabelModel: "Model e AC 19.2 kW / 80 A Charging Station",
  },
  "EX-1193-1FD8": {
    chargerMetaId: "1ada81fc-3708-480a-b570-cb0aaabf83e4",
    vendorModel: "IC 80A Series 1.5",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "Ford Pro AC Charging Station 80A - Series 1 with RFID",
  },
  "EX-1193-1LN1": {
    chargerMetaId: "1ada81fc-3708-480a-b570-cb0aaabf83e4",
    vendorModel: "IC 80A Series 1.5",
    whiteLabelManufacturer: "Lincoln",
    whiteLabelModel: "Model e AC 19.2 kW / 80 A Charging Station",
  },
  "EX-1193-1FD5": {
    chargerMetaId: "1ada81fc-3708-480a-b570-cb0aaabf83e4",
    vendorModel: "IC 80A Series 1.5",
    whiteLabelManufacturer: "Ford Land",
    whiteLabelModel: "Model e AC 19.2 kW / 80 A Charging Station",
  },
  "EX-1193-MFDZ": {
    chargerMetaId: "62950ec0-ba4a-43eb-8175-9e7363322d34",
    vendorModel: "IC 48A Series 2",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 48A Series 2",
  },
  "EX-1193-MFDX": {
    chargerMetaId: "62950ec0-ba4a-43eb-8175-9e7363322d34",
    vendorModel: "IC 48A Series 2",
    whiteLabelManufacturer: "Ford Pro Cyber Security",
    whiteLabelModel: "AC Charging Station 48A Series 2",
  },
  "EX-1193-MFD1": {
    chargerMetaId: "6a4a24a4-19ea-4328-b277-c726613cadc5",
    vendorModel: "IC 80A Series 2",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 2",
  },
  "EX-1193-MFD5": {
    chargerMetaId: "6a4a24a4-19ea-4328-b277-c726613cadc5",
    vendorModel: "IC 80A Series 2",
    whiteLabelManufacturer: "Ford Pro Cyber Security",
    whiteLabelModel: "AC Charging Station 80A Series 2",
  },
  "EX-1193-MFD3": {
    chargerMetaId: "6a4a24a4-19ea-4328-b277-c726613cadc5",
    vendorModel: "IC 80A Series 2",
    whiteLabelManufacturer: "Ford Pro",
    whiteLabelModel: "AC Charging Station 80A Series 2",
  },
  "EX-1193-MFD2": {
    chargerMetaId: "6a4a24a4-19ea-4328-b277-c726613cadc5",
    vendorModel: "IC 80A Series 2",
    whiteLabelManufacturer: "Ford Land",
    whiteLabelModel: "AC Charging Station 80A Series 2",
  },
};
