import ApiCore from "./utilities/core.js";
import { apiProvider, indexedEndPoints } from "./utilities/provider";

const url = "ford";
const single = "user/customers";
const {
  DEPOT_REQUESTS,
  COMPANIES,
  DECLINED_REQUESTS,
  PROVISION,

  DEPOT_SYNCHRONISE,
  BILLING_RATEPLANS,
  DEPOTS_INFO,
  DEPOTS_CHARGING_MODE_CHARGER_COUNT,
  REJECT_DELETE,
  DELETE_DEPOT,
} = indexedEndPoints;

const apiDepots = new ApiCore({
  getAll: true,
  post: true,
  url,
  single,
});

apiDepots.getAllDepotRequests = () => apiProvider.getAll(DEPOT_REQUESTS);

apiDepots.getAllCompanies = () => apiProvider.getAll(COMPANIES);

apiDepots.getAllDeclined = () => apiProvider.getAll(DECLINED_REQUESTS);

apiDepots.provision = (payload) => apiProvider.post(PROVISION, payload);

apiDepots.synchroniseDepots = (item) =>
  apiProvider.put(DEPOT_SYNCHRONISE?.replace("depotId", item.depotId), {});

apiDepots.confirmDecline = (companyId, depotId, payload) =>
  apiProvider.put(`${DEPOT_REQUESTS}/${companyId}/${depotId}`, payload);

apiDepots.getRatePlans = () => apiProvider.getAll(BILLING_RATEPLANS);

apiDepots.getDepotInfo = () => apiProvider.getAll(DEPOTS_INFO);

apiDepots.getDepotsChargingMode = () =>
  apiProvider.getAll(DEPOTS_CHARGING_MODE_CHARGER_COUNT);

apiDepots.rejectDeleteDepot = (depotId) =>
  apiProvider.put(`${REJECT_DELETE}/${depotId}`);

apiDepots.deleteDepot = (depotId) =>
  apiProvider.del(`${DELETE_DEPOT}/${depotId}`);

// apiDepots.deleteUser = (id) => {
//   return apiProvider.del(`${url}/${id}`);
// };

// apiDepots.updatePassword = (payload) => {
//   return apiProvider.put(`${url}/update-password`, payload);
// };

export default apiDepots;
