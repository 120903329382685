import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import "./Components.scss";
import Controls from "./controls/Controls";
const ExportCSVAPI = (props) => {
  const { setOpenPopup, count, headers, setHeaders, exportCsvApiCall } = props;
  const [columnType, setColumnType] = React.useState("all");
  const [customHeader, setCustomHeader] = React.useState([]);

  const handleChange = (event) => {
    setHeaders(
      headers.map((element) => {
        if (element.label === event.target.name)
          element.checked = event.target.checked;
        return element;
      })
    );
  };

  const CheckBoxFields = (props) => {
    const { headers, handleChange } = props;
    if (headers) {
      return headers.map((element) => {
        return (
          <Grid item xs={6} key={element.key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={element.checked}
                  onChange={handleChange}
                  name={element.label}
                  key={element.key}
                />
              }
              label={element.label}
              key={element.key}
            />
          </Grid>
        );
      });
    }
  };

  React.useEffect(() => {
    let filtered = headers.filter((element) => {
      if (element.checked) return true;
    });
    let temp = [];
    filtered?.map((single) => {
      let tempObj = {};
      tempObj["label"] = single.label;
      tempObj["key"] = single.key;
      temp.push(tempObj);
    });
    setCustomHeader(temp);
  }, [headers]);

  const handleColumnType = (event) => {
    setColumnType(event.target.value);
  };
  return (
    <>
      <div className="popup_download_layout">
        <p className="export-csv-api-columns-p">Columns</p>
        <div>
          <Select
            value={columnType}
            onChange={handleColumnType}
            className="export-csv-select"
          >
            <MenuItem value={"all"}>All </MenuItem>
            <MenuItem value={"custom"}>Custom</MenuItem>
          </Select>
        </div>
        {columnType === "custom" ? (
          <FormControl
            component="fieldset"
            variant="standard"
            className="width100"
          >
            <FormGroup>
              <Grid container>
                <CheckBoxFields headers={headers} handleChange={handleChange} />
              </Grid>
            </FormGroup>
            <FormHelperText>
              Only the checked columns will be downloaded, and minimum 2 columns
              must be selected
            </FormHelperText>
          </FormControl>
        ) : (
          <p>
            {headers.map(
              (element, index) => (index ? ", " : "") + element.label
            )}
          </p>
        )}

        {count && (
          <p className="exportCSVCount">
            Exporting <span className="exportCSVCountNumber">{count}</span>{" "}
            records
          </p>
        )}
        <Grid
          container
          justifyContent="flex-end"
          className="export-csv-buttons-grid"
        >
          <Grid item xs={2}>
            <div className="export-csv-buttons-div">
              <Controls.Button
                variant="outlined"
                text="Cancel"
                onClick={() => {
                  setOpenPopup({
                    isOpen: false,
                    title: "Export CSV",
                    child: "exportCSV",
                  });
                }}
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className="export-csv-buttons-div">
              {columnType === "all" ? (
                <Controls.Button
                  text={"Export"}
                  onClick={() => {
                    exportCsvApiCall("all");
                  }}
                />
              ) : (
                customHeader.length > 1 && (
                  <Controls.Button
                    text={"Export"}
                    onClick={() => {
                      exportCsvApiCall(customHeader);
                    }}
                  />
                )
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ExportCSVAPI;
