import React, { useEffect, useState, PureComponent, useRef } from "react";
import {
  Grid,
  Divider,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  Stack,
  InputLabel,
  TextField,
  CircularProgress,
  Select as MuiSelect,
  Tooltip as MuiTooltip,
  MenuItem,
  IconButton,
  Autocomplete,
  FormHelperText,
  List,
} from "@mui/material";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import Controls from "../../components/controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import { commonApiActionCreator } from "../../redux-state/actions";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import format from "date-fns/format";
import sub from "date-fns/sub";
import EvStationIcon from "@mui/icons-material/EvStation";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Brush,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import {
  getAllCircuits,
  getDepotMaxValues,
  getLoadMonitoring,
  getDepotChargers,
  getChargersMonitoring,
} from "./circuitService";
import { getChargerProfile } from "../charger-health/charger-firmware-details/chargerFirmwareService";
import { addMonths, differenceInDays, fromUnixTime, subDays } from "date-fns";
import { CLLMDATA } from "./CLLMdata";
import { TimerFunction, useDebounce } from "./hooks";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import { CSVDownload, CSVLink } from "react-csv";
import { messages } from "../../constants/Messages";
import { cryptoRandom } from "../../components/common-functions/CommonFunctions";

const listDate = [
  {
    label: "Live Tail",
    value: "Live Tail",
    disabled: false,
  },
  {
    label: "Past 15 Minutes",
    value: "Past 15 Minutes",
    disabled: false,
  },
  {
    label: "Past 1 Hour",
    value: "Past 1 Hour",
    disabled: false,
  },
  {
    label: "Past 4 Hour",
    value: "Past 4 Hour",
    disabled: false,
  },
  {
    label: "Past 1 Day",
    value: "Past 1 Day",
    disabled: false,
  },
  {
    label: "Past 3 Days",
    value: "Past 3 Days",
    disabled: true,
  },
  {
    label: "Past 7 Days",
    value: "Past 7 Days",
    disabled: true,
  },
  {
    label: "Past 15 Days",
    value: "Past 15 Days",
    disabled: true,
  },
  {
    label: "Past 1 Month",
    value: "Past 1 Month",
    disabled: true,
  },
  {
    label: "Custom Date",
    value: "Custom Date",
    disabled: true,
  },
];

const CircuitMonitorModel = () => {
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );

  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountNames, setAllAccountNames] = useState([]);
  const [allDepotNames, setAllDepotNames] = useState([]);
  const [companiesDataLoading, setCompaniesDataLoading] = useState(false);
  const [selectedAccountName, setSelectedAccountName] = useState(null);
  const [selectedDepotName, setSelectedDepotName] = useState(null);
  const [selectedDepotID, setSelectedDepotID] = useState("");
  const [circuitChipData, setCircuitChipData] = useState([]);
  const [profileChipData, setProfileChipData] = useState([]);
  const [cORp, setCORp] = useState("DEPOT");
  const [interval, setInterval] = useState(300);
  const [debounceState, setDebounceState] = useState(0);
  const debounceValue = useDebounce(debounceState);
  const [chips, setChips] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [chartData, setchartData] = useState([]);
  const [rawChartData, setRawChartData] = useState([]);
  const [dateType, setDatetype] = useState("Past 1 Day");
  const [siteCapacityLimit, setSiteCapacityLimit] = useState(0);
  const [powerLimit, setPowerLimit] = useState(0);
  const [multiPowerLimit, setMultiPowerLimit] = useState(false);
  const [dateOptions, setDateOptions] = useState(listDate);
  const [gridSizes, setGridSizes] = useState([6]);
  const [allDepotChargers, setAllDepotChargers] = useState([]);
  const [chargerChips, setChargerChips] = useState([]);
  const [allChargerGraph, setAllChargerGraph] = useState([]);

  const [customFromDate, setCustomFromDate] = useState(null);
  const [customToDate, setCustomToDate] = useState(null);

  const toggleGridSize = (index) => {
    setGridSizes((prevSizes) => {
      const updatedSizes = [...prevSizes];
      updatedSizes[index] = updatedSizes[index] === 6 ? 12 : 6;
      return updatedSizes;
    });
  };

  const intervalOptions = [
    // {
    //   label: "15 Sec",
    //   value: 15,
    // },
    {
      label: "1 Min",
      value: 60,
    },
    {
      label: "5 Min",
      value: 300,
    },
    {
      label: "15 Min",
      value: 900,
    },
    {
      label: "1 Hour",
      value: 3600,
    },
  ];

  const monitorByOptions = [
    {
      label: "DEPOT",
      label: "DEPOT",
    },
    {
      label: "CIRCUIT",
      label: "CIRCUIT",
    },
    {
      label: "PROFILES",
      label: "PROFILES",
    },
  ];

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getCompaniesRawData();
  }, [isCompaniesLoaded]);

  const getCompaniesRawData = async () => {
    setCompaniesDataLoading(true);
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      const onlyDepotsList = [];
      tempAllCompaniesV2.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          children: tempAllCompaniesV2,
        },
      ];
      setCompaniesDataLoading(false);
      setAllAccounts(tempAccounts);
    }
  };
  React.useEffect(() => {
    setAllAccountNames(
      allAccounts[0]?.children?.map((el) => ({
        label: el.companyName,
        value: el.companyName,
      }))
    );
  }, [allAccounts]);

  React.useEffect(() => {
    if (selectedAccountName) {
      setAllDepotNames(
        allAccounts[0]?.children
          .find((obj) => obj.companyName === selectedAccountName?.label)
          ?.depotsList?.map((el) => ({
            label: el.depotName,
            value: el.depotName,
          }))
      );
    }
  }, [selectedAccountName]);

  React.useEffect(async () => {
    if (selectedDepotID) {
      setchartData([]);

      const allDepotChargers = await getDepotChargers(selectedDepotID);
      if (
        allDepotChargers.statusCode >= 200 &&
        allDepotChargers.statusCode <= 299
      ) {
        setAllDepotChargers((prevState) => {
          const newData = allDepotChargers?.data?.map((obj) => ({
            color: getRandomColor(),
            name: obj.chargerName,
            id: obj.chargerId,
            clicked: false,
            ...obj,
          }));
          newData.unshift({
            name: "ALL",
            id: "ALL",
            clicked: false,
          });

          return newData;
        });
      } else {
        setAllDepotChargers();
      }

      const res = await getAllCircuits(selectedDepotID);
      if (res.statusCode >= 200 && res.statusCode <= 299) {
        setCircuitChipData([
          {
            circuitName: "ALL",
            circuitId: "ALL",
          },
          ...res.data,
        ]);
      } else {
        setCircuitChipData([]);
      }

      const resProfile = await getChargerProfile(selectedDepotID);
      if (resProfile.statusCode >= 200 && resProfile.statusCode <= 299) {
        setProfileChipData([
          {
            name: "ALL",
            profileId: "ALL",
          },
          ...resProfile.data,
        ]);
      } else {
        setProfileChipData([]);
      }

      const resMaxValues = await getDepotMaxValues(selectedDepotID);
      if (resMaxValues.statusCode >= 200 && resMaxValues.statusCode <= 299) {
        setSiteCapacityLimit(resMaxValues?.data?.siteCapacity);
        setPowerLimit(resMaxValues?.data?.powerLimit);
        setMultiPowerLimit(resMaxValues?.data?.hasMultiplePowerLimits);
      } else {
        setSiteCapacityLimit(0);
        setPowerLimit(0);
        setMultiPowerLimit(false);
      }
    } else {
      setCircuitChipData([]);
      setProfileChipData([]);
    }
  }, [selectedDepotID]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(cryptoRandom() * 16)]; // Not using Math.random as it is detected in GIT vulnerability
    }
    return color;
  };

  React.useEffect(() => {
    if (cORp) {
      setchartData([]);
      setRawChartData([]);
      if (cORp == "CIRCUIT") {
        if (circuitChipData.length) {
          setChips([
            ...circuitChipData?.map((item) => ({
              ...item,
              name: item.circuitName,
              id: item.circuitId,
              clicked: false,
            })),
          ]);
        }
      } else if (cORp == "DEPOT") {
        setChips([]);
      } else if (cORp == "PROFILES") {
        if (profileChipData.length) {
          setChips([
            ...profileChipData?.map((item) => ({
              ...item,
              name: item?.name,
              id: item?.profileId,
              clicked: false,
            })),
          ]);
        }
      }
    } else {
      setChips([]);
    }
  }, [cORp]);

  React.useEffect(() => {
    if (cORp) {
      setChargerChips([]);
      if (cORp == "CIRCUIT") {
        if (allDepotChargers.length) {
          const clickedChargersObjectIds = chips
            ?.filter((item) => item.clicked)
            ?.map((item) => item.circuitId);
          if (clickedChargersObjectIds?.length) {
            const filteredObjects = allDepotChargers?.filter((obj) =>
              clickedChargersObjectIds?.includes(obj?.circuitId)
            );
            const filteredObjectsWithAll =
              filteredObjects.length > 1
                ? [allDepotChargers[0], ...filteredObjects]
                : [];
            const uniqueObjects = new Set(
              filteredObjectsWithAll?.map(JSON.stringify)
            );
            const uniqueArray = Array.from(uniqueObjects).map(JSON.parse);
            setChargerChips([...uniqueArray]);
          }
        } else {
          setChargerChips([]);
        }
      } else if (cORp == "DEPOT") {
        if (allDepotChargers.length) {
          setChargerChips([...allDepotChargers]);
        } else {
          setChargerChips([]);
        }
      } else if (cORp == "PROFILES") {
        if (allDepotChargers.length) {
          const clickedProfileObjectIds = chips
            ?.filter((obj) => obj?.clicked)
            ?.map((obj) => obj?.profileId);
          if (clickedProfileObjectIds?.length) {
            const filteredObjects = allDepotChargers?.filter((obj) =>
              clickedProfileObjectIds?.includes(obj?.chargerProfile)
            );
            const filteredObjectsWithAll =
              filteredObjects.length > 1
                ? [allDepotChargers[0], ...filteredObjects]
                : [];
            const uniqueObjects = new Set(
              filteredObjectsWithAll?.map(JSON.stringify)
            );
            const uniqueArray = Array.from(uniqueObjects).map(JSON.parse);
            setChargerChips([...uniqueArray]);
          }
        } else {
          setChargerChips([]);
        }
      }
    }
  }, [cORp, allDepotChargers, chips]);

  const getDepotID = (name) => {
    if (name) {
      const depotId = allAccounts[0]?.children?.reduce((result, item) => {
        const depot = item?.depotsList?.find((dep) => dep?.depotName === name);
        return depot ? depot?.depotId : result;
      }, null);
      setSelectedDepotID(depotId);
    } else {
      setSelectedDepotID("");
    }
  };

  const chipFilter = (e) => {
    setChips((prevData) => {
      const newClickedValue = e === "ALL" ? !prevData[0]?.clicked : undefined;

      const updatedChips = prevData.map((item) =>
        e === "ALL"
          ? { ...item, clicked: newClickedValue }
          : item.id === e
          ? { ...item, clicked: !item.clicked }
          : item
      );
      const allClickedTrueExceptALL = updatedChips
        .slice(1)
        .every((item) => item.clicked);
      const anyClickedFalseExceptALL = updatedChips
        .slice(1)
        .some((item) => !item.clicked);
      const allClickedValue = !anyClickedFalseExceptALL;
      updatedChips[0].clicked = allClickedValue;

      return updatedChips;
    });
  };

  const chargerChipFilter = (e) => {
    setChargerChips((prevData) => {
      const newClickedValue = e === "ALL" ? !prevData[0]?.clicked : undefined;

      const updatedChips = prevData.map((item) =>
        e === "ALL"
          ? { ...item, clicked: newClickedValue }
          : item.id === e
          ? { ...item, clicked: !item.clicked }
          : item
      );
      const allClickedTrueExceptALL = updatedChips
        .slice(1)
        .every((item) => item.clicked);
      const anyClickedFalseExceptALL = updatedChips
        .slice(1)
        .some((item) => !item.clicked);
      const allClickedValue = !anyClickedFalseExceptALL;
      updatedChips[0].clicked = allClickedValue;

      return updatedChips;
    });
  };

  const getProfileOrCircuteName = (id) => {
    if (cORp == "PROFILES" || cORp == "CIRCUIT") {
      return chips?.find((item) => item?.id === id)?.name;
    } else {
      return null;
    }
  };
  const yourFunctionToCall = async () => {
    if (dateType == "Live Tail") {
      if (canExcute(selectedDepotID, cORp, fromDate, toDate, interval)) {
        let body = {
          siteID: selectedDepotID,
          startDate: Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              seconds: 120,
            }).getTime() / 1000
          ),
          endDate: Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              seconds: 60,
            }).getTime() / 1000
          ),
          groupByKey: cORp,
          intervals: interval,
        };

        // Math.floor(
        //   sub(new Date(Math.floor(new Date().getTime())), {
        //     minutes: 1,
        //   }).getTime() / 1000
        // );

        if (chips.length !== 0) {
          if (cORp == "CIRCUIT") {
            body["groupByValue"] = chips
              ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
              ?.map((obj) => obj.id);
          } else if (cORp == "PROFILES") {
            body["groupByValue"] = chips
              ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
              ?.map((obj) => obj.id);
          } else if (cORp == "DEPOT") {
            body["groupByValue"] = [selectedDepotID];
          }
        } else {
          body["groupByValue"] = [selectedDepotID];
        }
        const res = await getLoadMonitoring(body);
        if (res.statusCode >= 200 && res.statusCode <= 299) {
          if (res?.data?.length) {
            const getTime = (obj) => obj?.time;
            res?.data?.forEach((obj) =>
              obj?.data?.sort((a, b) => getTime(a) - getTime(b))
            );
            const newData = res?.data?.map((item) => {
              const newDataItem = {
                data: item?.data?.map((dataItem) => {
                  const formattedTime = dataItem?.time
                    ? format(fromUnixTime(dataItem?.time), "dd MMM, hh:mm a")
                    : "";

                  const mappedData = {
                    currentImport:
                      dataItem?.plotValue?.currentImport?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.currentImport?.value,
                    currentOffered:
                      dataItem?.plotValue?.currentOffered?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.currentOffered?.value,
                    powerActiveImport:
                      dataItem?.plotValue?.powerActiveImport?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.powerActiveImport?.value,
                    powerOffered:
                      dataItem?.plotValue?.powerOffered?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.powerOffered?.value,
                    time: formattedTime,
                  };

                  if (dataItem?.powerLimit) {
                    mappedData.powerLimit =
                      dataItem?.powerLimit == 0
                        ? ""
                        : (dataItem?.powerLimit * 1000) / 240;
                  }

                  return mappedData;
                }),
                group: item?.group,
              };

              return newDataItem;
            });

            setchartData((prevData) => {
              if (
                !newData ||
                !newData.length ||
                !newData[0].data ||
                !newData[0].data.length
              ) {
                return prevData;
              }

              return prevData.map((group) => {
                const matchingNewData = newData.find(
                  (newGroup) => newGroup.group === group.group
                );

                if (
                  matchingNewData &&
                  matchingNewData.data &&
                  matchingNewData.data.length
                ) {
                  const updatedData = [...group.data, ...matchingNewData.data];

                  return {
                    ...group,
                    data: updatedData,
                  };
                }
                return group;
              });
            });
            setRawChartData((prevData) => {
              if (
                !newData ||
                !newData.length ||
                !newData[0].data ||
                !newData[0].data.length
              ) {
                return prevData;
              }

              return prevData.map((group) => {
                const matchingNewData = newData.find(
                  (newGroup) => newGroup.group === group.group
                );

                if (
                  matchingNewData &&
                  matchingNewData.data &&
                  matchingNewData.data.length
                ) {
                  const updatedData = [...group.data, ...matchingNewData.data];

                  return {
                    ...group,
                    data: updatedData,
                  };
                }
                return group;
              });
            });
          }
        } else {
        }

        const resChargers = await getChargersMonitoring(body, selectedDepotID);

        if (resChargers.statusCode >= 200 && resChargers.statusCode <= 299) {
          if (resChargers?.data?.length) {
            const getTime = (obj) => obj?.time;
            resChargers?.data?.forEach((obj) =>
              obj?.data?.sort((a, b) => getTime(a) - getTime(b))
            );
            const newData = resChargers?.data?.map((item) => {
              const newDataItem = {
                data: item?.data?.map((dataItem) => {
                  const formattedTime = dataItem?.time
                    ? format(fromUnixTime(dataItem?.time), "dd MMM, hh:mm a")
                    : "";

                  const mappedData = {
                    currentImport:
                      dataItem?.plotValue?.currentImport?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.currentImport?.value,
                    currentOffered:
                      dataItem?.plotValue?.currentOffered?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.currentOffered?.value,
                    powerActiveImport:
                      dataItem?.plotValue?.powerActiveImport?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.powerActiveImport?.value,
                    powerOffered:
                      dataItem?.plotValue?.powerOffered?.value <= 0
                        ? ""
                        : dataItem?.plotValue?.powerOffered?.value,
                    time: formattedTime,
                  };
                  return mappedData;
                }),
                group: item?.chargerId,
                key: item?.key,
              };

              return newDataItem;
            });

            setAllChargerGraph((prevState) => {
              return prevState.map((prevItem) => {
                const matchingNewData = newData.find(
                  (newItem) => newItem.group === prevItem.group
                );
                if (matchingNewData) {
                  return {
                    ...prevItem,
                    data: [...prevItem.data, ...matchingNewData.data],
                  };
                } else {
                  return prevItem;
                }
              });
            });
          } else {
          }
        } else {
        }
      }
    }
  };
  TimerFunction(yourFunctionToCall, 60000);

  React.useEffect(async () => {
    if (canExcute(selectedDepotID, cORp, fromDate, toDate, interval)) {
      setCompaniesDataLoading(true);
      let body = {
        siteID: selectedDepotID,
        startDate: fromDate,
        endDate: toDate,
        groupByKey: cORp,
        intervals: interval,
      };

      if (chips.length !== 0) {
        if (cORp == "CIRCUIT") {
          body["groupByValue"] = chips
            ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
            ?.map((obj) => obj.id);
        } else if (cORp == "PROFILES") {
          body["groupByValue"] = chips
            ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
            ?.map((obj) => obj.id);
        } else if (cORp == "DEPOT") {
          body["groupByValue"] = [selectedDepotID];
        }
      } else {
        body["groupByValue"] = [selectedDepotID];
      }
      const res = await getLoadMonitoring(body);
      if (res.statusCode >= 200 && res.statusCode <= 299) {
        if (res?.data?.length) {
          const getTime = (obj) => obj?.time;
          res?.data?.forEach((obj) =>
            obj?.data?.sort((a, b) => getTime(a) - getTime(b))
          );
          const newData = res?.data?.map((item) => {
            const newDataItem = {
              data: item?.data?.map((dataItem) => {
                const formattedTime = dataItem?.time
                  ? format(fromUnixTime(dataItem?.time), "dd MMM, hh:mm a")
                  : "";

                const mappedData = {
                  currentImport:
                    dataItem?.plotValue?.currentImport?.value <= 0
                      ? ""
                      : dataItem?.plotValue?.currentImport?.value,
                  currentOffered:
                    dataItem?.plotValue?.currentOffered?.value <= 0
                      ? ""
                      : dataItem?.plotValue?.currentOffered?.value,
                  powerActiveImport:
                    dataItem?.plotValue?.powerActiveImport?.value <= 0
                      ? ""
                      : dataItem?.plotValue?.powerActiveImport?.value,
                  powerOffered:
                    dataItem?.plotValue?.powerOffered?.value <= 0
                      ? ""
                      : dataItem?.plotValue?.powerOffered?.value,
                  time: formattedTime,
                };

                if (dataItem?.powerLimit) {
                  mappedData.powerLimit =
                    dataItem?.powerLimit == 0
                      ? ""
                      : (dataItem?.powerLimit * 1000) / 240;
                }

                return mappedData;
              }),
              group: item?.group,
            };
            return newDataItem;
          });
          setchartData(newData);
          setRawChartData(newData);
        } else {
          setchartData([]);
          setRawChartData([]);
        }

        setCompaniesDataLoading(false);
      } else {
        setchartData([]);
        setRawChartData([]);
        setCompaniesDataLoading(false);
      }
    }
  }, [debounceValue]);

  React.useEffect(async () => {
    if (canExcute(selectedDepotID, cORp, fromDate, toDate, interval)) {
      let body = {
        siteID: selectedDepotID,
        startDate: fromDate,
        endDate: toDate,
        groupByKey: cORp,
        intervals: interval,
      };

      if (chips.length !== 0) {
        if (cORp == "CIRCUIT") {
          body["groupByValue"] = chips
            ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
            ?.map((obj) => obj.id);
        } else if (cORp == "PROFILES") {
          body["groupByValue"] = chips
            ?.filter((obj) => obj.id !== "ALL" && obj.clicked)
            ?.map((obj) => obj.id);
        } else if (cORp == "DEPOT") {
          body["groupByValue"] = [selectedDepotID];
        }
      } else {
        body["groupByValue"] = [selectedDepotID];
      }

      const resChargers = await getChargersMonitoring(body, selectedDepotID);

      if (resChargers.statusCode >= 200 && resChargers.statusCode <= 299) {
        if (resChargers?.data?.length) {
          const getTime = (obj) => obj?.time;
          resChargers?.data?.forEach((obj) =>
            obj?.data?.sort((a, b) => getTime(a) - getTime(b))
          );
          const newData = resChargers?.data?.map((item) => {
            const newDataItem = {
              data: item?.data?.map((dataItem) => {
                const formattedTime = dataItem?.time
                  ? format(fromUnixTime(dataItem?.time), "dd MMM, hh:mm a")
                  : "";

                const mappedData = {
                  currentImport:
                    dataItem?.plotValue?.currentImport?.value <= 0
                      ? ""
                      : dataItem?.plotValue?.currentImport?.value,
                  currentOffered:
                    dataItem?.plotValue?.currentOffered?.value <= 0
                      ? ""
                      : dataItem?.plotValue?.currentOffered?.value,
                  powerActiveImport:
                    dataItem?.plotValue?.powerActiveImport?.value <= 0
                      ? ""
                      : dataItem?.plotValue?.powerActiveImport?.value,
                  powerOffered:
                    dataItem?.plotValue?.powerOffered?.value <= 0
                      ? ""
                      : dataItem?.plotValue?.powerOffered?.value,
                  time: formattedTime,
                };
                return mappedData;
              }),
              group: item?.chargerId,
              key: item?.key,
            };

            return newDataItem;
          });
          setAllChargerGraph(newData);
        } else {
          setAllChargerGraph([]);
        }
      } else {
        setAllChargerGraph([]);
      }
    }
  }, [debounceValue]);

  React.useEffect(async () => {
    if (chargerChips.length) {
      if (cORp == "DEPOT") {
        if (!chargerChips.every((obj) => obj.clicked === false)) {
          let selectedChargers = chargerChips
            .filter((obj) => obj.clicked === true)
            .map((obj) => obj.chargerId);
          let chargerFiltered = allChargerGraph.filter((obj) =>
            selectedChargers.includes(obj.group)
          );
          const stateCopy = {};
          rawChartData[0]?.data?.forEach((single, i) => {
            stateCopy[single.time] = { ...single };
            chargerFiltered.forEach((row) => {
              const matched = row.data.find((item) => item.time == single.time);
              if (matched) {
                stateCopy[single.time] = {
                  ...stateCopy[single.time],
                  [`${row.group}/currentImport`]: matched.currentImport,
                  [`${row.group}/currentOffered`]: matched.currentOffered,
                  [`${row.group}/powerActiveImport`]: matched.powerActiveImport,
                  [`${row.group}/powerOffered`]: matched.powerOffered,
                };
              }
            });
          });

          setchartData([{ group: "all", data: Object.values(stateCopy) }]);
        } else {
          setchartData(rawChartData);
        }
      } else if (cORp == "PROFILES") {
        if (!chargerChips.every((obj) => obj.clicked === false)) {
          let selectedChargers = chargerChips
            .filter((obj) => obj.clicked === true)
            .map((obj) => obj.chargerId);

          let chargerFiltered = allChargerGraph.filter((obj) =>
            selectedChargers.includes(obj.group)
          );

          const filtered = [];

          rawChartData.forEach((profile) => {
            const _chargers = chargerFiltered.filter(
              (_charger) => _charger.key === profile.group
            );

            if (!_chargers.length) {
              filtered.push({ ...profile });
              return;
            }

            const retData = [];

            profile.data.forEach((profileData) => {
              const retValue = { ...profileData };
              _chargers.forEach((charger) => {
                const chargerData = charger.data.find(
                  (charge) => charge.time === profileData.time
                );
                if (chargerData) {
                  Object.entries(chargerData).forEach(([key, value]) => {
                    if (key === "time") {
                      return;
                    }
                    retValue[`${charger.group}/${key}`] = value;
                  });
                }
              });
              retData.push(retValue);
            });

            filtered.push({ ...profile, data: retData });
          });

          setchartData(filtered);
        } else {
          setchartData(rawChartData);
        }
      } else if (cORp == "CIRCUIT") {
        if (!chargerChips.every((obj) => obj.clicked === false)) {
          let selectedChargers = chargerChips
            .filter((obj) => obj.clicked === true)
            .map((obj) => obj.chargerId);

          let chargerFiltered = allChargerGraph.filter((obj) =>
            selectedChargers.includes(obj.group)
          );

          const filtered = [];

          rawChartData.forEach((profile) => {
            const _chargers = chargerFiltered.filter(
              (_charger) => _charger.key === profile.group
            );

            if (!_chargers.length) {
              filtered.push({ ...profile });
              return;
            }

            const retData = [];

            profile.data.forEach((profileData) => {
              const retValue = { ...profileData };
              _chargers.forEach((charger) => {
                const chargerData = charger.data.find(
                  (charge) => charge.time === profileData.time
                );
                if (chargerData) {
                  Object.entries(chargerData).forEach(([key, value]) => {
                    if (key === "time") {
                      return;
                    }
                    retValue[`${charger.group}/${key}`] = value;
                  });
                }
              });
              retData.push(retValue);
            });

            filtered.push({ ...profile, data: retData });
          });

          setchartData(filtered);
        } else {
          setchartData(rawChartData);
        }
      }
    } else {
      setchartData(rawChartData);
    }
  }, [chargerChips, allChargerGraph]);

  React.useEffect(async () => {
    if (canExcute(selectedDepotID, cORp, fromDate, toDate, interval)) {
      setDebounceState((prevCounter) => prevCounter + 1);
    }
  }, [selectedDepotID, cORp, fromDate, toDate, interval, chips]);

  const canExcute = (selectedDepotID, cORp, fromDate, toDate, interval) => {
    if (selectedDepotID && cORp && fromDate && toDate && interval) {
      if (cORp == "CIRCUIT" || cORp == "PROFILES") {
        if (
          chips?.filter((obj) => obj.clicked)?.map((obj) => obj.id).length == 0
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    if (dateType) {
      let tempFromDate = [];
      let tempToDate = [];
      if (dateType != "Custom Date") {
        setCustomFromDate(null);
        setCustomToDate(null);
      }
      if (dateType == "Past 15 Minutes") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              minutes: 15,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 1 Hour") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              hours: 1,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 4 Hour") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              hours: 4,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 1 Day") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              hours: 24,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 3 Days") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 3,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 7 Days") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 7,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 15 Days") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              days: 15,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Past 1 Month") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              months: 1,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Live Tail") {
        tempFromDate.push(
          Math.floor(
            sub(new Date(Math.floor(new Date().getTime())), {
              minutes: 5,
            }).getTime() / 1000
          )
        );
        setFromDate(tempFromDate[0]);
        setToDate(Math.floor(new Date().getTime() / 1000));
      } else if (dateType == "Custom Date") {
        if (customFromDate && customToDate) {
          setFromDate(Math.floor(new Date(customFromDate).getTime() / 1000));
          setToDate(Math.floor(new Date(customToDate).getTime() / 1000));
        }
      }
    }
  }, [
    dateType,
    selectedDepotID,
    cORp,
    fromDate,
    interval,
    chips,
    customToDate,
    customFromDate,
  ]);

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={10}
            fontSize={10}
            color="#3c5164"
            textAnchor="middle"
            fill="#666"
            transform="rotate(0)"
          >
            {payload.value}
          </text>
        </g>
      );
    }
  }

  const CustomTooltipCurrentImport = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Time : ${label}`}</p>
          {payload?.map((single) => {
            const name = chargerChips?.find(
              (item) => item?.chargerId === single?.name?.split("/")[0]
            );
            return (
              <p className="dis">
                {name ? <EvStationIcon style={{ color: name?.color }} /> : null}{" "}
                {`${name?.chargerName ? name?.chargerName : single?.name} : ${
                  single.value == "" ? 0 : single.value
                }A`}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const CustomTooltipCurrentOffered = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Time : ${label}`}</p>
          {payload?.map((single) => {
            const name = chargerChips?.find(
              (item) => item?.chargerId === single?.name?.split("/")[0]
            );
            return (
              <p className="dis">
                {name ? <EvStationIcon style={{ color: name?.color }} /> : null}{" "}
                {`${name?.chargerName ? name?.chargerName : single?.name} : ${
                  single.value == "" ? 0 : single.value
                }A`}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const CustomTooltipPowerImport = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Time : ${label}`}</p>
          {payload?.map((single) => {
            const name = chargerChips?.find(
              (item) => item?.chargerId === single?.name?.split("/")[0]
            );
            return (
              <p className="dis">
                {name ? <EvStationIcon style={{ color: name?.color }} /> : null}{" "}
                {`${name?.chargerName ? name?.chargerName : single?.name} : ${
                  single.value == "" ? 0 : single.value
                }kW`}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const CustomTooltipPowerOffered = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Time : ${label}`}</p>

          {payload?.map((single) => {
            const name = chargerChips?.find(
              (item) => item?.chargerId === single?.name?.split("/")[0]
            );
            return (
              <p className="dis">
                {name ? <EvStationIcon style={{ color: name?.color }} /> : null}{" "}
                {`${name?.chargerName ? name?.chargerName : single?.name} : ${
                  single.value == "" ? 0 : single.value
                }kW`}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const handleFromDate = (e) => {
    setCustomFromDate(e);
  };

  const handleToDate = (e) => {
    setCustomToDate(e);
  };

  const manageDurationOnInterval = (e) => {
    // if (e == "15 Sec" || dateType == "15 Sec") {
    //   updateDuration([
    //     "Live Tail",
    //     "Past 15 Minutes",
    //     "Past 1 Hour",
    //     "Past 4 Hour",
    //   ]);
    // } else
    if (e == "1 Min" || dateType == "1 Min") {
      updateDuration([
        "Live Tail",
        "Past 15 Minutes",
        "Past 1 Hour",
        "Past 4 Hour",
        "Past 1 Day",
        "Past 3 Days",
        "Past 7 Days",
        "Past 15 Days",
      ]);
    } else if (e == "5 Min" || dateType == "5 Min") {
      updateDuration([
        "Live Tail",
        "Past 1 Hour",
        "Past 4 Hour",
        "Past 1 Day",
        "Past 3 Days",
        "Past 7 Days",
      ]);
    } else if (e == "15 Min" || dateType == "15 Min") {
      updateDuration([
        "Live Tail",
        "Past 4 Hour",
        "Past 1 Day",
        "Past 3 Days",
        "Past 7 Days",
        "Past 15 Days",
      ]);
    } else if (e == "1 Hour" || dateType == "1 Hour") {
      updateDuration([
        "Live Tail",
        "Past 3 Days",
        "Past 7 Days",
        "Past 15 Days",
        "Past 1 Month",
        "Custom Date",
      ]);
    }
  };

  const updateDuration = (valuesToUpdate) => {
    const updatedState = dateOptions.map((item) => {
      if (valuesToUpdate.includes(item.value)) {
        return { ...item, disabled: false };
      } else {
        return { ...item, disabled: true };
      }
    });

    setDateOptions(updatedState);
  };

  const formatDataForExcel = (data) => {
    const result = data.map((obj) => {
      const newObj = {};
      for (const key in obj) {
        if (key !== "time") {
          newObj[key] = obj[key] === "" ? 0 : obj[key];
        } else {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    });
    return result;
  };

  const formatHeaderForExcel = (data) => {
    const uniqueKeys = new Set();
    data?.forEach((obj) => {
      Object?.keys(obj)?.forEach((key) => {
        uniqueKeys?.add(key);
      });
    });
    const labels = {
      time: "Time",
      currentImport: "Current Import (A)",
      currentOffered: "Current Offered (A)",
      powerActiveImport: "Power Active Import (kW)",
      powerOffered: "Power Offered (W)",
    };

    const transformedArray = Array.from(uniqueKeys).map((str) => {
      if (str in labels) {
        return { label: labels[str], key: str };
      } else {
        const key = str.split("/")[0];
        return {
          label: `${
            chargerChips?.find((item) => item?.chargerId === key)?.chargerName
          } - ${str.split("/")[1]}`,
          key: str,
        };
      }
    });

    return transformedArray;
  };

  return (
    <>
      <div>
        <div className="circuit_monitor">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div>
                  <FormControl
                    variant="outlined"
                    fullWidth="true"
                    size="small"
                    className="newAutoComplete"
                  >
                    <Autocomplete
                      disablePortal
                      size="small"
                      disabled={allAccountNames?.length ? false : true}
                      value={selectedAccountName}
                      onChange={(event, newValue) => {
                        setSelectedAccountName(newValue);
                        setSelectedDepotName(null);
                        setSelectedDepotID("");
                        setCORp("DEPOT");
                        setChargerChips([]);
                        setAllDepotChargers([]);
                        setAllDepotNames(null);
                      }}
                      options={allAccountNames}
                      renderInput={(params) => (
                        <TextField
                          style={{ fontSize: "5px" }}
                          error={!selectedAccountName ? true : false}
                          {...params}
                          label="Account *"
                        />
                      )}
                    />
                    {!selectedAccountName && (
                      <FormHelperText style={{ color: "#d32f2f" }}>
                        {messages.FIELD_REQUIRED}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div>
                  <FormControl
                    variant="outlined"
                    fullWidth="true"
                    size="small"
                    className="newAutoComplete"
                  >
                    <Autocomplete
                      disablePortal
                      size="small"
                      disabled={allDepotNames?.length ? false : true}
                      value={selectedDepotName}
                      onChange={(event, newValue) => {
                        setSelectedDepotName(newValue);
                        getDepotID(newValue?.label);
                        setCORp("DEPOT");
                        setChargerChips([]);
                        setAllDepotChargers([]);
                      }}
                      renderOption={(props, item) => (
                        <List {...props}>
                          <span>{item.label}</span>
                        </List>
                      )}
                      getOptionDisabled={(option) => option.disabled}
                      options={allDepotNames?.map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      getOptionLabel={(option) => {
                        return option.label;
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={!selectedDepotName ? true : false}
                          {...params}
                          label="Depot *"
                        />
                      )}
                    />
                    {!selectedDepotName && (
                      <FormHelperText style={{ color: "#d32f2f" }}>
                        {messages.FIELD_REQUIRED}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <FormControl
                    variant="outlined"
                    fullWidth="true"
                    size="small"
                    className="newSelect"
                  >
                    <InputLabel>Monitor By</InputLabel>
                    <MuiSelect
                      label="Monitor By"
                      style={{ fontSize: "0.8rem", lineHeight: "1.2" }}
                      name={cORp}
                      value={cORp}
                      onChange={(e) => {
                        setCORp(e.target.value);
                      }}
                      size="small"
                      MenuProps={{ "data-testid": `${name}Options` }}
                    >
                      <MenuItem value={"DEPOT"}>Depot</MenuItem>
                      <MenuItem
                        value={"CIRCUIT"}
                        disabled={circuitChipData?.length < 2}
                      >
                        Circuit
                      </MenuItem>
                      <MenuItem
                        value={"PROFILES"}
                        disabled={profileChipData?.length < 2}
                      >
                        Profiles
                      </MenuItem>
                    </MuiSelect>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={2}>
                <div>
                  <Controls.NewSelect
                    className="width100"
                    label="Interval"
                    name="interval"
                    options={intervalOptions}
                    value={
                      intervalOptions.find((item) => item.value === interval)
                        ?.label
                    }
                    error={interval ? null : messages.FIELD_REQUIRED}
                    disabled={dateType == "Live Tail" ? true : false}
                    onChange={(e) => {
                      setDatetype("");
                      setFromDate(null);
                      setToDate(null);
                      setCustomToDate(null);
                      setCustomToDate(null);
                      let x = intervalOptions.find(
                        (item) => item.label === e.target.value
                      )?.value;
                      setInterval(x);
                      manageDurationOnInterval(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <div>
                    <Controls.NewSelect
                      className="width100"
                      label="Duration"
                      name="duration"
                      options={dateOptions}
                      value={dateType}
                      error={dateType ? null : messages.FIELD_REQUIRED}
                      onChange={(e) => {
                        setDatetype(e.target.value);
                        if (e.target.value == "Live Tail") {
                          setInterval(60);
                        }
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <div className="circuit_chips">
                  {chips?.length ? (
                    <>
                      <div className="chip_sec">
                        <p className="chipTitle">{cORp}</p>
                        <Stack direction="row" flexWrap="wrap" spacing={1}>
                          {chips?.map((e, index) => (
                            <Chip
                              style={{
                                marginBottom: "5px",
                                fontSize: "0.7rem",
                              }}
                              variant={e.clicked ? "filled" : "outlined"}
                              label={e.name}
                              key={e.id}
                              clickable
                              onClick={() => chipFilter(e.id)}
                            />
                          ))}
                        </Stack>
                      </div>
                    </>
                  ) : null}
                </div>
              </Grid>
              {dateType == "Custom Date" && (
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <DatePicker
                      className="mui-date-picked-reports"
                      id="date-picker-dialog"
                      label="From Date"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      value={customFromDate}
                      onChange={(e) => handleFromDate(e)}
                      InputLabelProps={{
                        shrink: customFromDate ? true : false,
                      }}
                      maxDate={
                        customToDate ? new Date(customToDate) : new Date()
                      }
                      minDate={subDays(new Date(), 30)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ margin: "0 0 0 5px" }}
                          fullWidth
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                          error={!customFromDate ? true : false}
                        />
                      )}
                    />
                    <DatePicker
                      className="mui-date-picked-reports"
                      id="date-picker-dialog"
                      label="To Date"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      value={customToDate}
                      onChange={(e) => handleToDate(e)}
                      InputLabelProps={{
                        shrink: customToDate ? true : false,
                      }}
                      minDate={
                        customFromDate
                          ? new Date(customFromDate)
                          : subDays(new Date(), 29)
                      }
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          style={{ margin: "0 0 0 5px" }}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                          error={!customToDate ? true : false}
                        />
                      )}
                    />
                    <MuiTooltip title="Reset Dates" arrow>
                      <RestartAltIcon
                        style={{ marginLeft: "5px" }}
                        fontSize="small"
                        onClick={() => {
                          setCustomFromDate(null);
                          setCustomToDate(null);
                        }}
                      />
                    </MuiTooltip>
                  </div>
                </Grid>
              )}
            </Grid>
          </LocalizationProvider>

          <Grid container>
            <Grid item xs={12}>
              {chargerChips?.length ? (
                <>
                  <Divider variant="middle" style={{ margin: "5px 0 5px 0" }} />
                  <div className="charger_chips_section">
                    <div className="charger_chips">
                      <div className="chip_sec">
                        <p className="chipTitle">CHARGERS</p>
                        <Stack direction="row" flexWrap="wrap" spacing={1}>
                          {chargerChips?.map((e, index) => (
                            <Chip
                              style={{
                                marginBottom: "5px",
                                fontSize: "0.7rem",
                                ...(e.color && {
                                  border: `1px solid ${e.color}`,
                                }),
                                ...(e.clicked && {
                                  background: `${e.color}`,
                                }),
                              }}
                              variant={e.clicked ? "filled" : "outlined"}
                              label={e.name}
                              key={e.id}
                              clickable
                              onClick={() => chargerChipFilter(e.id)}
                            />
                          ))}
                        </Stack>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </Grid>
          </Grid>
        </div>
        {chartData.length != 0 &&
          chartData.map((single, index) => {
            return (
              <div className="circuit_monitor_chart" key={index}>
                <div className="DCP_export">
                  <p className="DCP_name">
                    {single.group == "all"
                      ? selectedDepotName?.label
                      : getProfileOrCircuteName(single.group)}
                  </p>

                  {single?.data.length != 0 && (
                    <CSVLink
                      data={formatDataForExcel(single?.data)}
                      headers={formatHeaderForExcel(single?.data)}
                      filename={
                        single?.group == "all"
                          ? `${
                              selectedDepotName?.label
                            }/${new Date().toLocaleDateString("en-GB")}`
                          : `${getProfileOrCircuteName(
                              single.group
                            )}/${new Date().toLocaleDateString("en-GB")}`
                      }
                      data-testid="csvLink"
                    >
                      <MuiTooltip title="Download CSV" arrow>
                        <IconButton>
                          <DownloadTwoToneIcon />
                        </IconButton>
                      </MuiTooltip>
                    </CSVLink>
                  )}
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={gridSizes[index] ? gridSizes[index] : 6}>
                    {companiesDataLoading ? (
                      <div className="loading_main">
                        <div className="loading_cllm">
                          <CircularProgress size={30} />
                        </div>
                      </div>
                    ) : single?.data.length != 0 ? (
                      <div className="chart_layout">
                        <div className="legendsOfGraph">
                          <div className="legends">
                            <p className="custom-paragraph currentImport">
                              <span>Current import(A)</span>
                            </p>
                          </div>
                          {gridSizes[index] == 6 ? (
                            <ZoomOutMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          ) : (
                            <ZoomInMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          )}
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            data={single?.data}
                            margin={{
                              top: 20,
                              right: 5,
                              left: 5,
                              bottom: 20,
                            }}
                          >
                            <XAxis
                              dataKey="time"
                              tick={<CustomizedAxisTick />}
                              label={{
                                value: `Time Duration`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                position: "bottom",
                                offset: 0,
                              }}
                            />
                            <YAxis
                              label={{
                                value: `Current(A)`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                angle: -90,
                                position: "left",
                                offset: 0,
                              }}
                              scale="log"
                              domain={["auto", "auto"]}
                              type="number"
                              id="currentImport"
                              tickFormatter={(value) => {
                                if (value % 1 !== 0) {
                                  return value.toFixed(1);
                                }
                                return value;
                              }}
                              style={{ fontSize: "10px", color: "#3c5164" }}
                            />
                            <Tooltip content={<CustomTooltipCurrentImport />} />
                            {cORp == "DEPOT" ? (
                              <>
                                {siteCapacityLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={
                                      siteCapacityLimit
                                        ? (siteCapacityLimit * 1000) / 240
                                        : 0
                                    }
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Site Capacity: ${
                                        siteCapacityLimit
                                          ? (
                                              (siteCapacityLimit * 1000) /
                                              240
                                            ).toFixed(2)
                                          : 0
                                      }A`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideRight",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : cORp == "CIRCUIT" ? (
                              <>
                                (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps
                                  }
                                  stroke="red"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Total Circuit Capacity: ${
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps
                                    }A`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideLeft",
                                    dy: -10,
                                    dx: 2,
                                    fill: "red",
                                  }}
                                />
                                ) (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps * 0.8
                                  }
                                  stroke="#c7b300"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Available Circuit Capacity: ${
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps * 0.8
                                    }A`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideRight",
                                    dy: -10,
                                    dx: 2,
                                    fill: "#c7b300",
                                  }}
                                />
                                )
                              </>
                            ) : cORp == "PROFILES" ? (
                              <>
                                {powerLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={
                                      powerLimit ? (powerLimit * 1000) / 240 : 0
                                    }
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Power limit: ${
                                        powerLimit
                                          ? (
                                              (powerLimit * 1000) /
                                              240
                                            )?.toFixed(2)
                                          : 0
                                      } A`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideLeft",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : null}

                            <Brush
                              dataKey="time"
                              height={10}
                              stroke="#0051A2"
                            />
                            <Line
                              type="monotone"
                              dataKey="currentImport"
                              stroke="#82ca9d"
                              dot={false}
                            />

                            {chargerChips
                              ?.filter((obj) => obj.clicked === true)
                              ?.map((single) => {
                                return (
                                  <Line
                                    type="monotone"
                                    dataKey={`${single?.chargerId}/currentImport`}
                                    stroke={single.color}
                                    dot={false}
                                  />
                                );
                              })}
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <div className="no_data_main">
                        <div className="no_data">
                          <MultilineChartIcon />
                          <p>No Data</p>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={gridSizes[index] ? gridSizes[index] : 6}>
                    {companiesDataLoading ? (
                      <div className="loading_main">
                        <div className="loading_cllm">
                          <CircularProgress size={30} />
                        </div>
                      </div>
                    ) : single?.data.length != 0 ? (
                      <div className="chart_layout">
                        <div className="legendsOfGraph">
                          <div className="legends">
                            <p className="custom-paragraph currentOffered">
                              <span>Current offered(A)</span>
                            </p>
                          </div>
                          {gridSizes[index] == 6 ? (
                            <ZoomOutMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          ) : (
                            <ZoomInMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          )}
                        </div>

                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            data={single?.data}
                            margin={{
                              top: 20,
                              right: 5,
                              left: 5,
                              bottom: 20,
                            }}
                          >
                            <XAxis
                              dataKey="time"
                              tick={<CustomizedAxisTick />}
                              label={{
                                value: `Time Duration`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                position: "bottom",
                                offset: 0,
                              }}
                            />
                            <YAxis
                              label={{
                                value: `Current(A)`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                angle: -90,
                                position: "left",
                                offset: 0,
                              }}
                              scale="log"
                              domain={["auto", "auto"]}
                              type="number"
                              id="currentOffered"
                              tickFormatter={(value) => {
                                if (value % 1 !== 0) {
                                  return value.toFixed(1);
                                }
                                return value;
                              }}
                              style={{ fontSize: "10px", color: "#3c5164" }}
                            />
                            <Tooltip
                              content={<CustomTooltipCurrentOffered />}
                            />
                            {cORp == "DEPOT" ? (
                              <>
                                {siteCapacityLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={
                                      siteCapacityLimit
                                        ? (siteCapacityLimit * 1000) / 240
                                        : 0
                                    }
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Site Capacity: ${
                                        siteCapacityLimit
                                          ? (
                                              (siteCapacityLimit * 1000) /
                                              240
                                            ).toFixed(2)
                                          : 0
                                      }A`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideRight",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : cORp == "CIRCUIT" ? (
                              <>
                                (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps
                                  }
                                  stroke="red"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Total Circuit Capacity: ${
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps
                                    }A`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideLeft",
                                    dy: -10,
                                    dx: 2,
                                    fill: "red",
                                  }}
                                />
                                ) (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps * 0.8
                                  }
                                  stroke="#c7b300"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Available Circuit Capacity: ${
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps * 0.8
                                    }A`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideRight",
                                    dy: -10,
                                    dx: 2,
                                    fill: "#c7b300",
                                  }}
                                />
                                )
                              </>
                            ) : cORp == "PROFILES" ? (
                              <>
                                {powerLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={
                                      powerLimit ? (powerLimit * 1000) / 240 : 0
                                    }
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Power limit: ${
                                        powerLimit
                                          ? (
                                              (powerLimit * 1000) /
                                              240
                                            )?.toFixed(2)
                                          : 0
                                      }A`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideLeft",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : null}

                            <Brush
                              dataKey="time"
                              height={10}
                              stroke="#0051A2"
                            />
                            <Line
                              type="monotone"
                              dataKey="currentOffered"
                              stroke="#0051A2"
                              dot={false}
                            />
                            {chargerChips
                              ?.filter((obj) => obj.clicked === true)
                              ?.map((single) => {
                                return (
                                  <Line
                                    type="monotone"
                                    dataKey={`${single?.chargerId}/currentOffered`}
                                    stroke={single.color}
                                    dot={false}
                                  />
                                );
                              })}
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <div className="no_data_main">
                        <div className="no_data">
                          <MultilineChartIcon />
                          <p>No Data</p>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={gridSizes[index] ? gridSizes[index] : 6}>
                    {companiesDataLoading ? (
                      <div className="loading_main">
                        <div className="loading_cllm">
                          <CircularProgress size={30} />
                        </div>
                      </div>
                    ) : single?.data.length != 0 ? (
                      <div className="chart_layout">
                        <div className="legendsOfGraph">
                          <div className="legends">
                            <p className="custom-paragraph powerActiveImport">
                              <span>Power active import(kW)</span>
                            </p>
                          </div>
                          {gridSizes[index] == 6 ? (
                            <ZoomOutMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          ) : (
                            <ZoomInMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          )}
                        </div>

                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            data={single?.data}
                            margin={{
                              top: 20,
                              right: 5,
                              left: 5,
                              bottom: 20,
                            }}
                          >
                            <XAxis
                              dataKey="time"
                              tick={<CustomizedAxisTick />}
                              label={{
                                value: `Time Duration`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                position: "bottom",
                                offset: 0,
                              }}
                            />
                            <YAxis
                              label={{
                                value: `Power(kW)`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                angle: -90,
                                position: "left",
                                offset: 0,
                              }}
                              scale="log"
                              domain={["auto", "auto"]}
                              type="number"
                              id="powerActiveImport"
                              style={{ fontSize: "10px", color: "#3c5164" }}
                              tickFormatter={(value) => {
                                if (value % 1 !== 0) {
                                  return value.toFixed(1);
                                }
                                return value;
                              }}
                            />
                            {cORp == "DEPOT" ? (
                              <>
                                {siteCapacityLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={parseInt(siteCapacityLimit)}
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Site capacity: ${parseInt(
                                        siteCapacityLimit
                                      )}kW`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideRight",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : cORp == "CIRCUIT" ? (
                              <>
                                (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    (chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps *
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxVolts) /
                                    1000
                                  }
                                  stroke="red"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Total Circuit Capacity: ${(
                                      (chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps *
                                        chips?.find(
                                          (item) => item.id === single.group
                                        )?.maxVolts) /
                                      1000
                                    )?.toFixed(2)}kW`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideLeft",
                                    dy: -10,
                                    dx: 2,
                                    fill: "red",
                                  }}
                                />
                                ) (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    ((chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps *
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxVolts) /
                                      1000) *
                                    0.8
                                  }
                                  stroke="#c7b300"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Available Circuit Capacity: ${(
                                      ((chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps *
                                        chips?.find(
                                          (item) => item.id === single.group
                                        )?.maxVolts) /
                                        1000) *
                                      0.8
                                    )?.toFixed(2)}kW`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideRight",
                                    dy: -10,
                                    dx: 2,
                                    fill: "#c7b300",
                                  }}
                                />
                                )
                              </>
                            ) : cORp == "PROFILES" && powerLimit ? (
                              <>
                                {multiPowerLimit == false && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={powerLimit}
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Power limit: ${powerLimit?.toFixed(
                                        2
                                      )}kW`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideLeft",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : null}
                            <Tooltip content={<CustomTooltipPowerImport />} />
                            <Brush
                              dataKey="time"
                              height={10}
                              stroke="#0051A2"
                            />
                            <Line
                              type="monotone"
                              dataKey="powerActiveImport"
                              stroke="#82ca9d"
                              dot={false}
                            />
                            {chargerChips
                              ?.filter((obj) => obj.clicked === true)
                              ?.map((single) => {
                                return (
                                  <Line
                                    type="monotone"
                                    dataKey={`${single?.chargerId}/powerActiveImport`}
                                    stroke={single.color}
                                    dot={false}
                                  />
                                );
                              })}
                            {multiPowerLimit == true && cORp == "PROFILES" && (
                              <Line
                                type="monotone"
                                dataKey="powerLimit"
                                stroke="red"
                                strokeDasharray="3 3"
                                dot={false}
                              />
                            )}
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <div className="no_data_main">
                        <div className="no_data">
                          <MultilineChartIcon />
                          <p>No Data</p>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={gridSizes[index] ? gridSizes[index] : 6}>
                    {companiesDataLoading ? (
                      <div className="loading_main">
                        <div className="loading_cllm">
                          <CircularProgress size={30} />
                        </div>
                      </div>
                    ) : single?.data.length != 0 ? (
                      <div className="chart_layout">
                        <div className="legendsOfGraph">
                          <div className="legends">
                            <p className="custom-paragraph powerOffered ">
                              <span>Power offered(kW)</span>
                            </p>
                          </div>
                          {gridSizes[index] == 6 ? (
                            <ZoomOutMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          ) : (
                            <ZoomInMapIcon
                              onClick={() => toggleGridSize(index)}
                              className="zoomInOut"
                            />
                          )}
                        </div>

                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            data={single?.data}
                            margin={{
                              top: 20,
                              right: 5,
                              left: 5,
                              bottom: 20,
                            }}
                          >
                            <XAxis
                              dataKey="time"
                              tick={<CustomizedAxisTick />}
                              label={{
                                value: `Time Duration`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                position: "bottom",
                                offset: 0,
                              }}
                            />
                            <YAxis
                              label={{
                                value: `Power(kW)`,
                                style: {
                                  textAnchor: "middle",
                                  fontSize: "10px",
                                  fill: "#3c5164",
                                },
                                angle: -90,
                                position: "left",
                                offset: 0,
                              }}
                              scale="log"
                              domain={["auto", "auto"]}
                              type="number"
                              id="powerOffered"
                              style={{ fontSize: "10px", color: "#3c5164" }}
                              tickFormatter={(value) => {
                                if (value % 1 !== 0) {
                                  return value.toFixed(1);
                                }
                                return value;
                              }}
                            />
                            {cORp == "DEPOT" ? (
                              <>
                                {siteCapacityLimit && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={parseInt(siteCapacityLimit)}
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Site capacity: ${parseInt(
                                        siteCapacityLimit
                                      )}kW`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideRight",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : cORp == "CIRCUIT" ? (
                              <>
                                (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    (chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps *
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxVolts) /
                                    1000
                                  }
                                  stroke="red"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Total Circuit Capacity: ${(
                                      (chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps *
                                        chips?.find(
                                          (item) => item.id === single.group
                                        )?.maxVolts) /
                                      1000
                                    )?.toFixed(2)}kW`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideLeft",
                                    dy: -10,
                                    dx: 2,
                                    fill: "red",
                                  }}
                                />
                                ) (
                                <ReferenceLine
                                  ifOverflow="extendDomain"
                                  y={
                                    ((chips?.find(
                                      (item) => item.id === single.group
                                    )?.maxAmps *
                                      chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxVolts) /
                                      1000) *
                                    0.8
                                  }
                                  stroke="#c7b300"
                                  strokeDasharray="3 3"
                                  label={{
                                    value: `Available Circuit Capacity: ${(
                                      ((chips?.find(
                                        (item) => item.id === single.group
                                      )?.maxAmps *
                                        chips?.find(
                                          (item) => item.id === single.group
                                        )?.maxVolts) /
                                        1000) *
                                      0.8
                                    )?.toFixed(2)}kW`,
                                    fontWeight: "500",
                                    fontSize: "10px",
                                    position: "insideRight",
                                    dy: -10,
                                    dx: 2,
                                    fill: "#c7b300",
                                  }}
                                />
                                )
                              </>
                            ) : cORp == "PROFILES" && powerLimit ? (
                              <>
                                {multiPowerLimit == false && (
                                  <ReferenceLine
                                    ifOverflow="extendDomain"
                                    y={powerLimit}
                                    stroke="red"
                                    strokeDasharray="3 3"
                                    label={{
                                      value: `Power limit: ${powerLimit.toFixed(
                                        2
                                      )}kW`,
                                      fontWeight: "500",
                                      fontSize: "10px",
                                      position: "insideLeft",
                                      dy: -10,
                                      dx: 2,
                                      fill: "red",
                                    }}
                                  />
                                )}
                              </>
                            ) : null}
                            <Tooltip content={<CustomTooltipPowerOffered />} />
                            <Brush
                              dataKey="time"
                              height={10}
                              stroke="#0051A2"
                            />

                            <Line
                              type="monotone"
                              dataKey="powerOffered"
                              stroke="#0051A2"
                              dot={false}
                            />
                            {chargerChips
                              ?.filter((obj) => obj.clicked === true)
                              ?.map((single) => {
                                return (
                                  <Line
                                    type="monotone"
                                    dataKey={`${single?.chargerId}/powerOffered`}
                                    stroke={single.color}
                                    dot={false}
                                  />
                                );
                              })}

                            {multiPowerLimit == true && cORp == "PROFILES" && (
                              <Line
                                type="monotone"
                                dataKey="powerLimit"
                                stroke="red"
                                strokeDasharray="3 3"
                                dot={false}
                              />
                            )}
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <div className="no_data_main">
                        <div className="no_data">
                          <MultilineChartIcon />
                          <p>No Data</p>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}
      </div>
      <Loader isLoading={companiesDataLoading} />
    </>
  );
};

export default CircuitMonitorModel;
