import React, { useState } from "react";
import {
  Autocomplete,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import Controls from "../../components/controls/Controls";
import DeleteIcon from "@mui/icons-material/Delete";
import "./requests.scss";

const NewBillingPackage = (props) => {
  const { values, AllRatePlans, handleSubmit, sideDrawerData, packageDetails } =
    props;
  const RatePlansList = (props) => {
    const { array } = props;
    array.sort();
    if (array.length) {
      return array.map((element) => {
        return (
          <div key={element[0]} className="ratePlansList">
            <Grid container>
              <Grid item xs={11}>
                <Divider />
                <p className="ratePlan0">{element[0]}</p>
                <p className="ratePlan1">{element[1]}</p>
              </Grid>
              <Grid item xs={1}>
                <Divider />
                <Controls.ActionButton
                  className="ratePlanSubmit"
                  onClick={() => {
                    handleSubmit("delete", element[0]);
                  }}
                >
                  <DeleteIcon style={{ align: "center" }} color="error" />
                </Controls.ActionButton>
              </Grid>
            </Grid>
          </div>
        );
      });
    } else {
      return (
        <>
          <Divider />
          <p key={"Nonbillable"} className="nonBillable">
            Non billable
          </p>
        </>
      );
    }
  };
  const Grids = (props) => {
    const { title, value } = props;
    return (
      <Grid container className="requestsGrid">
        <Grid item xs={12}>
          <p className="title">{title}</p>
        </Grid>
        <Grid item xs={12}>
          <RatePlansList array={values[value]} />
        </Grid>
      </Grid>
    );
  };
  const getSecondaryText = (item) => {
    if (item === "SKU-00000294") return "Management Base • AC,DC";
    else if (item === "SKU-P0000544") return "Management  Base • AC";
    else if (item === "SKU-00000295") return "Management  Elite • AC,DC";
    else if (item === "SKU-P0000507") return "Premium Warranty • AC";
    else if (item === "SKU-P0000508") return "Premium Warranty • DC";
    else if (item === "SKU-P0000509") return "Preventative Maintenance • AC,DC";
  };

  const [selectedSKU, setSelectedSKU] = useState(null);
  const [SKUOptions, setSKUOptions] = useState([]);
  const [ratePlanOptions, setRatePlanOptions] = useState([]);
  const [selectedRatePlan, setSelectedRatePlan] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const handleInputChange = (props) => {
    if (props.target.name === "sku") {
      setSelectedSKU(props.target.value);
      setSelectedRatePlan(null);
    } else setSelectedRatePlan(props.target.value);
  };
  React.useEffect(() => {
    let originalSKUOptions = AllRatePlans.reduce((acc, item) => {
      item.sku !== "SKU-P0000574" &&
        item.sku !== "SKU-P0000510" &&
        item.sku !== "SKU-00000296" &&
        item.sku !== "SKU-P0000506" &&
        !acc.includes(item.sku) &&
        acc.push(item.sku);
      return acc;
    }, []);
    setSKUOptions(originalSKUOptions);
  }, [AllRatePlans]);
  React.useEffect(() => {
    if (selectedSKU) {
      setRatePlanOptions(
        getUniqueListBy(
          AllRatePlans.filter((item) => item.sku === selectedSKU),
          "productRatePlanChargeName"
        )
      );
    }
  }, [selectedSKU]);

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };
  return (
    <>
      <div className="newBillingPkg">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <p className="depotNameKey">DEPOT NAME</p>
            <p className="depotNameValue">{sideDrawerData.depotName} </p>
          </Grid>
          <Grid item xs={6}>
            <p className="portsKey">NO. OF PORTS</p>
            <p className="portsValue">{sideDrawerData.portsCount}</p>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              label="SKU"
              id="sku"
              name="sku"
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              value={selectedSKU}
              options={SKUOptions}
              onChange={(event, newValue) => {
                handleInputChange({ target: { name: "sku", value: newValue } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="sku"
                  label="SKU"
                  size="small"
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => {
                return (
                  <MenuItem key={option} value={option} {...props}>
                    <ListItemText
                      primary={option}
                      secondary={getSecondaryText(option)}
                    />
                  </MenuItem>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.AutoSuggestion
              label="Rate plan"
              name="ratePlan"
              value={selectedRatePlan}
              options={ratePlanOptions
                .filter((ele) => ele.sku === selectedSKU)
                .map((item) => item.productRatePlanChargeName)
                .sort()}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid container justifyContent="flex-end">
            <div className="actionBtns">
              <Controls.Button
                type="button"
                text="Cancel"
                variant="outlined"
                size="small"
                disabled={!selectedSKU}
                onClick={() => {
                  setSelectedSKU(null);
                  setSelectedRatePlan(null);
                }}
              />
              <Controls.Button
                type="button"
                text="Update"
                size="small"
                disabled={!selectedRatePlan}
                onClick={() => {
                  setSelectedSKU(null);
                  setSelectedRatePlan(null);
                  handleSubmit("add", [selectedSKU, selectedRatePlan]);
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div className="divider">
          <Divider />
        </div>
        <Grids title={"MANAGEMENT"} value={"management"} />
        <Grids
          title={"PREVENTATIVE MAINTENANCE"}
          value={"preventativeMaintenance"}
        />
        <Grids title={"WARRANTY"} value={"premiumWarranty"} />
      </div>
    </>
  );
};

export default NewBillingPackage;
