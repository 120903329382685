import React from "react";
import NewPageMainContent from "../../components/NewPageMainContent";
import Loader from "../../components/Loader";
import { Divider } from "@mui/material";
import Controls from "../../components/controls/Controls";
import BulkUploadSteps from "./BulkUploadSteps";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export default function BulkUploadAndPreview({
  name,
  headers,
  templateFilename,
  loading,
  handleBulkUpload,
  handleCancelUpload,
  uploadFilename,
  tableData,
  UploadPreviewCSV,
  submitBtnLabel = "Submit",
  refGuideName = "",
  refGuideFile = null,
}) {
  return (
    <NewPageMainContent>
      <Loader isLoading={loading} />
      <div className="bulkUpload">
        <div className="previewContainer">
          <p className="title">Preview</p>
          <Divider />
          <div className="previewInner">
            {uploadFilename?.length === 0 && (
              <>
                <UploadFileIcon fontSize="large" />
                <p className="heading">
                  Select a CSV file with {name} to review and upload.
                </p>
                <p className="subHeading">
                  Refer to the template and the guide provided to make sure the
                  CSV file follows all the guidelines.
                </p>
              </>
            )}
            <UploadPreviewCSV
              headers={headers?.map((item) => item.key)}
              showErrorsWithPreview={true}
              showPreviewLabel={false}
            />
          </div>
          {tableData?.length > 0 && (
            <div className="bulkUploadValidate">
              <Controls.Button
                text="Cancel"
                variant="outlined"
                onClick={handleCancelUpload}
              />
              <Controls.Button
                text={submitBtnLabel}
                onClick={handleBulkUpload}
              />
            </div>
          )}
        </div>
        <div className="instruction">
          <p className="title">Guide to bulk upload {name}</p>
          <Divider />
          <div className="paddingGrp">
            <BulkUploadSteps
              headers={headers}
              filename={templateFilename}
              refGuideName={refGuideName}
              refGuideFile={refGuideFile}
            />
          </div>
        </div>
      </div>
    </NewPageMainContent>
  );
}
