import axios from "axios";
import { getAccessToken, getData } from "../../../utils/Storage";
import { clearLocalStorage } from "../../../utils/Storage";

export const AxiosInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      const token = await getAccessToken();
      if (token?.accessToken && !config.url.includes(":8001")) {
        config.headers.Authorization = `Bearer ${token?.accessToken}`;
        config.headers.idToken = token?.idToken;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    function (err) {
      setTimeout(() => {
        if (err.response.status === 401) {
          try {
            clearLocalStorage();
            window.location.href = "/";
          } catch (error) {
            console.log("error signing out:", error);
          }
        }
      }, 8000);
      return Promise.reject(err);
    }
  );
};

export default AxiosInterceptor;
