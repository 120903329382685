import React from "react";
import notFoundLogo from "../assets/images/svg/404.svg";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../components/controls/Controls";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  typography: {
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-40%,-80%)",
  },
  paper: {
    minHeight: "93vh",
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.typography}>
          <img
            src={notFoundLogo}
            alt="Electriphi Logo"
            className={classes.logo}
          />
          <Typography variant="h4" gutterBottom color="primary">
            The page you are looking for does not exist!
          </Typography>
          <Link to="/" className="caption">
            {" "}
            <Controls.Button type="submit" text={"Click to go Back"} />
          </Link>
        </div>
      </Paper>
    </>
  );
};

export default NotFound;
