export const CLLMDATA = () => [
  {
    data: [
      {
        currentImport: 3.7,
        currentOffered: 477,
        powerActiveImport: 0.6,
        powerOffered: 99.22,
        time: "12 Feb, 12:26 PM",
      },
      {
        currentImport: 16.4,
        currentOffered: 1668,
        powerActiveImport: 1.61,
        powerOffered: 346.94,
        time: "12 Feb, 12:27 PM",
      },
      {
        currentImport: 21.2,
        currentOffered: 1668,
        powerActiveImport: 2.67,
        powerOffered: 346.94,
        time: "12 Feb, 12:28 PM",
      },
      {
        currentImport: 84.3,
        currentOffered: 1598,
        powerActiveImport: 16.23,
        powerOffered: 332.38,
        time: "12 Feb, 12:29 PM",
      },
      {
        currentImport: 16.4,
        currentOffered: 1668,
        powerActiveImport: 1.61,
        powerOffered: 346.94,
        time: "12 Feb, 12:30 PM",
      },
      {
        currentImport: 77.6,
        currentOffered: 1596,
        powerActiveImport: 14.78,
        powerOffered: 331.97,
        time: "12 Feb, 12:31 PM",
      },
      {
        currentImport: 16.4,
        currentOffered: 1668,
        powerActiveImport: 1.61,
        powerOffered: 346.94,
        time: "12 Feb, 12:32 PM",
      },
      {
        currentImport: 16.4,
        currentOffered: 1668,
        powerActiveImport: 1.61,
        powerOffered: 346.94,
        time: "12 Feb, 12:33 PM",
      },
      {
        currentImport: 21.4,
        currentOffered: 1668,
        powerActiveImport: 2.67,
        powerOffered: 346.94,
        time: "12 Feb, 12:34 PM",
      },
      {
        currentImport: 16.4,
        currentOffered: 1668,
        powerActiveImport: 1.61,
        powerOffered: 346.94,
        time: "12 Feb, 12:35 PM",
      },
      {
        currentImport: 16.3,
        currentOffered: 1668,
        powerActiveImport: 1.59,
        powerOffered: 346.94,
        time: "12 Feb, 12:36 PM",
      },
      {
        currentImport: 22.2,
        currentOffered: 1668,
        powerActiveImport: 2.85,
        powerOffered: 346.94,
        time: "12 Feb, 12:37 PM",
      },
      {
        currentImport: 16.4,
        currentOffered: 1596,
        powerActiveImport: 1.61,
        powerOffered: 331.97,
        time: "12 Feb, 12:38 PM",
      },
      {
        currentImport: 16.3,
        currentOffered: 1668,
        powerActiveImport: 1.59,
        powerOffered: 346.94,
        time: "12 Feb, 12:39 PM",
      },
      {
        currentImport: 22.3,
        currentOffered: 1668,
        powerActiveImport: 2.87,
        powerOffered: 346.94,
        time: "12 Feb, 12:40 PM",
      },
      {
        currentImport: 16.5,
        currentOffered: 1668,
        powerActiveImport: 1.63,
        powerOffered: 346.94,
        time: "12 Feb, 12:41 PM",
      },
      {
        currentImport: 16.4,
        currentOffered: 1668,
        powerActiveImport: 1.63,
        powerOffered: 346.94,
        time: "12 Feb, 12:42 PM",
      },
      {
        currentImport: 16.3,
        currentOffered: 1668,
        powerActiveImport: 1.61,
        powerOffered: 346.94,
        time: "12 Feb, 12:43 PM",
      },
      {
        currentImport: 22.6,
        currentOffered: 1668,
        powerActiveImport: 2.96,
        powerOffered: 346.94,
        time: "12 Feb, 12:44 PM",
      },
      {
        currentImport: 16.3,
        currentOffered: 1668,
        powerActiveImport: 1.61,
        powerOffered: 346.94,
        time: "12 Feb, 12:45 PM",
      },
      {
        currentImport: 21.4,
        currentOffered: 1668,
        powerActiveImport: 2.7,
        powerOffered: 346.94,
        time: "12 Feb, 12:46 PM",
      },
      {
        currentImport: 16.3,
        currentOffered: 1668,
        powerActiveImport: 1.59,
        powerOffered: 346.94,
        time: "12 Feb, 12:47 PM",
      },
      {
        currentImport: 21.5,
        currentOffered: 1668,
        powerActiveImport: 2.7,
        powerOffered: 346.94,
        time: "12 Feb, 12:48 PM",
      },
      {
        currentImport: 9.4,
        currentOffered: 1668,
        powerActiveImport: 1.63,
        powerOffered: 346.94,
        time: "12 Feb, 12:49 PM",
      },
      {
        currentImport: 21.4,
        currentOffered: 1668,
        powerActiveImport: 2.67,
        powerOffered: 346.94,
        time: "12 Feb, 12:50 PM",
      },
      {
        currentImport: 68,
        currentOffered: 1668,
        powerActiveImport: 12.58,
        powerOffered: 346.94,
        time: "12 Feb, 12:51 PM",
      },
      {
        currentImport: 59.1,
        currentOffered: 1668,
        powerActiveImport: 10.72,
        powerOffered: 346.94,
        time: "12 Feb, 12:52 PM",
      },
      {
        currentImport: 100.2,
        currentOffered: 1669,
        powerActiveImport: 19.28,
        powerOffered: 347.15,
        time: "12 Feb, 12:53 PM",
      },
      {
        currentImport: 92.8,
        currentOffered: 1669,
        powerActiveImport: 18.05,
        powerOffered: 347.15,
        time: "12 Feb, 12:54 PM",
      },
      {
        currentImport: 51.9,
        currentOffered: 1668,
        powerActiveImport: 9.2,
        powerOffered: 346.94,
        time: "12 Feb, 12:55 PM",
      },
      {
        currentImport: 45.3,
        currentOffered: 1668,
        powerActiveImport: 7.79,
        powerOffered: 346.94,
        time: "12 Feb, 12:56 PM",
      },
      {
        currentImport: 39.8,
        currentOffered: 1668,
        powerActiveImport: 6.64,
        powerOffered: 346.94,
        time: "12 Feb, 12:57 PM",
      },
      {
        currentImport: 40.2,
        currentOffered: 1668,
        powerActiveImport: 6.72,
        powerOffered: 346.94,
        time: "12 Feb, 12:58 PM",
      },
      {
        currentImport: 39.9,
        currentOffered: 1668,
        powerActiveImport: 6.64,
        powerOffered: 346.94,
        time: "12 Feb, 12:59 PM",
      },
      {
        currentImport: 39.7,
        currentOffered: 1668,
        powerActiveImport: 6.59,
        powerOffered: 346.94,
        time: "12 Feb, 01:00 PM",
      },
      {
        currentImport: 39.3,
        currentOffered: 1668,
        powerActiveImport: 6.51,
        powerOffered: 346.94,
        time: "12 Feb, 01:01 PM",
      },
      {
        currentImport: 38.9,
        currentOffered: 1668,
        powerActiveImport: 6.45,
        powerOffered: 346.94,
        time: "12 Feb, 01:02 PM",
      },
      {
        currentImport: 38.8,
        currentOffered: 1668,
        powerActiveImport: 6.41,
        powerOffered: 346.94,
        time: "12 Feb, 01:03 PM",
      },
      {
        currentImport: 16.6,
        currentOffered: 1668,
        powerActiveImport: 1.64,
        powerOffered: 346.94,
        time: "12 Feb, 01:04 PM",
      },
      {
        currentImport: 16.5,
        currentOffered: 1668,
        powerActiveImport: 1.64,
        powerOffered: 346.94,
        time: "12 Feb, 01:05 PM",
      },
      {
        currentImport: 16.5,
        currentOffered: 1667,
        powerActiveImport: 1.64,
        powerOffered: 346.74,
        time: "12 Feb, 01:06 PM",
      },
      {
        currentImport: 16.5,
        currentOffered: 1667,
        powerActiveImport: 1.64,
        powerOffered: 346.74,
        time: "12 Feb, 01:07 PM",
      },
      {
        currentImport: 16.4,
        currentOffered: 1666,
        powerActiveImport: 1.61,
        powerOffered: 346.53,
        time: "12 Feb, 01:08 PM",
      },
      {
        currentImport: 16.3,
        currentOffered: 1666,
        powerActiveImport: 1.59,
        powerOffered: 346.53,
        time: "12 Feb, 01:09 PM",
      },
      {
        currentImport: 16.5,
        currentOffered: 1668,
        powerActiveImport: 1.64,
        powerOffered: 346.94,
        time: "12 Feb, 01:10 PM",
      },
      {
        currentImport: 16.2,
        currentOffered: 1596,
        powerActiveImport: 1.59,
        powerOffered: 331.97,
        time: "12 Feb, 01:11 PM",
      },
      {
        currentImport: 21.5,
        currentOffered: 1668,
        powerActiveImport: 2.7,
        powerOffered: 346.94,
        time: "12 Feb, 01:12 PM",
      },
      {
        currentImport: 16.5,
        currentOffered: 1668,
        powerActiveImport: 1.64,
        powerOffered: 346.94,
        time: "12 Feb, 01:13 PM",
      },
      {
        currentImport: 16.5,
        currentOffered: 1668,
        powerActiveImport: 1.64,
        powerOffered: 346.94,
        time: "12 Feb, 01:14 PM",
      },
      {
        currentImport: 21.5,
        currentOffered: 1668,
        powerActiveImport: 2.7,
        powerOffered: 346.94,
        time: "12 Feb, 01:15 PM",
      },
      {
        currentImport: 58,
        currentOffered: 1668,
        powerActiveImport: 10.64,
        powerOffered: 346.94,
        time: "12 Feb, 01:16 PM",
      },
      {
        currentImport: 66.1,
        currentOffered: 1668,
        powerActiveImport: 12.21,
        powerOffered: 346.94,
        time: "12 Feb, 01:17 PM",
      },
      {
        currentImport: 62.9,
        currentOffered: 1668,
        powerActiveImport: 13.06,
        powerOffered: 346.94,
        time: "12 Feb, 01:18 PM",
      },
      {
        currentImport: 56.6,
        currentOffered: 1668,
        powerActiveImport: 10.21,
        powerOffered: 346.94,
        time: "12 Feb, 01:19 PM",
      },
      {
        currentImport: 55.9,
        currentOffered: 1668,
        powerActiveImport: 10.08,
        powerOffered: 346.94,
        time: "12 Feb, 01:20 PM",
      },
      {
        currentImport: 56.9,
        currentOffered: 1668,
        powerActiveImport: 10.28,
        powerOffered: 346.94,
        time: "12 Feb, 01:21 PM",
      },
      {
        currentImport: 55.3,
        currentOffered: 1668,
        powerActiveImport: 9.95,
        powerOffered: 346.94,
        time: "12 Feb, 01:22 PM",
      },
      {
        currentImport: 53.9,
        currentOffered: 1668,
        powerActiveImport: 9.64,
        powerOffered: 346.94,
        time: "12 Feb, 01:23 PM",
      },
      {
        currentImport: 53.9,
        currentOffered: 1667,
        powerActiveImport: 9.67,
        powerOffered: 346.74,
        time: "12 Feb, 01:24 PM",
      },
      {
        currentImport: 50.2,
        currentOffered: 1667,
        powerActiveImport: 8.89,
        powerOffered: 346.74,
        time: "12 Feb, 01:25 PM",
      },
    ],
    group: "all",
  },
];
