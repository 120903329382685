import ApiCore from "./utilities/core.js";
import { apiProvider, indexedEndPoints } from "./utilities/provider";

const url = "ford";
const { USAGE, COMPANY, UPDATE_SPECIFIC_DEPOT, BILLING_HISTORY } =
  indexedEndPoints;

const apiBilling = new ApiCore({
  getAll: true,
  url,
});

apiBilling.postUsage = (payload) => {
  return apiProvider.post(USAGE, payload);
};

apiBilling.updateBillingPackage = (id, payload) => {
  return apiProvider.put(UPDATE_SPECIFIC_DEPOT.replace("id", id), payload);
};
apiBilling.updateBillingPackage2 = (id, payload) => {
  return apiProvider.put(id, payload);
};

apiBilling.updateCustomerRatePlan = (id, payload) => {
  return apiProvider.put(`${COMPANY}/${id}`, payload);
};

apiBilling.billingHistory = (companyId) => {
  return apiProvider.getAll(BILLING_HISTORY.replace("id", companyId));
};

apiBilling.inspectFailure = (batchId) => {
  return apiProvider.getAll(`${USAGE}/${batchId}`);
};

export default apiBilling;
