import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import "./Components.scss";
import { CSVLink } from "react-csv";
import Controls from "./controls/Controls";
const ExportCSV = (props) => {
  const {
    setOpenPopup,
    data,
    allColumnsHeaders,
    headers,
    setHeaders,
    fileName,
  } = props;
  const [columnType, setColumnType] = React.useState("all");
  const [customHeader, setCustomHeader] = React.useState([]);

  const handleChange = (event) => {
    setHeaders(
      headers.map((element) => {
        if (element.name === event.target.name)
          element.checked = event.target.checked;
        return element;
      })
    );
  };

  const CheckBoxFields = (props) => {
    const { headers, handleChange } = props;
    if (headers) {
      return headers.map((element) => {
        return (
          <Grid item xs={6} key={element.key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={element.checked}
                  onChange={handleChange}
                  name={element.name}
                  key={element.key}
                />
              }
              label={element.name}
              style={{ width: "100%" }}
              key={element.key}
            />
          </Grid>
        );
      });
    }
  };
  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    let filtered = headers.filter((element) => {
      element.checked = isFirstRender.current ? false : element.checked;
      if (element.checked) return true;
    });
    let temp = [];
    filtered?.map((single) => {
      let tempObj = {};
      tempObj["label"] = single.name;
      tempObj["key"] = single.key;
      temp.push(tempObj);
    });
    isFirstRender.current = false;
    setCustomHeader(temp);
  }, [headers]);

  const handleColumnType = (event) => {
    setColumnType(event.target.value);
  };
  return (
    <>
      <div className="popup_download_layout">
        <p style={{ margin: "0 0 5px 0" }}>Columns</p>
        <div>
          <Select
            value={columnType}
            onChange={handleColumnType}
            style={{ minWidth: "200px" }}
          >
            <MenuItem value={"all"}>All </MenuItem>
            <MenuItem value={"custom"}>Custom</MenuItem>
          </Select>
        </div>
        {columnType === "custom" ? (
          <FormControl
            component="fieldset"
            variant="standard"
            style={{ width: "100%" }}
          >
            <FormGroup>
              <Grid container>
                <CheckBoxFields headers={headers} handleChange={handleChange} />
              </Grid>
            </FormGroup>
            <FormHelperText>
              Only the checked columns will be downloaded, and minimum 2 columns
              must be selected
            </FormHelperText>
          </FormControl>
        ) : (
          <p>
            {headers.map(
              (element, index) => (index ? ", " : "") + element.name
            )}
          </p>
        )}

        <p className="exportCSVCount">
          Exporting <span className="exportCSVCountNumber">{data?.length}</span>{" "}
          records
        </p>
        <Grid container justifyContent="flex-end" style={{ columnGap: "20px" }}>
          <Grid item xs={2}>
            <div style={{ textAlign: "end" }}>
              <Controls.Button
                variant="outlined"
                text="Cancel"
                onClick={() => {
                  isFirstRender.current = true;
                  setOpenPopup({
                    isOpen: false,
                    title: "Export CSV",
                    child: "exportCSV",
                  });
                }}
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div aria-label="true" style={{ textAlign: "end" }}>
              {columnType === "all" ? (
                <CSVLink
                  data={data}
                  headers={allColumnsHeaders}
                  style={{ textDecoration: "none" }}
                  filename={fileName}
                  data-testid="csvLink"
                  onClick={() => {
                    isFirstRender.current = true;
                    setOpenPopup({
                      isOpen: false,
                      title: "Export CSV",
                      child: "exportCSV",
                    });
                  }}
                >
                  <Controls.Button text={"Export"} />
                </CSVLink>
              ) : (
                customHeader.length > 1 && (
                  <CSVLink
                    data={data}
                    headers={customHeader}
                    style={{ textDecoration: "none" }}
                    filename={fileName}
                    data-testid="csvLink"
                    onClick={() => {
                      isFirstRender.current = true;
                      setOpenPopup({
                        isOpen: false,
                        title: "Export CSV",
                        child: "exportCSV",
                      });
                    }}
                  >
                    <Controls.Button text={"Export"} />
                  </CSVLink>
                )
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ExportCSV;
