import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Switch,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ScienceIcon from "@mui/icons-material/Science";
import InfoIcon from "@mui/icons-material/Info";
import Controls from "../../../components/controls/Controls";
import { Form, UseForm } from "../../../components/UseForm";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Loader from "../../../components/Loader";
import { validateNonEmptyFields } from "../../../utils/FieldValidations";
import { useLocation } from "react-router-dom";
import { Autocomplete, TextField, Button } from "@mui/material";
import { messages } from "../../../constants/Messages";

const AddEditCharger = ({
  type,
  openPopup,
  setOpenPopup,
  setRefreshChargers,
  setToast,
  chargerId,
  errorCodeGroups,
}) => {
  const { CHARGERS } = indexedEndPoints;
  const [loading, setLoading] = useState(false);

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const manufacturer = searchParams.get("manufacturer");

  const addEditInitialValues = useMemo(
    () => ({
      manufacturer:
        type === "updateCharger" ? openPopup?.item?.manufacturer : manufacturer,
      model: type === "updateCharger" ? openPopup?.item?.model : "",
      isRfidCapable:
        type === "updateCharger" ? openPopup?.item?.isRfidCapable : false,
      minChargingRate:
        type === "updateCharger" ? openPopup?.item?.minChargingRate : "",
      maxChargingRate:
        type === "updateCharger" ? openPopup?.item?.maxChargingRate : "",
      maxVoltage: type === "updateCharger" ? openPopup?.item?.maxVoltage : "",
      numberOfPorts:
        type === "updateCharger" ? openPopup?.item?.numberOfPorts : "",
      maxAmps: type === "updateCharger" ? openPopup?.item?.maxAmps : "",
      chargingRateUnit:
        type === "updateCharger" ? openPopup?.item?.chargingRateUnit : "",
      networkType: type === "updateCharger" ? openPopup?.item?.networkType : "",
      typeOfConnectorList:
        type === "updateCharger" ? openPopup?.item?.typeOfConnectorList : [],
      errorCodeGroupName:
        type === "updateCharger" ? openPopup?.item?.errorCodeGroupName : "",
      powerType: type === "updateCharger" ? openPopup?.item?.powerType : "",
      maxTemperature:
        type === "updateCharger" ? openPopup?.item?.temperatureRange?.max : "",
      minTemperature:
        type === "updateCharger" ? openPopup?.item?.temperatureRange?.min : "",
      isCustomerVisible:
        type === "updateCharger" ? openPopup?.item?.isCustomerVisible : true,
      isWhiteLabelled:
        type === "updateCharger" ? openPopup?.item?.isWhiteLabelled : false,
      isEVCCIdCapable:
        type === "updateCharger" ? openPopup?.item?.isEVCCIdCapable : false,
      rfidConfig:
        type === "updateCharger"
          ? openPopup?.item?.rfidConfig || [
              { key: "", freevendValue: "", authValue: "" },
            ]
          : [{ key: "", freevendValue: "", authValue: "" }],
      soldAs:
        type === "updateCharger"
          ? openPopup?.item?.soldAs
          : [{ whiteLabelManufacturer: "", whiteLabelModel: "" }],
    }),
    [openPopup]
  );

  const validateNumberFields = (field) => {
    for (let [key, value] of Object.entries(field)) {
      if (key !== "minTemperature" && value < 0)
        setValues({ ...values, [key]: Math.abs(value) });
      if ("maxAmps" === key && value > 500)
        setErrors({ ...errors, [key]: "Maximum limit is 500 Amps" });
      else setErrors({ ...errors, [key]: "" });
      if ("maxChargingRate" === key && value > 500)
        setErrors({ ...errors, [key]: "Maximum limit is 500 kW" });
      else setErrors({ ...errors, [key]: "" });
    }
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues, true, validateNumberFields);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const fields = Object.keys(addEditInitialValues);
      if (errors["maxAmps"] && errors["maxAmps"] !== "") return;
      const validData = validateNonEmptyFields(
        fields?.filter(
          (item) =>
            item !== "minTemperature" &&
            item !== "maxTemperature" &&
            item !== "rfidConfig" &&
            item !== "soldAs"
        ),
        values,
        setErrors
      );

      if (validData) setErrors({});
      else return;

      const payload = {
        manufacturer: manufacturer,
        model: values.model?.trim(),
        networkType: values.networkType?.trim(),
        minChargingRate: values.minChargingRate,
        maxChargingRate: values.maxChargingRate,
        maxAmps: values.maxAmps,
        maxVoltage: values.maxVoltage,
        isRfidCapable: values.isRfidCapable,
        chargingRateUnit: values.chargingRateUnit,
        numberOfPorts: values.numberOfPorts,
        powerType: values.powerType,
        typeOfConnectorList: values.typeOfConnectorList,
        errorCodeGroupName: values.errorCodeGroupName,
        errorCodeGroupId: errorCodeGroups?.find(
          (item) => item.groupName === values.errorCodeGroupName
        )?.groupId,
        isCustomerVisible: values.isCustomerVisible,
        isEVCCIdCapable: values.isEVCCIdCapable,
        temperatureRange: {
          max: values.maxTemperature,
          min: values.minTemperature,
          unit: "celsius",
        },
        portConfig: {},
      };
      if (values.isRfidCapable) payload["rfidConfig"] = values.rfidConfig;
      if (
        values.isWhiteLabelled &&
        values["soldAs"][0]?.whiteLabelManufacturer?.length > 0 &&
        values["soldAs"][0]?.whiteLabelModel?.length > 0
      )
        payload["soldAs"] = values.soldAs;
      else
        payload["soldAs"] = [
          {
            whiteLabelManufacturer: payload.manufacturer,
            whiteLabelModel: payload.model,
          },
        ];

      setLoading(true);
      if (type === "addCharger") {
        const response = await apiProvider.post(CHARGERS, [payload]);
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Charger added successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshChargers(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.data?.data,
            type: "error",
          });
        }
      } else {
        const response = await apiProvider.put(
          `${CHARGERS}/${chargerId}`,
          payload
        );
        if (response.statusCode >= 200 && response.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Charger updated successfully",
            type: "success",
          });
          setOpenPopup((op) => ({ ...op, isOpen: false }));
          setRefreshChargers(true);
        } else {
          setToast({
            isOpen: true,
            message: response?.data?.data,
            type: "error",
          });
        }
      }
      setLoading(false);
    },
    [values]
  );

  return (
    <div className="chargeDetailsSideDrawer" id="totalHeight">
      <Form extraStyles={{ height: "inherit", overflowY: "scroll" }}>
        <Loader isLoading={loading} />
        <div className="charger_details_section" id="rfidAuthModeContainer">
          <Grid container alignItems="center" margin={"10px 0"}>
            <Grid item xs={6}>
              <Controls.Input
                disabled
                name="manufacturer"
                label="Manufacturer*"
                value={values.manufacturer}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="model"
                label="Model*"
                value={values.model}
                error={errors.model}
                onChange={handleFormInputChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                type="number"
                name="minChargingRate"
                label="Min Charging Rate (kW)*"
                value={values.minChargingRate}
                onChange={handleFormInputChange()}
                error={errors.minChargingRate}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                type="number"
                name="maxChargingRate"
                label="Max Charging Rate (kW)*"
                value={values.maxChargingRate}
                onChange={handleFormInputChange()}
                error={errors.maxChargingRate}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                type="number"
                name="maxVoltage"
                label="Max Voltage (V)*"
                value={values.maxVoltage}
                onChange={handleFormInputChange()}
                error={errors.maxVoltage}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                type="number"
                name="maxAmps"
                label="Max Amps*"
                value={values.maxAmps}
                onChange={handleFormInputChange()}
                error={errors.maxAmps}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                type="number"
                name="numberOfPorts"
                label="Number of Ports*"
                value={values.numberOfPorts}
                onChange={handleFormInputChange()}
                error={errors.numberOfPorts}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="chargingRateUnit"
                label="Charging Rate Unit*"
                data-testid="chargingRateUnit"
                options={["W", "A"]}
                value={values.chargingRateUnit}
                onChange={handleFormInputChange()}
                error={errors.chargingRateUnit}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="networkType"
                label="Network Type*"
                data-testid="networkType"
                options={["Ethernet", "Wifi", "3G", "4G/LTE"]}
                value={values.networkType}
                onChange={handleFormInputChange()}
                error={errors.networkType}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="powerType"
                label="Power Type*"
                data-testid="powerType"
                options={["AC", "DC"]}
                value={values.powerType}
                onChange={handleFormInputChange()}
                error={errors.powerType}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                size="small"
                multiple
                getOptionLabel={(item) => item.label}
                value={values.typeOfConnectorList?.map((item) => ({
                  label: item,
                  value: item,
                }))}
                ListboxProps={{ "data-testid": "typeOfConnector" }}
                options={[
                  "J1772 type 1",
                  "J1772 type 2",
                  "CCS1",
                  "CCS2",
                  "CHAdeMO",
                ].map((item) => ({
                  label: item,
                  value: item,
                }))}
                onChange={(_, value) =>
                  setValues({
                    ...values,
                    typeOfConnectorList: value?.map((item) => item.value),
                  })
                }
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Type of Connector*"
                    placeholder="Type of Connector"
                    error={errors.typeOfConnectorList}
                    helperText={errors.typeOfConnectorList}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="errorCodeGroupName"
                label="Error Code Group*"
                data-testid="errorCodeGroupName"
                options={errorCodeGroups?.map((item) => item.groupName)}
                value={values.errorCodeGroupName}
                onChange={handleFormInputChange()}
                error={errors.errorCodeGroupName}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                type="number"
                name="minTemperature"
                label="Min Temperature (celsius)"
                value={values.minTemperature}
                onChange={handleFormInputChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                type="number"
                name="maxTemperature"
                label="Max Temperature (celsius)"
                value={values.maxTemperature}
                onChange={handleFormInputChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="switchBox">
                <p>Charger is RFID Capable</p>
                <Switch
                  name="isRfidCapable"
                  checked={values.isRfidCapable}
                  onChange={(e) => {
                    setValues({ ...values, isRfidCapable: e.target.checked });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="switchBox">
                <p>Charger is Customer Visible</p>
                <Switch
                  name="isCustomerVisible"
                  checked={values.isCustomerVisible}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      isCustomerVisible: e.target.checked,
                    });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="switchBox">
                <div>
                  <p className="whitelabelTitle">Charger is white labelled</p>
                  <p className="whitelabel">
                    Customers will see white label names as manufacturer and
                    model in CMS
                  </p>
                </div>
                <Switch
                  name="isWhiteLabelled"
                  checked={values.isWhiteLabelled}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      isWhiteLabelled: e.target.checked,
                    });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="switchBox">
                <p>Charger is EVCC ID Capable</p>
                <Switch
                  name="isEVCCIdCapable"
                  checked={values.isEVCCIdCapable}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      isEVCCIdCapable: e.target.checked,
                    });
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {values.isRfidCapable && (
          <div className="charger_details_section" id="rfidAuthModeContainer">
            <div className="charger_heading">
              <p>RFID AUTHENTICATION CONFIGURATION</p>
            </div>
            <div className="rfidTable">
              <Grid container className="rfidAuthModeHeading">
                <Grid item xs={4}></Grid>
                <Grid item xs={3}>
                  Freevend mode
                </Grid>
                <Grid item xs={4}>
                  Authentication mode
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
              {values?.rfidConfig?.map((row, ind) => (
                <Grid
                  container
                  key={ind}
                  spacing={0}
                  className="rfidAuthModeRows"
                >
                  <Grid item xs={4} className="rfidAuthModeKey">
                    <Controls.Input
                      variant="standard"
                      name="rfidAuthKey"
                      testid={`rfidAuthKey${ind}`}
                      InputProps={{ disableUnderline: true }}
                      value={row?.key}
                      onChange={(e) => {
                        let res = [...values.rfidConfig];
                        res[ind].key = e.target.value;
                        setValues({ ...values, rfidConfig: res });
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controls.Input
                      variant="standard"
                      name="rfidFreevendValue"
                      testid={`rfidFreevendValue${ind}`}
                      InputProps={{ disableUnderline: true }}
                      value={row?.freevendValue}
                      onChange={(e) => {
                        let res = [...values.rfidConfig];
                        res[ind].freevendValue = e.target.value;
                        setValues({ ...values, rfidConfig: res });
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controls.Input
                      variant="standard"
                      name="rfidAuthValue"
                      testid={`rfidAuthValue${ind}`}
                      InputProps={{ disableUnderline: true }}
                      value={row?.authValue}
                      onChange={(e) => {
                        let res = [...values.rfidConfig];
                        res[ind].authValue = e.target.value;
                        setValues({ ...values, rfidConfig: res });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Controls.ActionButton
                      data-testid={`deleteKeyBtn${ind}`}
                      onClick={() =>
                        setValues({
                          ...values,
                          rfidConfig:
                            values.rfidConfig?.length === 1
                              ? [{ key: "", freevendValue: "", authValue: "" }]
                              : values.rfidConfig?.filter((_, i) => i !== ind),
                        })
                      }
                    >
                      <DeleteIcon />
                    </Controls.ActionButton>
                  </Grid>
                </Grid>
              ))}
              <Controls.Button
                text="Add Key"
                disabled={values.rfidConfig?.some((row) => !row.key?.length)}
                variant="outlined"
                startIcon={<AddIcon />}
                className="rfidAddKey"
                onClick={() => {
                  setValues({
                    ...values,
                    rfidConfig: [
                      ...values.rfidConfig,
                      { key: "", freevendValue: "", authValue: "" },
                    ],
                  });
                }}
              />
            </div>
          </div>
        )}
        {values.isWhiteLabelled && (
          <div className="charger_details_section" id="rfidAuthModeContainer">
            <div className="charger_heading">
              <p>WHITE LABEL CONFIGURATION</p>
            </div>
            <div className="rfidTable">
              <Grid container className="rfidAuthModeHeading">
                <Grid item xs={5}>
                  Sold as manufacturer
                </Grid>
                <Grid item xs={5}>
                  Sold as model
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              {values?.soldAs?.map((row, ind) => (
                <Grid
                  container
                  key={ind}
                  spacing={0}
                  className="rfidAuthModeRows"
                >
                  <Grid item xs={5}>
                    <Controls.Input
                      variant="standard"
                      name="whiteLabelManufacturer"
                      testid={`whiteLabelManufacturer${ind}`}
                      InputProps={{ disableUnderline: true }}
                      value={row?.whiteLabelManufacturer}
                      onChange={(e) => {
                        let res = [...values.soldAs];
                        res[ind].whiteLabelManufacturer = e.target.value;
                        setValues({ ...values, soldAs: res });
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Controls.Input
                      variant="standard"
                      name="whiteLabelModel"
                      testid={`whiteLabelModel${ind}`}
                      InputProps={{ disableUnderline: true }}
                      value={row?.whiteLabelModel}
                      onChange={(e) => {
                        let res = [...values.soldAs];
                        res[ind].whiteLabelModel = e.target.value;
                        setValues({ ...values, soldAs: res });
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Controls.ActionButton
                      data-testid={`deleteWhiteLabelBtn${ind}`}
                      onClick={() =>
                        setValues({
                          ...values,
                          soldAs:
                            values.soldAs?.length === 1
                              ? [
                                  {
                                    whiteLabelManufacturer: "",
                                    whiteLabelModel: "",
                                  },
                                ]
                              : values.soldAs?.filter((_, i) => i !== ind),
                        })
                      }
                    >
                      <DeleteIcon />
                    </Controls.ActionButton>
                  </Grid>
                </Grid>
              ))}
              <Controls.Button
                text="Add Sold As Model"
                disabled={values.soldAs?.some(
                  (row) =>
                    !row.whiteLabelManufacturer?.length &&
                    !row.whiteLabelModel?.length
                )}
                variant="outlined"
                startIcon={<AddIcon />}
                className="rfidAddKey"
                onClick={() => {
                  setValues({
                    ...values,
                    soldAs: [
                      ...values.soldAs,
                      { whiteLabelManufacturer: "", whiteLabelModel: "" },
                    ],
                  });
                }}
              />
            </div>
          </div>
        )}
      </Form>
      <div className="serviceSidebarActionBtns">
        <Controls.Button
          text="Cancel"
          variant="outlined"
          onClick={() => setOpenPopup((op) => ({ ...op, isOpen: false }))}
          className="cancelBtn"
        />
        <Controls.Button
          text={type === "addCharger" ? "Add Charger" : "Update Charger"}
          testid="addChargerModelSubmitBtn"
          color="primary"
          className="addCardBtn"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export function UploadFirmware({
  setOpenPopup,
  setRefreshChargers,
  setToast,
  data,
}) {
  const { CHARGER_FIRMWARE } = indexedEndPoints;
  const [loading, setLoading] = useState(false);
  const [firmwareFile, setFirmwareFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [deleteFirmwareList, setDeleteFirmwareList] = useState({});
  const [versionList, setVersionList] = useState([]);
  const [noReplaceVersionList, setNoReplaceVersionList] = useState([]);
  const [replaceTestVersions, setReplaceTestVersions] = useState([]);

  const addEditInitialValues = useMemo(
    () => ({
      model: [],
      version: "",
      isTestVersion: false,
    }),
    []
  );

  const models = data?.map((row) => {
    let items = [];
    row.vendorModel && items.push(row.vendorModel);
    row.networkType && items.push(row.networkType);
    row?.typeOfConnectorList?.length &&
      items.push(row.typeOfConnectorList.join(","));
    const label = items?.join("-");
    const value = row.chargerMetaId;
    return { label, value };
  });
  const validateNumberFields = (field) => {
    for (let [key, value] of Object.entries(field)) {
      if (value < 0) setValues({ ...values, [key]: Math.abs(value) });
    }
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(addEditInitialValues, true, validateNumberFields);

  let formData = useMemo(() => new FormData(), []);

  const handleFileChange = (e) => {
    const file = e?.target?.files?.[0];
    if (!file) return;
    setFileName(file?.name);
    const invalidTypes = [
      "image",
      "audio",
      "video",
      "pdf",
      "doc",
      "xls",
      "ppt",
      "csv",
    ];
    if (invalidTypes.some((item) => file?.type?.includes(item)))
      setFileError("Invalid file type uploaded");
    else {
      setFirmwareFile(file);
      setFileError("");
    }
  };

  const handleSubmit = useCallback(async () => {
    let response = {};
    if (Object.keys(deleteFirmwareList).length > 0) {
      setLoading(true);
      response = await apiProvider.del(
        CHARGER_FIRMWARE,
        Object.values(deleteFirmwareList)
      );
      setLoading(false);
      if (response.statusCode < 200 || response.statusCode > 299) {
        setToast({
          isOpen: true,
          message: "Charger firmware deletion failed",
          type: "error",
        });
        return;
      }
    }
    formData.set(
      "chargerMetaId",
      values.model?.map((item) => item.value)
    );
    formData.set("version", values.version);
    formData.set("isTestVersion", values.isTestVersion);
    formData.set("file", firmwareFile);
    setLoading(true);
    response = await apiProvider.post(CHARGER_FIRMWARE, formData);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger firmware uploaded successfully",
        type: "success",
      });
      setOpenPopup((op) => ({ ...op, isOpen: false }));
      setRefreshChargers(true);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data,
        type: "error",
      });
    }
  }, [deleteFirmwareList, values, firmwareFile]);

  const handleNext = () => {
    let isValid = false;
    if (activeStep === 0) {
      const fields = Object.keys(addEditInitialValues);
      const validData = validateNonEmptyFields(fields, values, setErrors);
      if (validData && firmwareFile && fileError?.length === 0) {
        setErrors({});
        isValid = true;
      }
      if (!firmwareFile) setFileError("Please upload firmware file");
    } else if (activeStep === 1)
      isValid =
        !values.isTestVersion &&
        Object.keys(deleteFirmwareList).length === versionList?.length;
    if (isValid) setActiveStep((curr) => curr + 1);
  };

  useEffect(() => {
    if (values?.model?.length === 0) return;
    const versionList = [],
      noVersionList = [],
      testVersionList = [];
    data?.forEach((row) => {
      if (values?.model.find((item) => item.value === row.chargerMetaId)) {
        const testVersion = [],
          releaseVersions = [];
        row?.firmwareDetails?.forEach((item) =>
          item.isTestVersion
            ? testVersion.push(item)
            : releaseVersions.push(item)
        );
        if (values.isTestVersion)
          testVersion?.length
            ? testVersionList.push(row)
            : noVersionList.push(row);
        else
          releaseVersions?.length > 1
            ? versionList.push(row)
            : noVersionList.push(row);
      }
    });
    setVersionList(versionList);
    setNoReplaceVersionList(noVersionList);
    setReplaceTestVersions(testVersionList);
    if (values.isTestVersion && testVersionList?.length) {
      const deleteList = {};
      testVersionList?.forEach(
        (row) =>
          (deleteList[row.vendorModel] = {
            version: row.firmwareDetails?.find((item) => item.isTestVersion)
              ?.version,
            chargerMetaId: row.chargerMetaId,
            model: row.vendorModel,
          })
      );
      setDeleteFirmwareList(deleteList);
    } else setDeleteFirmwareList({});
  }, [values, data]);

  const stepperLabel = ["Select models and upload firmware"];
  if (!values.isTestVersion) stepperLabel.push("Select a version to remove");
  stepperLabel.push("Review and confirm");

  const stepperContent = useMemo(() => {
    let steps = [];
    steps.push(
      <Form>
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              multiple
              value={values.model}
              getOptionLabel={(item) => item.label}
              options={models}
              onChange={(_, value) =>
                setValues({
                  ...values,
                  model: value,
                })
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Models"
                  placeholder="Models"
                  error={errors.model}
                  helperText={errors.model}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="version"
              label="Version"
              value={values.version}
              onChange={handleFormInputChange()}
              error={errors.version}
            />
          </Grid>
          <Grid item xs={12} className="testVersionUpload">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isTestVersion}
                  onChange={(e) =>
                    setValues({ ...values, isTestVersion: e.target.checked })
                  }
                  name="isTestVersion"
                />
              }
              label={
                <p>
                  This is a test version{" "}
                  <ScienceIcon fontSize="small" className="testVersionIcon" />
                </p>
              }
            />
          </Grid>
          <Grid item xs={5} margin={"8px"}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              className="uploadFirmwareBtnBg"
            >
              <ArrowUpwardIcon />
              &nbsp;Upload Firmware
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </Grid>
          <Grid item xs={6} margin={"8px"}>
            {fileName}
          </Grid>
          <Grid item xs={12} marginLeft={"8px"} color="red">
            {fileError}
          </Grid>
        </Grid>
      </Form>
    );
    if (!values.isTestVersion) {
      steps.push(
        <div>
          <p className="firmwareStepInfo">
            Maximum 2 general release versions can be stored per model. The
            selected version will be replaced with version{" "}
            <Chip label={values.version} className="availableFirmwareChips" />
          </p>
          <div className="removeFirmwareList">
            {versionList?.map((row) => (
              <div key={row.vendorModel}>
                <Controls.RadioButtons
                  value={deleteFirmwareList?.[row.model]?.version || ""}
                  error={true}
                  helperText={
                    deleteFirmwareList[row.model] ? "" : messages.FIELD_REQUIRED
                  }
                  direction="horizontal"
                  groupLabel={row.vendorModel}
                  itemList={row.firmwareDetails
                    ?.filter((item) => !item.isTestVersion)
                    ?.map((item) => ({
                      label: item.version,
                      value: item.version,
                    }))}
                  onChange={(e) => {
                    setDeleteFirmwareList({
                      ...deleteFirmwareList,
                      [row.vendorModel]: {
                        version: e.target.value,
                        chargerMetaId: row.chargerMetaId,
                        model: row.vendorModel,
                      },
                    });
                  }}
                />
                <Divider className="firmwareDividers" />
              </div>
            ))}
            {noReplaceVersionList?.map((row) => (
              <div key={row.vendorModel}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  {row.vendorModel}
                </FormLabel>
                <p className="noReplaceVersion">
                  <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />{" "}
                  <i>No version to replace</i>
                </p>
                <Divider className="firmwareDividers" />
              </div>
            ))}
          </div>
        </div>
      );
    }
    steps.push(
      <div>
        <p className="firmwareStepInfo">
          The following firmware files will be removed and firmware version{" "}
          <Chip
            label={
              <p className="centerAligned">
                {values?.isTestVersion && (
                  <ScienceIcon fontSize="small" className="testVersionIcon" />
                )}{" "}
                {values.version}
              </p>
            }
            className="availableFirmwareChips"
          />{" "}
          will be available on the selected models.
        </p>
        {values.isTestVersion && (
          <p className="firmwareStepInfo">
            Note: <span className="boldFont">{values.version}</span> is a test
            version and will not be visible to all users until marked ready for
            global release.
          </p>
        )}
        <Grid container>
          {Object.values(deleteFirmwareList)?.map((row) => (
            <>
              <Grid container>
                <Grid item xs={6} className="firmwareStepInfo">
                  {row.model}
                </Grid>
                <Grid item xs={6} className="boldFont">
                  <Chip
                    label={
                      <p className="centerAligned">
                        {values?.isTestVersion && (
                          <ScienceIcon
                            fontSize="small"
                            className="testVersionIcon"
                          />
                        )}{" "}
                        {row.version}
                      </p>
                    }
                    className="availableFirmwareChips"
                  />
                </Grid>
              </Grid>
              <Divider className="firmwareDividers" />
            </>
          ))}
          {noReplaceVersionList?.map((row) => (
            <>
              <Grid container>
                <Grid item xs={6} className="firmwareStepInfo">
                  {row.vendorModel}
                </Grid>
                <Grid item xs={6} className="boldFont">
                  <p className="noReplaceVersion">
                    <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />{" "}
                    <i>No version to replace</i>
                  </p>
                </Grid>
              </Grid>
              <Divider className="firmwareDividers" />
            </>
          ))}
        </Grid>
      </div>
    );
    return steps;
  }, [
    values,
    errors,
    versionList,
    noReplaceVersionList,
    fileName,
    fileError,
    deleteFirmwareList,
  ]);

  return (
    <>
      <Loader isLoading={loading} />
      <Stepper activeStep={activeStep} alternativeLabel>
        {stepperLabel.map((label, index) => (
          <Step
            key={"step-" + index}
            sx={{
              "& .MuiStepLabel-iconContainer .Mui-active": {
                color: "#0051A2",
              },
              "& .MuiStepLabel-iconContainer .Mui-completed": {
                color: "#0051A2",
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {stepperContent?.[activeStep]}
      <div className="uploadFirmwareBtns">
        <Button
          onClick={() => {
            setOpenPopup((op) => ({ ...op, isOpen: false }));
          }}
        >
          Cancel
        </Button>
        {activeStep > 0 && (
          <Button onClick={() => setActiveStep((curr) => curr - 1)}>
            Previous
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className="uploadFirmwareBtnBg"
          onClick={() => {
            activeStep === stepperLabel.length - 1
              ? handleSubmit()
              : handleNext();
          }}
        >
          {activeStep === stepperLabel.length - 1 ? "Confirm" : "Next"}
        </Button>
      </div>
    </>
  );
}

export default AddEditCharger;
